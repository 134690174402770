import React from 'react'
import classNames from 'classnames'
import { ReactComponent as InfoIcon } from 'i/icons/info.svg'

import styles from './AlertMessage.module.scss'

const AlertMessage = ({ message = 'Something went wrong...', formMod, inputMod }) => {
  const componentClassName = classNames(styles.message, {
    [styles.formMod]: formMod,
    [styles.inputMod]: inputMod,
  })

  return (
    <div className={componentClassName}>
      {message}
      <div class={styles.icon}>
        <InfoIcon />
      </div>
    </div>
  )
}

export default AlertMessage
