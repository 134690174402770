import { apiRequest } from '../../apiRequest'

export const googleLogInUser = (data) =>
  apiRequest(`/user/social-login/google/`).post(data)

export const appleLogInUser = (data) => apiRequest(`/user/social-login/apple/`).post(data)

export const facebookLogInUser = (data) =>
  apiRequest(`/user/social-login/facebook/`).post(data)

export const loginUser = (data) => apiRequest(`/auth/login/`).post(data)

export const registerUser = (data) => apiRequest('/auth/registration/').post(data)
