import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { paymentApi } from 'api'

import LogoImg from 'i/talkpro_logo.png'
import SignImg from 'i/signup_img.jpg'
import { BaseNavigation } from 'components/ui/BaseNavgiation'
import { SERVICE_NAVIGATION } from 'shared/constants'

import { Spinner } from 'components/Spinner'

const StripePage = (props) => {
  const [connectLink, setConnectLink] = useState('')

  useEffect(() => {
    props.location.state === '0' || !props.location.state
      ? paymentApi
          .createSubscription()
          .then((res) => setConnectLink(res.result.action_url))
      : paymentApi.connectStripe().then((res) => setConnectLink(res.result.action_url))
  }, [])

  useEffect(() => {
    if (connectLink) {
      window.location = connectLink
    }
  }, [connectLink])

  useEffect(() => {
    document.title = 'TalkPro | Connect Payment'
  }, [])

  return (
    <section className="section">
      <Spinner />
    </section>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(StripePage)
