import React from 'react'

import ResultImg1 from 'i/result_1.jpg'
import ResultImg2 from 'i/result_2.jpg'
import ResultImg3 from 'i/result_3.jpg'
import ResultImg4 from 'i/result_4.jpg'
import ResultImg5 from 'i/result_5.jpg'
import ResultImg6 from 'i/result_6.jpg'
import ResultImg7 from 'i/result_7.jpg'
import ResultImg8 from 'i/result_8.jpg'
import ResultImg9 from 'i/result_9.jpg'

const ResultPage = () => {
  const resultMenu = [
    {
      mod: 'active_mod',
      title: 'All',
      text: 'All videos unified',
      count: '99',
    },
    {
      title: 'Business',
      text: 'Business videos unified',
      count: '66',
    },
    {
      title: 'Investing',
      text: 'Investing videos unified',
      count: '55',
    },
    {
      title: 'Training',
      text: 'Training videos unified',
      count: '9',
    },
    {
      title: 'Beauty',
      text: 'Beauty videos unified',
      count: '199',
    },
  ]

  const resultArray = [
    {
      img: ResultImg1,
      title: 'Types Of Paper In Catalog Printing',
      info: ['27 Feb 2020', '1.4k views', '1:40'],
    },
    {
      img: ResultImg2,
      title: 'Tips To Increase Your Adwords Profits',
      info: ['Sales Manager', '1.4k views', '1:40'],
    },
    {
      img: ResultImg3,
      title: 'Influencing The Social Influencer',
      info: ['PR agent', '1.4k views', '1:40'],
    },
    {
      img: ResultImg4,
      title: 'Internet Advertising Trends You Won',
      info: ['Support Team', '1.4k views', '1:40'],
    },
    {
      img: ResultImg5,
      title: 'Getting Free Publicity For Your Business',
      info: ['Project Manager', '1.4k views', '1:40'],
    },
    {
      img: ResultImg6,
      title: 'Welcome To Desiclassifieds Classifieds',
      info: ['PR agent', '1.4k views', '1:40'],
    },
    {
      img: ResultImg7,
      title: 'Free Advertising For Your Online Business',
      info: ['27 Feb 2020', '1.4k views', '1:40'],
    },
    {
      img: ResultImg8,
      title: 'Importance Of The Custom Company',
      info: ['27 Feb 2020', '1.4k views', '1:40'],
    },
    {
      img: ResultImg9,
      title: 'Promotional Advertising Specialty',
      info: ['Designer', '1.4k views', '1:40'],
    },
  ]

  return (
    <section className="section height_mod wrap_mod">
      <div className="section_col sidebar_mod left_bar_mod">
        <div className="result_menu_list">
          {resultMenu.map((item, index) => {
            return (
              <div className="result_menu_item">
                <a
                  className={`result_menu_link ${index == 0 ? 'active_mod' : ''}`}
                  href="#"
                >
                  <div className="result_menu_title">{item.title}</div>
                  <div className="result_menu_text">{item.text}</div>
                  <div className="result_menu_count">{item.count}</div>
                </a>
              </div>
            )
          })}
        </div>
      </div>
      <div className="section_col content_mod col_mod">
        <div className="result_list">
          {resultArray.map((item) => {
            return (
              <div className="result_item_wrap">
                <a className="result_item" href="#">
                  <div className="result_img_wrap">
                    <img className="result_img" src={item.img} alt={item.title} />
                  </div>
                  <div className="result_content">
                    <div className="result_title">{item.title}</div>
                    <div className="result_info">
                      {item.info.map((infoItem) => {
                        return <div className="result_info_item">{infoItem}</div>
                      })}
                    </div>
                  </div>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ResultPage
