import { React, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'

import customRates from 'i/logo_custom_rates.svg'
import googleCalendar from 'i/logo_google_calendar.svg'
import paypalPayouts from 'i/logo_paypal_payouts.svg'
import videoCalls from 'i/logo_video_calls.svg'
import talkproPreview from 'i/talkpro_desktop_preview.svg'
import phones from 'i/two_phones.svg'
import ellipse from 'i/ellipseGreen.png'
import talkUser from 'i/talk_user.jpg'
import sittingMan from 'i/lottie/computer_man.json'
import animationData from 'i/lottie/Scene2.json'
import sync from 'i/lottie/Scene9.json'

export default function WelcomePage() {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: sittingMan,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const lottieOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const lottieOptions3 = {
    loop: true,
    autoplay: true,
    animationData: sync,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [logged, setLogged] = useState(true)
  const history = useHistory()

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    const loggedIn = window.localStorage.getItem('token')
    if (loggedIn && logged) {
      JSON.parse(window.localStorage.getItem('isPro'))
        ? history.push('/dashboard/pro')
        : history.push('/dashboard/user')
    } else window.location.replace('https://join.talkpro.com')

    window.addEventListener('resize', handleResize)
    setLogged(false)
  }, [])

  return (
    <section className="welcome_body">
      <div className="welcome_content">
        <section className="welc_section mobile_wrap first_section">
          {windowWidth >= 1024 && (
            <>
              <div className="welc_intro_img">
                <Lottie options={lottieOptions} height={700} width={700} />
              </div>
              <div className="welc_intro_content">
                <div className="welc_intro_text_container">
                  <div className="welc_intro_title mobile_center">
                    Talk to any pro or influencer by the minute
                  </div>
                  <div className="welc_intro_descr mobile_center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra
                    tincidunt tellus, et iaculis justo auctor eu. Quisque non commodo
                    ante, vel vestibulum.{' '}
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => history.push('/pro/login')}
                    className="btn_base_green width_mod"
                  >
                    Sign up free
                  </button>
                  <button className="btn_v3 width_mod welcome_mod">Watch Video</button>
                </div>
              </div>
            </>
          )}
          {windowWidth < 1024 && (
            <>
              <div className="welc_intro_content">
                <div className="welc_intro_text_container">
                  <div className="welc_intro_title mobile_center">
                    Talk to a pro or an influencer by the minute
                  </div>
                  <div className="welc_intro_descr mobile_center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra
                    tincidunt tellus, et iaculis justo auctor eu. Quisque non commodo
                    ante, vel vestibulum.{' '}
                  </div>
                  <button
                    onClick={() => history.push('/pro/login')}
                    className="btn_base_green width_mod"
                  >
                    Sign up free
                  </button>
                  <button className="btn_v3 width_mod welcome_mod">Watch Video</button>{' '}
                </div>
              </div>
              <div className="welc_intro_img">
                <Lottie options={lottieOptions} height={400} width={400} />
              </div>
            </>
          )}
        </section>

        <section className="welc_section mobile_wrap first_section">
          {windowWidth >= 1024 && (
            <>
              <div className="welc_intro_content">
                <div className="welc_intro_text_container">
                  <div className="welc_intro_title mobile_center">
                    Book or host group events. For free or a fee
                  </div>
                  <div className="welc_intro_descr mobile_center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra
                    tincidunt tellus, et iaculis justo auctor eu. Quisque non commodo
                    ante, vel vestibulum .{' '}
                  </div>
                </div>
              </div>
              <div className="welc_intro_img">
                <Lottie options={lottieOptions2} height={700} width={700} />
              </div>
            </>
          )}
          {windowWidth < 1024 && (
            <>
              <div className="welc_intro_content">
                <div className="welc_intro_text_container">
                  <div className="welc_intro_title mobile_center">
                    Book or host group calls. Control attendance and price
                  </div>
                  <div className="welc_intro_descr mobile_center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra
                    tincidunt tellus, et iaculis justo auctor eu. Quisque non commodo
                    ante, vel vestibulum.{' '}
                  </div>
                  <button
                    onClick={() => history.push('/pro/login')}
                    className="btn_base_green width_mod"
                  >
                    Sign up free
                  </button>
                  <button className="btn_v3 width_mod welcome_mod">Watch Video</button>{' '}
                </div>
              </div>
              <div className="welc_intro_img">
                <Lottie options={lottieOptions} height={400} width={400} />
              </div>
            </>
          )}
        </section>

        <section className="welc_section mobile_wrap first_section">
          {windowWidth >= 1024 && (
            <>
              <div className="welc_intro_img">
                <Lottie options={lottieOptions3} height={700} width={700} />
              </div>
              <div className="welc_intro_content">
                <div className="welc_intro_text_container">
                  <div className="welc_intro_title mobile_center">
                    Synced with Google and iOS Calendar
                  </div>
                  <div className="welc_intro_descr mobile_center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra
                    tincidunt tellus, et iaculis justo auctor eu. Quisque non commodo
                    ante, vel vestibulum.{' '}
                  </div>
                </div>
              </div>
            </>
          )}
          {windowWidth < 1024 && (
            <>
              <div className="welc_intro_content">
                <div className="welc_intro_text_container">
                  <div className="welc_intro_title mobile_center">
                    Talk to a pro or an influencer by the minute
                  </div>
                  <div className="welc_intro_descr mobile_center">
                    Add 1,000 person video and audio calls to any website or app, with
                    ease and flexibility. Our secure, scalable, global infrastructure is
                    trusted by companies{' '}
                  </div>
                  <button
                    onClick={() => history.push('/pro/login')}
                    className="btn_base_green width_mod"
                  >
                    Sign up free
                  </button>
                  <button className="btn_v3 width_mod welcome_mod">Watch Video</button>{' '}
                </div>
              </div>
              <div className="welc_intro_img">
                <Lottie options={lottieOptions} height={400} width={400} />
              </div>
            </>
          )}
        </section>
      </div>
    </section>
  )
}
