import { apiRequest } from 'api/apiRequest'

export const getUserEvents = () => apiRequest('/user/events/').get()

export const attendEvent = (data) => apiRequest('/user/event/join/').post(data)

export const proEventList = (data) =>
  apiRequest('/user/pro-events/', { error: { message: 'ERROR' } }).post(data)

export const eventList = () => apiRequest('/events/').get()
