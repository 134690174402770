export class YoutubeVideoHandler {
  convertDuration(duration) {
    duration = duration.substring(2)
    let hours = 0,
      minutes = 0,
      seconds = 0
    if (duration.includes('H')) {
      ;[hours, duration] = duration.split('H')
      hours = parseInt(hours)
    }
    if (duration.includes('M')) {
      ;[minutes, duration] = duration.split('M')
      minutes = parseInt(minutes)
    }
    if (duration.includes('S')) {
      seconds = parseInt(duration.replace('S', ''))
    }
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  validateId(id) {
    const regExp = /^[A-Za-z0-9_-]{11}$/
    const match = id.match(regExp)

    return match && match[0]
  }

  getIdFromUrl(str) {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = str.match(regExp)

    if (match && match[2].length === 11) {
      return match[2]
    }
  }

  parseVideo(video) {
    const duration = video.contentDetails.duration
    const { thumbnails, title } = video.snippet
    const id = video.id

    return {
      thumbnails,
      title,
      duration,
      videoId: id,
      videoLink: `http://www.youtube.com/watch?v=${id}`,
    }
  }
}
