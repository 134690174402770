import React, { useContext, useEffect, useState, useRef } from 'react'
import { UiContext } from 'context/ui'
import classNames from 'classnames'
import { useClickOutside } from 'hooks'
import { format, isFuture } from 'date-fns'

import Popup from '../Popup/Popup'
import styles from './ClassesPopup.module.scss'

import './../../../styles/customStyles.css';

import { eventApi } from 'api'

{/* const popupHead = ['Date', 'Name', 'Time', 'Attendee(s)', 'Amount', 'Fees', 'Net'] */}
const popupHead = ['Date', 'Name', 'Attendee(s)', 'Amount']


const ClassesPopup = ({ isOpen }) => {
  const $container = useRef()
  const { setActivePopupId } = useContext(UiContext)
  const [events, setEvents] = useState()

  const getAttendeesAndTotal = (event) => {
    const attendees = event?.attendes
      ?.split(',')
      .filter((e) => e !== ' ' && e !== '').length
    const total = attendees * event?.entry_fee
    const fees = event.platform_fee * attendees
    const profit = total - fees

    return { attendees, total, fees, profit }
  }

  useEffect(() => {
    eventApi
      .getProEvents()
      .then((res) =>
        setEvents(
          res.result
            .filter((e) => e.title)
            ?.sort((a, b) => new Date(b.scheduled_at) - new Date(a.scheduled_at))
        )
      )
  }, [])

  const handleClose = () => {
    setActivePopupId(null)
  }

  useClickOutside($container, handleClose)

  const showCreateClassPopup = () => {
    setActivePopupId('create-class')
  }

  return (
    <Popup
      isOpen={isOpen}
      title="Events"
      description="Choose which days and from what time you would like to offer 1on1 calls."
      type="v2"
      centerContent
    >
      <div className={styles.btnWrap}>
        <button type="button" onClick={showCreateClassPopup} className={styles.button}>
          Create Event
        </button>
      </div>
      <div className={styles.tableWrap}>
        <div className={styles.table}>
          <div className={styles.tableHead}>
            {popupHead &&
              popupHead.map((item, index) => {
                return (
                  <div
                    className={classNames(styles.tableItem, styles.tableItemV2)}
                    key={index}
                  >
                    {item}
                  </div>
                )
              })}
          </div>
          <div className={styles.tableBody}>
            {events &&
              events.map((e, index) => {

              if( getAttendeesAndTotal(e).attendees == 0 || getAttendeesAndTotal(e).total == 0 ){
              }else{
                  
                return (
                  <div
                    className={styles.tableRow}
                    key={index}
                    onClick={(a) => {
                      a.preventDefault()
                      if (isFuture(new Date(e.scheduled_at))) {
                        window.localStorage.setItem(
                          'callInfo',
                          JSON.stringify({
                            scheduled_at: e.scheduled_at,
                            entry_fee: parseInt(e.entry_fee),
                            max_participants: e.max_participants,
                            category_name: e.category_name,
                            title: e.title,
                            pro_id: e.pro_id,
                            id: e.id,
                            description: e.description,
                            invited_users: e?.attendes
                              ?.split(',')
                              ?.filter(
                                (e) => e !== ' ' && e !== '' && e !== e?.pro?.user?.email
                              ),
                          })
                        )
                        showCreateClassPopup()
                      }
                    }}
                  >
                    <div className={styles.tableItem}>
                      {format(new Date(e.scheduled_at), 'M/d/yy')}
                    </div>
                    <div
                      style={
                        isFuture(new Date(e.scheduled_at))
                          ? { color: '#007eff' }
                          : { color: '#000' }
                      }
                      className={styles.tableItem}
                    >
                      {e.title}
                    </div>
                    {/* <div className={styles.tableItem}>
                        {format(new Date(e.scheduled_at), 'h:mm a')}
                    </div>*/}
                    <div className={styles.tableItem}>
                      {/* {getAttendeesAndTotal(e).attendees}/{e.max_participants} */}
                      {getAttendeesAndTotal(e).attendees} {"Attended"}
                    </div>
                    <div className={styles.tableItem}>
                      ${getAttendeesAndTotal(e).total} {"Payout"}
                    </div>
                    {/* <div className={styles.tableItem}>
                      ${getAttendeesAndTotal(e).fees}
                    </div>
                    <div className={styles.tableItem}>
                      ${getAttendeesAndTotal(e).profit}
                    </div> */}
                  </div>
                )

              }

              })}
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ClassesPopup
