//API calls
import { apiRequest } from '../apiRequest'

import { googleLogInPro, getGoogleContacts } from './Pro'

import {
  googleLogInUser,
  loginUser,
  registerUser,
  appleLogInUser,
  facebookLogInUser,
} from './User'

const passwordResetCSRF = () => apiRequest(`/auth/accounts/password/reset/`).get()

const passwordReset = (data) => apiRequest(`/auth/accounts/password/reset/`).post(data)

const logOut = () =>
  apiRequest('/auth/logout/').post(window.localStorage.getItem('token'))

const refresh = (data) => apiRequest('/auth/token/refresh/').post(data)

const validate = (data) => apiRequest('/auth/token/verify/').post({ token: data })

export const authApi = {
  appleLogInUser,
  facebookLogInUser,
  googleLogInPro,
  getGoogleContacts,
  googleLogInUser,
  loginUser,
  passwordReset,
  passwordResetCSRF,
  registerUser,
  logOut,
  refresh,
  validate,
}
