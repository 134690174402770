import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { FormFieldSearch } from 'components/Form'
import { SectionHeading } from 'components/SectionHeading'
import { TalksList } from 'components/Talks'

import { ReactComponent as CloseIcon } from 'i/icons/close.svg'

const ReschedulePopup = (props) => {
  const history = useHistory()
  const goBack = () => history.goBack()
  const calls = props?.calls

  return (
    <div className="page_popup active_state">
      <div className="page_popup_close" onClick={() => goBack()}>
        <CloseIcon className="icon icon-close size_mod" />
      </div>
      <div className="section default_mod mob_full_mod popup_scroll_mod">
        <div className="section_in v3_mod">
          <SectionHeading
            title="Re-Schedule a talk"
            descr="Search or choose someone from upcoming talks"
            offsetV2Mod
          />
          <FormFieldSearch
            id="enter_field"
            labelText="Enter name"
            type="text"
            name="enter_field"
            placeholder="Enter name"
            defaultValue=""
            offsetMod
          />
          <TalksList chats={calls} schedule={true} reschedule={true} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth: { calls, requests } }) => ({
  calls,
  requests,
})

export default connect(mapStateToProps)(ReschedulePopup)
