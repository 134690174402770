import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import AppleLogin from 'react-apple-login'

import Login from 'components/Auth/Login'

import { authApi, profileApi } from 'api'

import { setIsPro, logIn } from 'store/actions'

import SignImg from 'i/signup_img.jpg'
import { Image } from 'components/ui/Image'
import { SERVICE_NAVIGATION } from 'shared/constants'
import { BaseNavigation } from 'components/ui/BaseNavgiation'
import { AuthHeader } from 'components/ui/AuthHeader'
import { AuthItem } from 'components/ui/AuthItem'
import { AuthContent } from 'components/ui/AuthContent'
import { AuthRow } from 'components/ui/AuthRow'
import { AuthCol } from 'components/ui/AuthCol'
import { Button } from 'components/ui/Button'
import { WhyNeedAccount } from 'components/WhyNeedAccount'
import redirectOnLogin from 'utils/redirectOnLogin'

const LoginPage = (props) => {
  const history = useHistory()
  const request = props?.location?.state

  useEffect(() => {
    document.title = 'TalkPro | Login'
  }, [])

  useEffect(() => {
    let code = [...new URLSearchParams(window.location.search)]
      .find(([key]) => key === 'code')?.[1]
      .toString()

    if (code) {
      authApi.appleLogInUser({ code: code }).then(async (res) => {
        props.setIsPro(false)
        localStorage.setItem('token', res?.result?.access_token)
        localStorage.setItem('refreshToken', res?.result?.refresh_token)
        localStorage.setItem('isPro', false)
        localStorage.setItem('login-method', 'apple')
        await profileApi
          .getUserInfo()
          .then(
            (res) => (
              props.logIn(res?.result?.profile),
              res?.result?.profile && redirectOnLogin(history)
            )
          )
      })
    }
  }, [])

  return (
    <AuthRow>
      <AuthCol type="gradient" />
      <AuthCol>
        <AuthHeader title="Login to your meeting" offsetMod />
        <AuthItem variant="divider">
          <AuthContent>
            You don’t need to remember an email and password, <br /> plus we’ll send you a
            reminders before your talk
          </AuthContent>
        </AuthItem>
        <AuthItem variant="divider">
          <AuthItem>
            <Button variant="secondary" iconName="google" fullWidth>
              Continue with Google
              <Login isPro={false} data={request} />
            </Button>
          </AuthItem>
          <AuthItem>
            <AppleLogin
              clientId="com.talkpro.login"
              redirectURI="https://www.talkpro.com/user/login"
              render={(props) => {
                return (
                  <Button
                    variant="secondary"
                    iconName="apple"
                    onClick={props.onClick}
                    fullWidth
                  >
                    Continue with Apple
                  </Button>
                )
              }}
            />
          </AuthItem>
        </AuthItem>
        <AuthItem variant="divider">
          <WhyNeedAccount />
        </AuthItem>
      </AuthCol>
    </AuthRow>
  )
}

const mapStateToProps = ({ auth: { error, attributes } }) => ({
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(LoginPage)
