import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { isBefore, add } from 'date-fns'
import * as Yup from 'yup'

import { profileApi } from 'api'

import LogoImg from 'i/talkpro_logo.png'
import SignImg from 'i/signup_img.jpg'
import LinkedinImg from 'i/logo_linkedin.png'
import InstagramImg from 'i/logo_instagram.png'
import FbImg from 'i/logo_fb.png'
import TwitterImg from 'i/logo_twitter.png'
import times from 'utils/times.json'
import { BaseNavigation } from 'components/ui/BaseNavgiation'
import { SERVICE_NAVIGATION } from 'shared/constants'

const rateSchema = Yup.object().shape({
  rate: Yup.number()
    .required('Rate is required')
    .positive()
    .min(1, 'Rate is too low')
    .max(10000, 'Rate is too high'),
  availability_earliest: Yup.string()
    .required('Talk from is required')
    .notOneOf(['Talk from'], 'Talk from is not selected'),
  availability_latest: Yup.string()
    .required('Talk cutoff is required')
    .notOneOf(['Talk cutoff'], 'Talk cutoff from is not selected'),
})

const RatePage = (props) => {
  const [availableWeekends, setAvailableWeekends] = useState(false)
  const [fifteenMinutesFree, setFifteenMinutesFree] = useState(false)
  const [success, setSuccess] = useState(false)

  const history = useHistory()

  return (
    <section className="section signup">
      <div className="signup__row">
        <div className="signup__col">
          <div className="signup__img">
            <img className="signup__img_in" src={SignImg} alt="" />
          </div>
          <BaseNavigation navigation={SERVICE_NAVIGATION} isAuthPage />
        </div>
        <div className="signup__col">
          <div className="signup__col_in">
            <div className="signup__logo_w">
              <a className="signup__logo" href="/">
                <img className="signup__logo_in" src={LogoImg} />
              </a>
            </div>
            <div className="signup__info">
              <h1 className="signup__title">Create Account</h1>
              <div className="signup__subtitle">
                Talkpro.com/
                {props?.location?.state?.channelName ||
                  props?.user?.channel_name ||
                  'xxxxxxx'}
              </div>
              <div className="signup__descr">
                You don’t need to remember an email and password, plus we’ll send you a
                reminders before your talk
              </div>
            </div>

            <div className="signup__tabs">
              <div className="signup__tabs_head">
                <div className="signup__tabs_item">
                  <div className="signup__tabs_link signup__tabs_link--active">
                    Rate and Profile
                  </div>
                </div>
                <div className="signup__tabs_item">
                  <div className="signup__tabs_link">Payout Sync</div>
                </div>
                <div className="signup__tabs_item_descr">
                  You will be asked for payment in the next step{' '}
                </div>
              </div>
              <Formik
                initialValues={{
                  rate: props.user?.rate,
                  // Need to review
                  location: `${props.user?.location ? props.user?.location : ''}`,
                  // Need to review
                  language: `${
                    props.user?.language
                      ? props.user?.language?.code?.toLowerCase()
                      : 'en'
                  }`,
                  website: `${props.user?.youtube ? props.user?.youtube : ''}`,
                  expectations: `${
                    props.user?.expectations ? props.user?.expectations : ''
                  }`,
                  bio: `${props.user?.bio ? props.user?.bio : ''}`,
                  name: `${props.user?.user?.name ? props.user?.user?.name : ''}`,
                  website_url: `${
                    props.user?.website_url ? props.user?.website_url : ''
                  }`,
                  linkedin_usr: `${
                    props.user?.linkedin_usr ? props.user?.linkedin_usr : ''
                  }`,
                  insta_usr: `${props.user?.insta_usr ? props.user?.insta_usr : ''}`,
                  facebook_usr: `${
                    props.user?.facebook_usr ? props.user?.facebook_usr : ''
                  }`,
                  twitter_usr: `${props.user?.twitter_usr || ''}`,
                }}
                validationSchema={rateSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  const data = {
                    rate: values.rate,
                    rate_currency_code: 'USD',
                    language: props.user?.language
                      ? props.user?.language?.code?.toLowerCase()
                      : 'en',
                    location: values.location,
                    category_name:
                      props.location?.state?.category || props.user?.category || '',
                    expectations: JSON.stringify(values.expectations),
                    bio: values.bio,
                    name: values.name,
                    website_url: values.website_url,
                    linkedin_usr: values.linkedin_usr,
                    insta_usr: values.insta_usr,
                    facebook_usr: values.facebook_usr,
                    twitter_usr: values.twitter_usr,
                  }

                  Object.keys(data).forEach((key) => {
                    if (data[key] === '') {
                      delete data[key]
                    }
                  })

                  await profileApi.setProInfo(data)

                  setSuccess(true)

                  setSubmitting(false)

                  props.location?.state?.rate
                    ? (window.location.pathname = '/account/profile')
                    : history.push('/pro/stripe')
                }}
              >
                {({
                  errors,
                  touched,
                  submitForm,
                  isSubmitting,
                  handleChange,
                  values,
                }) => (
                  <>
                    <div className="signup__tabs_content">
                      <div className="signup__tabs_content_item signup__tabs_content_item--active">
                        <div className="signup__form">
                          <Form className="signup__form_in">
                            <div className="form_row">
                              <div className="form_group">
                                <Field
                                  className="form_group__field"
                                  name="availability_earliest"
                                  as="select"
                                  placeholder={
                                    props.user?.availability_earliest
                                      ? props.user?.availability_earliest
                                      : 'Talk from'
                                  }
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                >
                                  {times.map((e, index) =>
                                    e.time === null ? (
                                      <option
                                        value="Talk from"
                                        disabled
                                        hidden
                                        key={'default_from'}
                                      >
                                        Talk from
                                      </option>
                                    ) : (
                                      <option value={e.time} key={index}>
                                        {e.time}
                                      </option>
                                    )
                                  )}
                                </Field>
                                <Field
                                  className="form_group__field"
                                  name="availability_latest"
                                  as="select"
                                  placeholder={
                                    props.user?.availability_latest
                                      ? props.user?.availability_latest
                                      : ''
                                  }
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                >
                                  {times.map((e, index) =>
                                    e.time === null ? (
                                      <option
                                        value="Talk cutoff"
                                        disabled
                                        hidden
                                        key={'default_cutoff'}
                                      >
                                        Talk cutoff
                                      </option>
                                    ) : !isBefore(
                                        add(new Date(), {
                                          hours: values.availability_earliest
                                            .split(' ')
                                            .includes('PM')
                                            ? parseInt(
                                                values.availability_earliest.split(':')[0]
                                              ) + 12
                                            : parseInt(
                                                values.availability_earliest.split(':')[0]
                                              ),
                                          minutes: parseInt(
                                            values.availability_earliest.split(':')[1]
                                          ),
                                        }),
                                        add(new Date(), {
                                          hours:
                                            e.time.split(' ').includes('PM') &&
                                            e.time.split(':')[0] !== '12'
                                              ? parseInt(e.time.split(':')[0]) + 12
                                              : parseInt(e.time.split(':')[0]),
                                          minutes: parseInt(e.time.split(':')[1]),
                                        })
                                      ) ? (
                                      <option disabled value={e.time} key={index}>
                                        {e.time}
                                      </option>
                                    ) : (
                                      <option value={e.time} key={index}>
                                        {e.time}
                                      </option>
                                    )
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="form_group form_group--amount_mod">
                              <label className="form_group__label">
                                You will be asked for payment in the next step{' '}
                              </label>
                              <Field
                                className="form_group__field"
                                name="rate"
                                type="number"
                                placeholder={[
                                  props.user
                                    ? (props.user?.rate).toFixed(0)
                                    : 'Enter Amount',
                                ]}
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                              <span className="form_group__preffix">$</span>
                              <span className="form_group__suffix">/ consult</span>
                            </div>
                            <div className="form_group">
                              <label className="form_group__label">
                                What should someone expect (short and sweat)
                              </label>
                              <Field
                                name="expectations"
                                as="textarea"
                                className="form_group__field"
                                placeholder={
                                  props.user?.expectations || 'Enter what you get'
                                }
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form_group__divider">
                              <div className="form_group__divider_in">Profile</div>
                            </div>
                            <div className="form_group">
                              <label className="form_group__label">
                                Describe yourself
                              </label>
                              <Field
                                className="form_group__field"
                                name="name"
                                type="text"
                                placeholder={
                                  props.user?.user?.name || 'Enter your title or headline'
                                }
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form_group">
                              <label className="form_group__label">
                                Your bio (max 400 char)
                              </label>
                              <Field
                                className="form_group__field"
                                name="bio"
                                as="textarea"
                                placeholder={props.user?.bio || 'Write a short bio'}
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form_group">
                              <label className="form_group__label">
                                Handles (optional)
                              </label>
                              <Field
                                className="form_group__field"
                                name="website_url"
                                type="text"
                                placeholder={
                                  props.user?.website_url || 'Enter website url'
                                }
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form_row form_row--grid_mod">
                              <div className="form_group form_group--icon_mod">
                                <div className="form_group__icon">
                                  <img
                                    className="form_group__icon_in"
                                    src={LinkedinImg}
                                  />
                                </div>
                                <Field
                                  className="form_group__field"
                                  name="linkedin_usr"
                                  type="text"
                                  placeholder={
                                    props.user?.linkedin_usr || 'Enter LinkedIn'
                                  }
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="form_group form_group--icon_mod">
                                <div className="form_group__icon">
                                  <img
                                    className="form_group__icon_in"
                                    src={InstagramImg}
                                  />
                                </div>
                                <Field
                                  className="form_group__field"
                                  name="insta_usr"
                                  type="text"
                                  placeholder={props.user?.insta_usr || 'Enter Instagram'}
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="form_group form_group--icon_mod">
                                <div className="form_group__icon">
                                  <img className="form_group__icon_in" src={FbImg} />
                                </div>
                                <Field
                                  className="form_group__field"
                                  name="facebook_usr"
                                  type="text"
                                  placeholder={
                                    props.user?.facebook_usr || 'Enter Facebook'
                                  }
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="form_group form_group--icon_mod">
                                <div className="form_group__icon">
                                  <img className="form_group__icon_in" src={TwitterImg} />
                                </div>
                                <Field
                                  className="form_group__field"
                                  name="twitter_usr"
                                  type="text"
                                  placeholder={props.user?.twitter_usr || 'Enter Twitter'}
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </div>
                            <div className="form_group">
                              <button
                                type="submit"
                                onClick={(e) => {
                                  e.preventDefault()
                                  submitForm()
                                }}
                                className="form_group__button"
                              >
                                Continue to payouts
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(RatePage)
