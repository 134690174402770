import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useGoogleLogin } from 'react-google-login'
import AppleLogin from 'react-apple-login'

import { ProfileSideBar } from '../Book/ProfileSideBar'
import { isPast } from 'date-fns'

import LogoGoogle from 'i/google.svg'
import LogoApple from 'i/apple-signin.png'

import { callApi, profileApi, authApi, eventApi } from 'api'

import { setPro, logIn } from 'store/actions'
import { generatePaymentIntentId } from 'utils/generate-payment-intent-id'

const scope =
  'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly'
const clientId =
  '762923432718-9ifhiu7igag6c2902uh9nejfo28uuc69.apps.googleusercontent.com'

const JoinConfirmPage = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const history = useHistory()
  const [pro, setPro] = useState(null)
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isGuest, setIsGuest] = useState(false)
  const [guestEmail, setGuestEmail] = useState('')
  const [isDatePast, setIsDatePast] = useState(false)
  const info = props?.location?.state

  useEffect(() => {
    let rawData =
      window.location.search &&
      window.location.search
        .split('')
        .filter((e) => e !== '?')
        .join('')
    let proName = window.location.search && rawData?.split('&')[0]
    let date =
      window.location.search &&
      rawData?.split('&')[2] !== 'event' &&
      rawData?.split('&')[1].split('%20').join(' ').split('%3A').join(':')
    let isDatePast = isPast(new Date(date))
    setIsDatePast(isDatePast)

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    if (!window.location.search && !props?.location?.state?.pro) history.push('/')

    if (window.location.search && rawData?.split('&')[2] !== 'event') {
      profileApi.getProInfo({ pro: proName }).then((res) => {
        setPro(res.result)
        props.setPro(res.result)
        setData({
          pro: res.result,
          scheduled_at: date,
          callRequest: {
            scheduled_at: date,
            pro_id: res.result.id,
            category_name: res.result.category,
          },
        })
      })
    } else if (window.location.search && rawData?.split('&')[2] === 'event') {
      profileApi.getProInfo({ pro: proName }).then((res) => {
        setPro(res.result)
        eventApi.proEventList({ pro: proName }).then((res) => {
          let event = res?.result?.find((e) => e.id === parseInt(rawData?.split('&')[1]))
          props.setPro(res.result)
          setData({
            pro: res.result,
            event,
            scheduled_at: event.scheduled_at,
          })
        })
      })
    } else {
      setPro(props?.location?.state?.pro)
      props.setPro(props?.location?.state?.pro)
      setData(props?.location?.state)
    }

    setIsLoading(false)
    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    props.setIsConfirmPage(true)
    return () => {
      props.setIsConfirmPage(false)
    }
  }, [])

  useEffect(() => {
    let code = [...new URLSearchParams(window.location.search)]
      .find(([key]) => key === 'code')?.[1]
      .toString()

    if (code) {
      authApi.appleLogInUser({ code: code }).then(async (res) => {
        props.setIsPro(false)
        localStorage.setItem('token', res?.result?.access_token)
        localStorage.setItem('refreshToken', res?.result?.refresh_token)
        localStorage.setItem('login-method', 'apple')
        localStorage.setItem('isPro', false)
        await profileApi.getUserInfo().then((res) => props.logIn(res?.result?.profile))

        data?.callRequest
          ? callApi.sendCallRequest(data.callRequest).then((res) => {
              window.localStorage.setItem('info', JSON.stringify(data))
              console.log('data', data)
              res?.result?.redirect
                ? history.push('/user/callback', data, res?.result?.call_info)
                : history.push('/user/payments', {
                    ...data,
                    call_id: res?.result?.call_info,
                  })
            })
          : history.push('/user/payments', data)
      })
    }
  }, [data, history, props])

  const onSuccess = async (res) => {
    const result = await authApi.googleLogInUser({
      access_token: res?.tokenObj?.access_token,
    })

    const name = res?.profileObj?.name
    const avi = res?.profileObj?.imageUrl

    localStorage.setItem('token', result?.result?.access_token)
    localStorage.setItem('refreshToken', result?.result?.refresh_token)
    localStorage.setItem('login-method', 'google')
    localStorage.setItem('isPro', false)

    profileApi.setUserInfo({
      photo: avi,
      name: name,
    })

    await profileApi.getUserInfo().then((res) => props.logIn(res.result.profile))

    const eventEntryFee = parseInt(data?.event?.entry_fee)

    if (data?.callRequest) {
      callApi.sendCallRequest(data.callRequest).then((res) => {
        console.log('data', data)
        window.localStorage.setItem('info', JSON.stringify(data))
        res?.result?.redirect
          ? history.push('/user/callback', data, res?.result?.call_info)
          : history.push('/user/payments', {
              ...data,
              call_id: res?.result?.call_info,
            })
      })
    } else {
      if (eventEntryFee !== 0) {
        history.push('/user/payments', data)
      } else {
        const paymentIntentId = generatePaymentIntentId()
        const callbackRoute = `/user/callback/?payment_intent=${paymentIntentId}`
        window.localStorage.setItem('isEvent', 'true')
        window.localStorage.setItem('info', JSON.stringify(data))
        history.push(callbackRoute, data)
      }
    }
  }

  const onFailure = async (res) => {
    console.log('Error authenticating: ', res)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    scope,
    accessType: 'offline',
    prompt: 'consent',
  })

  const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  return (
    <section className="section profile">
      {!isLoading && (
        <div className="book__cols">
          <div className="book__col book__col--sidebar book__col--mobile_hidden_mod">
            <ProfileSideBar
              profile={pro}
              windowWidth={windowWidth}
              eventEntryFee={info?.event?.entry_fee}
            />
          </div>
          <div className="book__col book__col--main">
            <div className="book__col_in">
              <div className="signup__col_in">
                <div className="signup__info signup__info--offset_mod">
                  <h1 className="signup__title">{`Confirm ${
                    data?.event ? 'your class' : ''
                  } with ${pro?.user?.name}`}</h1>
                  <div className="signup__subtitle">
                    {new Date(
                      info?.callRequest?.scheduled_at || info?.event.scheduled_at
                    ).toLocaleDateString('en-US', dateOptions)}
                  </div>
                  <div className="signup__descr">
                    You don’t need to remember an email and password, plus we’ll send you
                    a reminders before your talk
                  </div>
                </div>
                {isDatePast && (
                  <div className="signup__info signup__info--offset_mod">
                    <div className="signup__descr">
                      This call has already passed. Please choose another time.
                    </div>
                  </div>
                )}

                <div className="signup__tabs">
                  <div className="signup__tabs_content">
                    <div className="signup__tabs_content_item signup__tabs_content_item--active">
                      <div className="signup__form">
                        <form className="signup__form_in">
                          <div className="form_group">
                            <button
                              type="submit"
                              className="form_group__button form_group__button--icon_mod form_group__button--bg_mod"
                              onClick={(e) => {
                                e.preventDefault()
                                signIn()
                              }}
                            >
                              <span className="form_group__button_img">
                                <img
                                  className="form_group__button_img_in"
                                  src={LogoGoogle}
                                  alt="Google icon"
                                ></img>
                              </span>
                              Continue with Google
                            </button>
                          </div>
                          <div className="form_group">
                            {/* TODO: Update redirect URI via Apple side */}
                            <AppleLogin
                              clientId="com.talkpro.login"
                              redirectURI="https://www.talkpro.com/user/confirm"
                              usePopup={true}
                              scope="email name"
                              render={(renderProps) => (
                                <button
                                  className="form_group__button form_group__button--icon_mod form_group__button--bg_mod"
                                  onClick={renderProps.onClick}
                                >
                                  <span className="form_group__button_img">
                                    <img
                                      className="form_group__button_img_in"
                                      src={LogoApple}
                                      alt="Apple icon"
                                    ></img>
                                  </span>
                                  Continue with Apple
                                </button>
                              )}
                            />
                          </div>

                          {data?.pro?.rate === '0.00' && !isGuest && (
                            <>
                              <div className="form_group form_group--separator_mod">
                                <div class="form_group__separator">Or</div>
                              </div>

                              <div className="form_group">
                                <button
                                  type="submit"
                                  className="form_group__button form_group__button--icon_mod form_group__button--bg_mod"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setIsGuest(true)
                                  }}
                                >
                                  Continue as Guest
                                </button>
                              </div>
                            </>
                          )}
                          {isGuest && (
                            <>
                              <div className="form_group form_group--separator_mod">
                                <div class="form_group__separator">Or</div>
                              </div>
                              <div className="form_group">
                                <input
                                  type="text"
                                  className="form_group__field"
                                  placeholder="Enter your email"
                                  value={guestEmail}
                                  onChange={(e) => setGuestEmail(e.target.value)}
                                />
                              </div>
                              <button
                                type="submit"
                                className="form_group__button form_group__button--icon_mod form_group__button--bg_mod"
                                onClick={(e) => {
                                  e.preventDefault()
                                  callApi
                                    .JoinGuestCall({
                                      pro_id: data?.pro?.id,
                                      category_name: data?.pro?.category,
                                      client_email: guestEmail,
                                      timezone:
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                      scheduled_at:
                                        data?.scheduled_at ||
                                        data?.callRequest?.scheduled_at,
                                    })
                                    .then((res) => {
                                      window.localStorage.setItem(
                                        'info',
                                        JSON.stringify(data)
                                      )
                                      history.push(
                                        '/user/callback',
                                        data,
                                        res?.result?.call_info
                                      )
                                    })
                                }}
                              >
                                Submit
                              </button>
                            </>
                          )}

                          {pro?.rate !== '0.00' && (
                            <div className="signup__tabs_item_descr">
                              You will be asked for payment in the next step
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes } }) => ({
  user,
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(JoinConfirmPage)
