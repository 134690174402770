import { React, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { AccountSidebar } from 'components/AccountSidebar'
import { ProfileForm } from 'components/ProfileForm'

const AccountProfile = (props) => {
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  return (
    <section className="section wrap_mod">
      {windowWidth < 1024 && props.sidebar && (
        <div className="section_col sidebar_mod left_bar_mod no_mob_offset">
          <AccountSidebar />
        </div>
      )}
      {windowWidth >= 1024 && (
        <div className="section_col sidebar_mod left_bar_mod no_mob_offset">
          <AccountSidebar />
        </div>
      )}
      <div className="section_col content_2_mod col_mod padding_mod">
        <ProfileForm />
      </div>
    </section>
  )
}
const mapStateToProps = ({ auth: { sidebar } }) => ({
  sidebar,
})

export default connect(mapStateToProps)(AccountProfile)
