import React from 'react'
import { Link } from 'react-router-dom'

import LogoImg from 'i/icons/talkpro_logo_white.svg'

import style from './Logo.module.scss'

const Logo = () => {
  return (
    <Link className={style.logo}>
      <img src={LogoImg} className={style.logoImage} alt="" />
    </Link>
  )
}

export default Logo
