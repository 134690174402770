import { PROMISE_STATES } from 'utils/constants'
import {
  AUTH_START,
  AUTH_ERROR_RESET,
  AUTH_FAIL,
  AUTH_SUCCESS,
  AUTH_REFRESH_TOKEN,
  SET_ONLINE_STATE,
  SET_IS_PRO,
  SET_ATTRIBUTES,
  SET_REQUESTS,
  SET_CALLS,
  SET_PRO,
  SHOW_SIDEBAR,
  SET_EVENT_SETTINGS,
  SET_EVENTS,
} from '../actions/actionTypes'

const initialState = {
  attributes: null,
  user: null,
  groups: [],
  isPro: null,
  token: null,
  error: null,
  loading: PROMISE_STATES.idle,
  requests: [],
  calls: [],
  isOnline: window.navigator.onLine,
  pro: null,
  sidebar: true,
  eventSettings: null,
  events: [],
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return { ...state, loading: PROMISE_STATES.pending, socialMessage: '', error: null }

    case AUTH_FAIL: {
      // Convert error message to i18n key code
      const errorMessage =
        typeof action.payload === 'string' || !action.payload
          ? action.payload
          : action.payload.message || action.payload.code

      const error =
        errorMessage && errorMessage.replace(/\s/g, '_').replace(/\W/g, '').toUpperCase()

      return {
        ...state,
        socialMessage: error ? '' : state.socialMessage,
        loading: PROMISE_STATES.rejected,
        error,
      }
    }

    case AUTH_SUCCESS:
      const { user } = action.payload

      return {
        ...state,
        attributes: user,
        user,
        loading: PROMISE_STATES.fulfilled,
        error: null,
        socialMessage: '',
      }

    case SET_IS_PRO: {
      return { ...state, isPro: action.payload }
    }
    case SET_ATTRIBUTES: {
      return { ...state, attributes: action.payload }
    }

    case AUTH_ERROR_RESET:
      return { ...state, error: null }

    case SET_ONLINE_STATE: {
      return { ...state, isOnline: action.payload }
    }

    case SET_REQUESTS: {
      return { ...state, requests: action.payload }
    }

    case SET_CALLS: {
      return { ...state, calls: action.payload }
    }

    case SET_EVENTS: {
      return { ...state, events: action.payload }
    }

    case AUTH_REFRESH_TOKEN: {
      return { ...state }
    }

    case SET_EVENT_SETTINGS: {
      return { ...state, eventSettings: action.payload }
    }

    case SHOW_SIDEBAR: {
      return { ...state, sidebar: action.payload }
    }

    case SET_PRO: {
      return { ...state, pro: action.payload }
    }

    default:
      return { ...state }
  }
}

export default authReducer
