import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { subMinutes, differenceInSeconds, isFuture, isPast, addHours } from 'date-fns'
import DailyIframe from '@daily-co/daily-js'

import { Spinner } from 'components/Spinner'
import { WaitingRoomSection } from 'components/WaitingRoomSection'
import { CallInfo } from 'components/CallInfo'
import { Button } from 'components/ui/Button'

import StartImg from 'i/talk_start.svg'
import TalkBanner from 'i/talk_banner.svg'

import { callApi } from 'api'

const Event = (props) => {
  const history = useHistory()
  const callObj = props?.location?.state
  const [loading, setLoading] = useState(true)
  const [isEnding, setIsEnding] = useState(false)
  const [callLive, setCallLive] = useState(false)
  const [isCall, setIsCall] = useState(false)

  let [transcription, setTranscription] = useState(
    JSON.parse(window.localStorage.getItem('transcription')) || []
  )

  let title = callObj?.title
  let host = callObj?.pro?.user?.name

  useEffect(() => {
    document.title = `TalkPro | ${title} with ${host}`
  }, [])

  const videoRef = useRef(null)

  let [timeLeft, setTimeLeft] = useState(null)

  let headerdisplay = document.getElementsByClassName('header')
  let basedisplay = document.getElementsByClassName('base')
  let chatdisplay = document.getElementsByClassName('section')
  let timerdisplay = document.getElementsByClassName('talk_start_in')
  let joincalldisplay = document.getElementsByClassName('btn_v7_upper')

  useEffect(() => {
    window.localStorage.setItem('transcription', JSON.stringify(transcription) || [])
  }, [transcription])

  useEffect(() => {
    if (callObj && timeLeft !== null) {
      setLoading(false)
    }
  }, [callObj, timeLeft])

  useEffect(() => {
    setTranscription(JSON.parse(window.localStorage.getItem('transcription') || []))
  }, [])

  const startContent = {
    img: !isFuture(new Date(callObj?.scheduled_at)) ? TalkBanner : StartImg,

    title: callLive
      ? ``
      : isFuture(new Date(callObj?.scheduled_at)) && timeLeft !== null
      ? `Call starts in ${
          Math.trunc((timeLeft / 60) % 60) < 10
            ? `0${Math.trunc((timeLeft / 60) % 60)}`
            : Math.trunc((timeLeft / 60) % 60)
        }:${timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}`
      : timeLeft !== null
      ? `Call Started ${
          Math.trunc((Math.abs(timeLeft) / 60) % 60) < 10
            ? `0${Math.trunc((Math.abs(timeLeft) / 60) % 60)}`
            : Math.trunc((Math.abs(timeLeft) / 60) % 60)
        } minutes ago`
      : '',

    text: isFuture(new Date(callObj?.scheduled_at))
      ? `Start new call from the previous conversations list or search for an existing contact`
      : callLive
      ? `Scheduled call for 1 hour. If you stay on the call past 1 hours you will be billed average at $${parseInt(
          callObj?.entry_fee
        ).toFixed(0)}/hr`
      : `You have 15 min to join the call or receive a strike. The third stike will suspend you account.`,

    buttons: isFuture(new Date(callObj?.scheduled_at)) ? null : ['Call Issue'],
  }

  setInterval(function () {
    setTimeLeft(differenceInSeconds(new Date(callObj?.scheduled_at), new Date()))
  }, 1000)

  const startCall = () => {
    callApi.startGroupCall({ id: callObj.id }).then((res) => {
      const searchParams = new URLSearchParams(new URL(res?.result?.result).search)
      const params = Object.fromEntries(searchParams.entries())
      let callFrame = DailyIframe.createFrame(videoRef.current, {
        iframeStyle: {
          position: 'fixed',
          left: '0',
          bottom: '0',
          width: '100%',
          height: '100%',
        },
        showLeaveButton: true,
        showFullscreenButton: true,
        userName: props.user?.user?.name,
      })
      callFrame.on('left-meeting', async (event) => {
        setIsEnding(true)
        setLoading(true)
        setIsCall(false)
        await callApi
          .endGroupCall({
            id: callObj.id,
            transcription: window.localStorage.getItem('transcription'),
          })
          .then((res) => {
            history.push(`/summary/group/${callObj.id}`)
            window.localStorage.removeItem('transcription')
          })
        setLoading(false)
      })
      callFrame.join({ url: props.location.state.join_call_url, token: params.t })
      callFrame.on('joined-meeting', (event) => {
        callFrame.startTranscription()
      })
      callFrame.on('app-message', (msg) => {
        const data = msg.data
        if (msg?.fromId === 'transcription' && data?.is_final) {
          const local = callFrame.participants().local
          const userName =
            local.session_id === data.session_id
              ? local.user_name
              : callFrame.participants()[data.session_id].user_name

          console.log(`Transcription: ${userName} - ${data?.text}`)
          setTranscription((prevTranscription) => [
            ...prevTranscription,
            {
              name: userName,
              date: data?.timestamp,
              text: data?.text,
            },
          ])
        }
      })
    })
  }
  return (
    <WaitingRoomSection>
      {!isCall ? (
        <>
          {loading ? (
            <>
              <Spinner className={'spinner_button'} />
              {isEnding && <CallInfo.Title>Call saving</CallInfo.Title>}
            </>
          ) : (
            <CallInfo>
              <CallInfo.Title>{startContent?.title}</CallInfo.Title>
              <CallInfo.Content>{startContent?.text}</CallInfo.Content>
              {((isFuture(addHours(new Date(callObj.scheduled_at), 12)) &&
                isPast(subMinutes(new Date(callObj.scheduled_at), 15))) ||
                timeLeft <= 0) &&
                !callObj.finished_at && (
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => (
                      e.preventDefault(),
                      setIsCall(true),
                      startCall(),
                      setCallLive(true),
                      (headerdisplay.className += ' remove'),
                      (basedisplay.className += ' no_header_mod'),
                      (chatdisplay.className += ' active_call_width'),
                      (timerdisplay.className += ' active_call_margin'),
                      (joincalldisplay.className += ' remove')
                    )}
                  >
                    Join Call
                  </Button>
                )}
              {callObj.finished_at && (
                <div className="talk_send join_mod">
                  <Button variant="light" size="sm" onClick={(e) => e.preventDefault()}>
                    Call ended on {new Date(callObj.finished_at).toDateString()}
                  </Button>
                </div>
              )}
            </CallInfo>
          )}
        </>
      ) : (
        <div id="daily_video" ref={videoRef} />
      )}
    </WaitingRoomSection>
  )
}

const mapStateToProps = ({ auth: { user, isPro } }) => ({
  user,
  isPro,
})

export default connect(mapStateToProps)(Event)
