import { React, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  isFuture,
  differenceInDays,
  differenceInMinutes,
  differenceInHours,
  differenceInSeconds,
  addMinutes,
  isPast,
} from 'date-fns'
import { Button } from 'components/ui/Button'

import { callApi, eventApi } from 'api'

import { setCalls } from 'store/actions'
import { WaitingRoomSection } from 'components/WaitingRoomSection'
import { CallInfo } from 'components/CallInfo'
import { Spinner } from 'components/Spinner'

const TuberPage = (props) => {
  let [calls, setCalls] = useState([])
  let [events, setEvents] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  let [timeLeft, setTimeLeft] = useState()
  let [callsAndEvents, setCallsAndEvents] = useState()
  let [isLoadingCalls, setIsLoadingCalls] = useState(true)
  let [isLoadingEvents, setIsLoadingEvents] = useState(true)
  let [confirm, setConfirm] = useState(false)

  const history = useHistory()

  useEffect(() => {
    document.title = 'TalkPro | Dashboard'
  }, [])

  useEffect(() => {
    if (props.isPro) {
      history.push('/dashboard/pro')
    }

    eventApi.getUserEvents().then((res) => {
      const filteredEvents = res?.result
        ?.filter((e) => {
          return (
            !e.canceled &&
            isFuture(addMinutes(new Date(e?.scheduled_at), 15)) &&
            !e.declined &&
            !e?.finished_at
          )
        })
        ?.sort((a, b) => new Date(a?.scheduled_at) - new Date(b?.scheduled_at))

      setEvents(filteredEvents)
      setIsLoadingEvents(false)
    })

    callApi.getUserCalls().then((res) => {
      const filteredCalls = res?.result
        ?.sort((a, b) => new Date(a?.scheduled_at) - new Date(b.scheduled_at))
        .filter((e) => {
          return (
            !e.canceled &&
            isFuture(addMinutes(new Date(e?.scheduled_at), 15)) &&
            !e.declined &&
            !e?.finished_at
          )
        })

      setCalls(filteredCalls)
      setIsLoadingCalls(false)

      props.setCalls(
        res?.result?.sort((a, b) => new Date(a?.scheduled_at) - new Date(b.scheduled_at))
      )
    })
  }, [props])

  useEffect(() => {
    if (!isLoadingCalls && !isLoadingEvents) {
      setCallsAndEvents([...events, ...calls])
      setIsLoading(false)
    }
  }, [isLoadingCalls, isLoadingEvents, events, calls])

  let latestCall = callsAndEvents
    ?.filter((e) => {
      return isFuture(addMinutes(new Date(e?.scheduled_at), 15))
    })
    ?.sort((a, b) => new Date(a?.scheduled_at) - new Date(b?.scheduled_at))[0]

  const scheduledAt = latestCall?.scheduled_at

  const handleReschedule = () => {
    callApi.cancelCall({ id: latestCall?.id })

    history.push(`/${latestCall?.pro?.channel_name}`)
  }

  useEffect(() => {
    if (latestCall?.started_at) {
      history.push(`/talk/${latestCall?.id}`, latestCall)
    }

    setTimeLeft(differenceInSeconds(new Date(latestCall?.scheduled_at), new Date()))
  }, [latestCall])

  const callWaiting = () => {
    if (timeLeft <= 0) {
      history.push(`/talk/${latestCall?.id}`, latestCall)
    }
    setInterval(function () {
      if (timeLeft > 0) {
        setTimeLeft(differenceInSeconds(new Date(latestCall?.scheduled_at), new Date()))
      }
    }, 1000)
  }
  callWaiting()

  return (
    <WaitingRoomSection>
      <CallInfo logoHref={props.isPro ? '/dashboard/pro' : '/dashboard/user'}>
        <CallInfo.Title>
          {isLoading && (
            <Spinner className="form_group__button spinner_mod" button={true} />
          )}
          {!isLoading && !callsAndEvents?.length && `No call scheduled`}
          {callsAndEvents?.length
            ? differenceInHours(new Date(scheduledAt), new Date()) < 24
              ? differenceInMinutes(new Date(scheduledAt), new Date()) < 60
                ? `Next call with ${latestCall?.pro?.user?.name} is about to start in ${
                    Math.trunc((timeLeft / 60) % 60) < 10
                      ? `0${Math.trunc((timeLeft / 60) % 60)}`
                      : Math.trunc((timeLeft / 60) % 60)
                  }:${timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}`
                : `Next call with ${
                    latestCall?.pro?.user?.name
                  } start in ${differenceInHours(
                    new Date(scheduledAt),
                    new Date(),
                    'ceil'
                  )} hours`
              : `Next call with ${
                  latestCall?.pro?.user?.name
                }  starts in ${differenceInDays(
                  new Date(scheduledAt),
                  new Date(),
                  'ceil'
                )} days`
            : ''}
        </CallInfo.Title>
        <CallInfo.Buttons>
          {latestCall && !confirm ? (
            <Button variant="light" size="sm" onClick={(e) => setConfirm(true)}>
              Reschedule
            </Button>
          ) : (
            latestCall &&
            confirm && (
              <div className="confirm_button">
                Are you sure?
                <div className="cancel_buttons">
                  <Button variant="light" size="sm" onClick={(e) => setConfirm(false)}>
                    Cancel
                  </Button>
                  <Button variant="light" size="sm" onClick={handleReschedule}>
                    Confirm
                  </Button>
                </div>
              </div>
            )
          )}
          {((isFuture(addMinutes(new Date(latestCall?.scheduled_at), 15)) &&
            isPast(new Date(latestCall?.scheduled_at))) ||
            timeLeft <= 0) && (
            <Button
              variant="light"
              size="sm"
              onClick={(e) =>
                history.push(`/talk/${latestCall?.call_request}`, latestCall)
              }
            >
              Join Waiting Room
            </Button>
          )}
        </CallInfo.Buttons>
        {!isLoading && !calls[0] ? `Contact a pro to receive URL` : ''}
      </CallInfo>
    </WaitingRoomSection>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user } }) => ({
  authError: error,
  user,
  isPro,
})

const willMapDispatchToProps = {
  setCalls,
}

export default connect(mapStateToProps, willMapDispatchToProps)(TuberPage)
