import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isPast, format, addYears } from 'date-fns'

import { Spinner } from 'components/Spinner'

import { callApi, eventApi } from 'api'

const CallHistory = ({ isPro, user }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [calls, setCalls] = useState()
  const [events, setEvents] = useState()
  const [callsLoading, setCallsLoading] = useState(true)
  const [callsAndEvents, setCallsAndEvents] = useState()

  let subscriptionText =
    Math.abs(parseInt(user.subscription_balance) - 85) === 85
      ? `$85 Paid`
      : `$${Math.abs(parseInt(user.subscription_balance) - 85)} of $85 paid`

  const renewalDate = format(
    addYears(new Date(user.subscription_timestamp), 1),
    'MMMM dd, yyyy'
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    setCallsAndEvents(true)
    isPro
      ? callApi
          .getCalls()
          .then((res) =>
            setCalls(
              res?.result?.filter(
                (e) => isPast(new Date(e?.scheduled_at)) && e?.finished_at
              )
            )
          )
      : callApi
          .getUserCalls()
          .then((res) =>
            setCalls(
              res?.result?.filter(
                (e) => isPast(new Date(e?.scheduled_at)) && e?.finished_at
              )
            )
          )
    window.addEventListener('resize', handleResize)

    isPro
      ? eventApi
          .getProEvents()
          .then((res) =>
            setEvents(
              res.result?.filter((e) => isPast(new Date(e?.scheduled_at)) && e.title)
            )
          )
      : eventApi
          .getUserEvents()
          .then((res) =>
            setEvents(
              res.result?.filter((e) => isPast(new Date(e?.scheduled_at)) && e.title)
            )
          )
  }, [isPro])

  useEffect(() => {
    if (calls && events) {
      setCallsAndEvents(
        [...calls, ...events].sort(
          (a, b) => new Date(b.scheduled_at) - new Date(a.scheduled_at)
        )
      )
    }

    if (calls && events) {
      setCallsLoading(false)
    }
  }, [calls, events])

  return (
    <div className="settings_table_w">
      <div className="subscription_info">
        <div className="subscription_info_in">
          <div className="subscription_info_content">
            <div className="subscription_info_row">
              <div className="subscription_info title">Anual Fee</div>
              <div className="subscription_info_content in">{subscriptionText}</div>
            </div>
            <div
              className="subscription_info_row"
              style={{ marginRight: '1rem', marginLeft: '0' }}
            >
              <div className="subscription_info title">Renewal date</div>
              <div className="subscription_info_content in">{renewalDate}</div>
            </div>
          </div>
        </div>
      </div>
      {callsLoading ? (
        <Spinner style={{ position: 'relative', top: '6rem' }} />
      ) : (
        callsAndEvents?.length === 0 && (
          <div className="requests_empty_placeholder">No Prior talks</div>
        )
      )}
      {callsAndEvents && callsAndEvents.length && (
        <ul className="settings_table_in">
          <div className="settings_table_row">
            <div className="settings_table_item">Date</div>
            <div className="settings_table_item">Time</div>
            <div className="settings_table_item">Attendee(s)</div>
            <div className="settings_table_item">{''}</div>
            <div className="settings_table_item">Amount</div>
            <div className="settings_table_item">Fees</div>
            <div className="settings_table_item">Net</div>
          </div>
          {callsAndEvents?.map((e, i) => (
            <div className="settings_table">
              <div
                className={
                  callsAndEvents.length === i + 1
                    ? 'settings_table_row last_child'
                    : 'settings_table_row'
                }
              >
                <div className="settings_table_item">
                  {format(new Date(e?.scheduled_at), 'M/d/yy').toString()}
                </div>
                <div className="settings_table_item">
                  {e.started_at && e.finished_at
                    ? `${format(new Date(e.started_at), 'p')} to ${' '}
                  ${format(new Date(e.finished_at), 'p')}`
                    : `${format(new Date(e.scheduled_at), 'p')}`}
                </div>
                <div className="settings_table_item">
                  {isPro ? e.client?.user?.name : e.pro?.user?.name}
                  {e.attendes &&
                    `${
                      e.attendes?.split(',').filter((e) => e !== ' ' && e !== '').length
                    } / ${e.max_participants} attendees`}
                </div>
                <div className="settings_table_item">{''}</div>
                <div className="settings_table_item">
                  ${e.total_cost || parseInt(e.entry_fee).toFixed(0)}
                </div>
                <div className="settings_table_item">
                  ${e.platform_fee || (parseInt(e.entry_fee) * 0.03).toFixed(2)}
                </div>
                <div className="settings_table_item">
                  ${e.pro_profit || (parseInt(e.entry_fee) * 0.97).toFixed(2)}
                </div>
              </div>
            </div>
          ))}
        </ul>
      )}
    </div>
  )
}

const mapStateToProps = ({ auth: { isPro, user } }) => ({
  isPro,
  user,
})

export default connect(mapStateToProps)(CallHistory)
