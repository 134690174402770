import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? <Component {...props} /> : <Redirect to="/" />
    }
  />
)

const mapStateToProps = ({ auth: { user, error, attributes, isPro } }) => ({
  user,
  authError: error,
  attributes,
  isPro,
})

export default connect(mapStateToProps)(PrivateRoute)
