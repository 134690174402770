import React, { useState } from 'react'

import UiContext from './UiContext'

const UiState = ({ children }) => {
  const [activePopupId, setActivePopupId] = useState(null)
  const [dashboardTabIndex, setDashboardTabIndex] = useState(0)
  const [activeChatVideo, setActiveChatVideo] = useState(null)
  const [isNewUser, setIsNewUser] = useState(true)
  const [sentInvite, setSentInvite] = useState(false)
  const [createdClass, setCreatedClass] = useState(false)

  return (
    <UiContext.Provider
      value={{
        activePopupId,
        setActivePopupId,
        dashboardTabIndex,
        setDashboardTabIndex,
        activeChatVideo,
        setActiveChatVideo,
        isNewUser,
        setIsNewUser,
        sentInvite,
        setSentInvite,
        createdClass,
        setCreatedClass,
      }}
    >
      {children}
    </UiContext.Provider>
  )
}

export default UiState
