import { apiRequest } from '../../apiRequest'

export const getUserRequests = () => apiRequest('/user/call-requests/').get()

export const sendCallRequest = (data) => apiRequest('/user/call-request/').post(data)

export const confirmUserCall = (data) =>
  apiRequest(`/user/call-request/${data.id}/approve/`).post()

export const JoinGuestCall = (data) => apiRequest(`/user/call-request/free/`).post(data)

export const getUserCalls = () => apiRequest('/user/calls/').get()
export const getUserPersonalCalls = () => apiRequest('/user/call-records/').get()
