const APP_DATA = {
  popups: {
    createClass: {
      title: 'Create Event',
      description:
        'Choose which days and from what time you would like to offer 1on1 calls. You can always edit your schedule later',
    },
    editClass: {
      title: 'Edit Class',
      description:
        'Choose which days and from what time you would like to offer 1on1 calls. You can always edit your schedule later',
    },
    availability: {
      title: 'Availability',
      description:
        'Choose which days and from what time you would like to offer 1on1 calls. You can always edit your schedule later',
    },
    availabilityRatePage: {
      title: 'Share Availability',
      description: 'You can optionally allow anyone to book time with you.',
    },
  },
}

export default APP_DATA
