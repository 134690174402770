import { useEffect, useState } from 'react'
import { PROMISE_STATES } from 'utils/constants'

const useFetch = (fetchFunction, options = { auto: true }) => {
  const [loading, setLoading] = useState(PROMISE_STATES.idle)
  const [error, setError] = useState(null)
  const [statusCode, setStatusCode] = useState(null)
  const [data, setData] = useState(null)

  const fetchData = async (...args) => {
    try {
      setLoading(PROMISE_STATES.pending)

      const data = await fetchFunction(...args)

      if (data?.error) {
        setLoading(PROMISE_STATES.rejected)
        setError(data?.error?.message)
        setStatusCode(data?.error?.status)
      } else {
        setData(data)
        setLoading(PROMISE_STATES.fulfilled)
      }
    } catch (unknownError) {
      setLoading(PROMISE_STATES.rejected)
      setStatusCode(unknownError?.status)
      if (unknownError instanceof Error) {
        setError(unknownError)
      } else {
        setError(new Error(String(unknownError)))
      }
    }
  }

  useEffect(() => {
    if (options.auto) {
      fetchData()
    }
  }, [])

  return {
    loading,
    error,
    data,
    fetchData,
    statusCode,
  }
}

export default useFetch
