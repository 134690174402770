import { apiRequest } from '../../apiRequest'

export const getProRequests = () => apiRequest('/pro/call-requests/').get()

export const confirmProCall = (data) =>
  apiRequest(`/pro/call-request/${data.id}/confirm/`).post(data)

export const getCalls = () => apiRequest('/pro/calls/').get()

export const getProPersonalCalls = () => apiRequest('/pro/call-records/').get()
export const getProGroupCalls = () => apiRequest('/pro/group-call-records/').get()

export const saveContacts = (data) =>
  apiRequest('/pro/contacts/').post({ contacts: data })

export const getContacts = () => apiRequest('/pro/contacts/').get()

export const sendInvite = (data) => apiRequest('/pro/invite/').post(data)

export const sendInviteToGroup = (data) => apiRequest('/pro/invite-group/').post(data)

export const getCallTranscription = (callType, callId) =>
  apiRequest(`/call/transcription/${callType}/${callId}/`).get()

export const shareMessage = (callType, callId) =>
  apiRequest(`pro/share-meeting-notes/${callType}/${callId}/`).get()

export const huddleTalk = () => apiRequest('/pro/instant-call/').post()
