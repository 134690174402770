import React from 'react'

import styles from './AuthCol.module.scss'

const AuthCol = ({ children, type = 'content' }) => {
  return (
    <div className={styles.col}>
      {type === 'image' && children}
      {type === 'gradient' && <div className={styles.gradient} />}
      {type === 'content' && <div className={styles.colIn}>{children}</div>}
    </div>
  )
}

export default AuthCol
