import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { UiContext } from 'context/ui'

import { POPUPS_ID } from 'utils/constants'
import { TalksList } from 'components/Talks'
import styles from './DashboardRecentCalls.module.scss'

import addWhiteIcon from 'i/icons/add_new.svg'
import { Spinner } from 'components/Spinner'

import { setCalls, setRequests } from 'store/actions'
import { TalkListPreview } from 'components/TalkListPreview'
import { FetchResult } from 'components/FetchResult'
import { useFetchProCalls } from './hook'
import { ReactComponent as SearchIcon } from 'i/icons/search_new.svg'

const DashboardRecentCalls = (props) => {
  const { loading, error, previousCalls, previousRecordedCalls } = useFetchProCalls(props)

  const { activePopupId, setActivePopupId } = useContext(UiContext)

  const openSearch = () => {
    setActivePopupId('search')
  }

  const showPopup = (popupId) => {
    setActivePopupId(popupId)
  }

  return (
    <div className={styles.DashboardRecentCalls}>
      <>
        <FetchResult
          error={error}
          status={loading}
          data={previousCalls}
          indicator={<TalkListPreview />}
        >
          {previousCalls && activePopupId !== POPUPS_ID.chat && (
            <div className={styles.DashboardRecentCallsHead}>
              <div className={styles.DashboardRecentCallsTitle}>Past Talks</div>
              <div className={styles.DashboardRecentCallsSubtitle}>
                Video recordings and transcripts
              </div>
              <button type="button" className={styles.DashboardRecentCallsSeach}>
                <SearchIcon
                  className={styles.DashboardRecentCallsSeachIcon}
                  onClick={openSearch}
                />
              </button>
            </div>
          )}

          {previousCalls && (
            <div className={styles.DashboardRecentCallsTalks}>
              <TalksList
                calls={previousCalls}
                recordedCalls={previousRecordedCalls}
                toggleSidebar={props.toggleSidebar}
              />
            </div>
          )}

          {!previousCalls && (
            <>
              <div
                class="dashboardCreateMeetingWrap"
                onClick={() => showPopup(POPUPS_ID.sendInvite)}
              >
                <div class="createIconWrap">
                  <img src={addWhiteIcon} class="start_icon" />
                </div>
                <div class="startContentWrap">
                  <p>Create first meeting</p>
                </div>
              </div>
              <div class="dashboardCreate_bottom_text">talkpro</div>
            </>
          )}
        </FetchResult>
      </>
    </div>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user, calls, requests } }) => ({
  authError: error,
  user,
  isPro,
  calls,
  requests,
})

const willMapDispatchToProps = {
  setRequests,
  setCalls,
}

export default connect(mapStateToProps, willMapDispatchToProps)(DashboardRecentCalls)
