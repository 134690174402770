import React from 'react'

import { authApi } from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactComponent as LogoutIcon } from 'i/icons/logout.svg'

import styles from './WaitingRoomSection.module.scss'
import classNames from 'classnames'

export function WaitingRoomSection({ children, variant, withoutLogout }) {
  const history = useHistory()

  const handleSignOut = () => {
    authApi.logOut().then((history.push('/'), window.localStorage.clear()))
  }

  return (
    <div
      className={classNames(styles.component, {
        [styles.huddleVariant]: variant === 'huddle',
      })}
    >
      <div className={styles.subComponent}></div>
      {!withoutLogout && (
        <div className={styles.logout}>
          <button className={styles.button} onClick={handleSignOut}>
            Logout
            <div className={styles.buttonIconWrap}>
              <LogoutIcon className={styles.buttonIcon} />
            </div>
          </button>
        </div>
      )}
      <div className={styles.container}>{children}</div>
    </div>
  )
}

export default WaitingRoomSection
