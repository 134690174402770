import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { enUS } from 'date-fns/locale'
import { add, format } from 'date-fns'
import { DatePicker } from 'react-nice-dates'
import { ErrorMessage, Form, Formik } from 'formik'

import { AlertMessage } from 'components/AlertMessage'
import { Spinner } from 'components/Spinner'
import { FormTimeSelect, FormTextArea, FormFile } from 'components/Form'
import FormInputWithLabel from 'components/Form/FormInputWithLabel/FormInputWithLabel'
import FormInputDropdown from 'components/Form/FormInputDropdown/FormInputDropdown'
import { FormField } from './ui'

import { ReactComponent as ProfileIcon } from 'i/icons/profile.svg'

import { setEventSettings } from 'store/actions'

import 'react-nice-dates/build/style.css'
import styles from './CreateClassForm.module.scss'

import { liveClassesServiceName } from 'utils/constants'
import times from 'utils/times.json'

import { eventApi, paymentApi, profileApi, callApi } from 'api'

const rateSchema = Yup.object().shape({
  title: Yup.string().required('Class name is required'),
  date: Yup.string().required('Date is required'),
  time: Yup.string()
    .required('Time is required')
    .notOneOf(['Time'], 'Time is not selected'),
  // entry_fee: Yup.string().required('Entry price is required'),
  // max_participants: Yup.string().required('Max attendance is required'),
  description: Yup.string().required('Description is required'),
})

const getDate = (scheduledAt) => {
  return scheduledAt ? new Date(scheduledAt) : new Date()
}

const CreateClassForm = ({
  isOpen,
  initialValues,
  setFieldValue,
  onSubmitCallback,
  pro,
  onSignup,
}) => {
  const itemClassName = `${styles.item} ${styles.itemHalfMod}`
  const [errorSubmitMessage, setErrorSubmitMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [coverImg, setCoverImg] = useState(null)
  const [contacts, setContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [isEmailDropdownOpen, setEmailDropdownOpen] = useState(false)
  const [emails, setEmails] = useState([])
  const [textInput, setTextInput] = useState('')

  useEffect(() => {
    if (isOpen && !onSignup) {
      callApi.getContacts().then((res) => {
        if (res?.result?.result) {
          setContacts(JSON.parse(res?.result?.result))
          setFilteredContacts(JSON.parse(res?.result?.result))
        } else {
          setContacts([])
          setFilteredContacts([])
        }
      })
    }
  }, [isOpen, onSignup])

  useEffect(() => {
    if (window.localStorage.getItem('editEvent')) {
      let invitedUsers = JSON.parse(
        window.localStorage.getItem('editEvent')
      ).invited_users
      let parsedInvitedUsers = invitedUsers.map((e) => {
        return { email: e, displayName: e, isInvited: true }
      })
      setEmails(parsedInvitedUsers)
    }
  }, [])

  const filterTextByInput = (inputString) => {
    const newFilteredContacts = []

    for (const item of contacts) {
      if (
        item?.email?.includes(inputString) ||
        item?.displayName?.toLowerCase()?.includes(inputString)
      ) {
        newFilteredContacts.push(item)
      }
    }
    if (inputString.length === 0) {
      setFilteredContacts([...newFilteredContacts])
    } else {
      setFilteredContacts([...newFilteredContacts])
    }
  }

  const removeContact = (email) => {
    setContacts(contacts.filter((e) => e.email !== email))
  }

  const renderErrorMessage = (message) => {
    return <AlertMessage message={message} />
  }

  const createFieldName = (name) => {
    return name
  }

  const earliestTime = times
    .map((e) => {
      const timeParts = e.time.split(' ')
      let hours = parseInt(timeParts[0].split(':')[0])
      let minutes = parseInt(timeParts[0].split(':')[1])
      let isPM = timeParts[1] === 'PM'

      // If the time is AM and the hour is 12, set it to 0
      if (!isPM && hours === 12) {
        hours = 0
      }

      // If the time is PM and the hour is not 12, add 12 to the hours
      if (isPM && hours !== 12) {
        hours += 12
      }

      const currentTime = new Date()
      const targetTime = new Date(currentTime)
      targetTime.setHours(hours, minutes, 0, 0)

      return {
        time: targetTime,
        original: e.time, // Store the original time for reference
      }
    })
    .filter((e) => e.time > new Date())
    .sort((a, b) => a.time - b.time)[0]

  document?.querySelector('#clientEmail')?.addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
      setEmails([...emails, textInput])
      e.preventDefault()
      setTextInput('')
    }
  })

  let storageItems = JSON.parse(localStorage.getItem('callInfo')) || {
    title: '',
    scheduled_at: '',
    time: earliestTime?.original,
    entry_fee: '',
    max_participants: '',
    description: '',
    invited_users: '',
  }

  return (
    <Formik
      initialValues={storageItems}
      validationSchema={rateSchema}
      onSubmit={async (values) => {
        setIsLoading(true)
        let parsedDate = add(
          new Date(
            values.date.getFullYear(),
            values.date.getMonth(),
            values.date.getDate()
          ),
          {
            hours:
              values.time.split(' ').includes('PM') && values.time.split(':')[0] !== '12'
                ? parseInt(values.time.split(':')[0]) + 12
                : parseInt(values.time.split(':')[0]),
            minutes: parseInt(values.time.split(':')[1]),
          }
        )

        if (coverImg) {
          const imageData = new FormData()
          imageData.append('photo', coverImg)
          await profileApi.uploadPhoto(imageData).then((res) => {
            values.cover_image = res.result.fileUrl
            profileApi.setProInfo({ photo: pro.photo })
          })
        }

        let scheduled_at = format(parsedDate, 'MM/dd/yyyy HH:mm XXXX')

        const formData = {
          description: values.description,
          pro_id: pro.id,
          title: values.title,
          entry_fee: values.entry_fee ? parseInt(values.entry_fee) : 0,
          max_participants: values.max_participants || 0,
          scheduled_at: scheduled_at,
          category_name: pro?.category,
          cover_image: values.cover_image,
          invited_users: emails.length > 0 ? emails : [textInput],
        }
        window.localStorage.getItem('editEvent')
          ? await eventApi.editEvent(
              formData,
              JSON.parse(window.localStorage.getItem('editEvent')).id
            )
          : await eventApi.createEvent(formData)

        if (setFieldValue && typeof setFieldValue === 'function') {
          setFieldValue(liveClassesServiceName, formData)
          onSubmitCallback()

          return
        }

        profileApi.setProInfo({
          [liveClassesServiceName]: JSON.stringify(formData),
        })

        window.localStorage.removeItem('editEvent')
        window.localStorage.removeItem('callInfo')
        window.location.reload()
        setIsLoading(false)
      }}
    >
      {({ values, handleChange, setFieldValue, submitForm }) => {
        if (!values?.date) {
          const date = getDate(values.scheduled_at)
          setFieldValue('date', date)
        }

        if (!values?.time) {
          const date = getDate(values.scheduled_at)
          const timeValue = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })

          setFieldValue('time', timeValue)
        }

        return (
          <Form className={styles.form}>
            <div className={styles.container}>
              <ul className={styles.list}>
                <li className={styles.item}>
                  <FormInputWithLabel label="Enter class title">
                    <FormField
                      name={createFieldName('title')}
                      id="title"
                      defaultValue={values.title}
                      handleChange={handleChange}
                    />
                  </FormInputWithLabel>
                  <ErrorMessage
                    name={createFieldName('title')}
                    render={renderErrorMessage}
                  />
                </li>
                <li className={`${itemClassName} ${styles.itemCalendar}`}>
                  <div className="date-picker">
                    <FormInputWithLabel label="Date">
                      <DatePicker
                        date={values.date ? new Date(values.date) : new Date()}
                        onDateChange={(value) => {
                          setFieldValue('date', new Date(value))
                        }}
                        locale={enUS}
                      >
                        {({ inputProps, focused }) => (
                          <input
                            className={'form_group__field' + (focused ? ' -focused' : '')}
                            {...inputProps}
                          />
                        )}
                      </DatePicker>
                    </FormInputWithLabel>
                  </div>
                  <ErrorMessage
                    name={createFieldName('date')}
                    render={renderErrorMessage}
                  />
                </li>
                <li className={itemClassName}>
                  <FormInputWithLabel select label="Time">
                    <FormTimeSelect
                      name={createFieldName('time')}
                      id="time"
                      defaultOptionValue="Time"
                      variant="lg"
                      selectedValue={values.time}
                      handleChange={handleChange}
                    />
                  </FormInputWithLabel>
                  <ErrorMessage
                    name={createFieldName('time')}
                    render={renderErrorMessage}
                  />
                </li>
                <li className={`${itemClassName} price_mod`}>
                  <FormInputWithLabel label="Entry price ($)">
                    <FormField
                      name={createFieldName('entry_fee')}
                      id="entry_fee"
                      type="number"
                      defaultValue={values.entry_fee}
                      handleChange={handleChange}
                    />
                  </FormInputWithLabel>
                  {!pro?.stripe && (
                    <button
                      onClick={(e) =>
                        paymentApi
                          .connectStripe()
                          .then((res) => (window.location = res.result.action_url))
                      }
                    >
                      Connect Stripe
                    </button>
                  )}
                  <ErrorMessage
                    name={createFieldName('entry_fee')}
                    render={renderErrorMessage}
                  />
                </li>
                <li className={itemClassName}>
                  <FormInputWithLabel label="Max attendance">
                    <FormField
                      name={createFieldName('max_participants')}
                      id="max_participants"
                      type="number"
                      defaultValue={values.max_participants}
                      handleChange={handleChange}
                    />
                  </FormInputWithLabel>
                  <ErrorMessage
                    name={createFieldName('max_participants')}
                    render={renderErrorMessage}
                  />
                </li>
                <li className={styles.item}>
                  <FormInputWithLabel textarea label="Write a short description">
                    <FormTextArea
                      name={createFieldName('description')}
                      defaultValue={values.description}
                      handleChange={handleChange}
                    />
                  </FormInputWithLabel>
                  <ErrorMessage
                    name={createFieldName('description')}
                    render={renderErrorMessage}
                  />
                </li>
                {!onSignup && (
                  <li className={styles.item}>
                    <FormInputWithLabel label="Email address" icon={<ProfileIcon />}>
                      <FormField
                        name={createFieldName('invited_users')}
                        id="invited_users"
                        defaultValue={values.invited_users}
                        value={textInput}
                        handleChange={(e) => {
                          filterTextByInput(e.target.value)
                          setTextInput(e.target.value)
                          setFieldValue('invited_users', e.target.value)
                        }}
                        handleFocus={() => setEmailDropdownOpen(true)}
                        onMouseEnter={() => setEmailDropdownOpen(true)}
                      />
                    </FormInputWithLabel>
                    <FormInputDropdown
                      data={filteredContacts}
                      contacts={contacts}
                      fieldName="invited_users"
                      isOpen={isEmailDropdownOpen}
                      removeContact={removeContact}
                      setEmails={setEmails}
                      setTextInput={setTextInput}
                      emails={emails}
                      hover={true}
                      onMouseLeave={() => {
                        setEmailDropdownOpen(false)
                      }}
                    />
                    <ErrorMessage
                      name={createFieldName('invited_users')}
                      render={renderErrorMessage}
                    />
                    <FormInputDropdown
                      data={contacts}
                      emails={emails}
                      isOpen={emails.length > 0}
                      hover={false}
                      setEmails={setEmails}
                    />
                  </li>
                )}
                <li className={itemClassName}>
                  <FormFile
                    text="Add cover image"
                    onChange={(e) => {
                      setCoverImg(e.target.files[0])
                    }}
                  />
                </li>
              </ul>
            </div>
            {errorSubmitMessage && <AlertMessage message={errorSubmitMessage} formMod />}
            {isLoading ? (
              <Spinner className="form_group__button spinner_mod" button={true} />
            ) : (
              <>
                <button
                  type="submit"
                  className="form_group__button"
                  onClick={(e) => {
                    e.preventDefault(e)
                    submitForm()
                  }}
                >
                  Confirm
                </button>
                {window.localStorage.getItem('editEvent') && (
                  <button
                    type="button"
                    className="form_group__button cancel_mod"
                    onClick={(e) => {
                      e.preventDefault()
                      eventApi
                        .cancelEvent(
                          JSON.parse(window.localStorage.getItem('editEvent')).id
                        )
                        .then((res) => {
                          window.localStorage.removeItem('editEvent')
                          window.location.reload()
                        })
                    }}
                  >
                    Cancel
                  </button>
                )}
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = ({ auth: { user, eventSettings, isPro } }) => ({
  user,
  eventSettings,
  isPro,
})

const willMapDispatchToProps = {
  setEventSettings,
}

export default connect(mapStateToProps, willMapDispatchToProps)(CreateClassForm)
