import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { format, addMinutes } from 'date-fns'

import LogoDownloadICS from 'i/download-ics.png'
import LogoDownloadIcon from 'i/icons/download-2.svg'

import { ProfileSideBar } from '../Book/ProfileSideBar'

import { paymentApi } from 'api'

import { setPro } from 'store/actions'

import { ics } from 'ics-file-export-mac'

const BookedPage = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [pro, setPro] = useState(null)
  const cal = ics()
  const data = JSON.parse(localStorage.getItem('info'))
  const isEvent = JSON.parse(localStorage.getItem('isEvent'))

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const code = [...searchParams].find(([key]) => key === 'payment_intent')?.[1]

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    if (code) {
      if (isEvent) {
        paymentApi.captureEntry({ token: code, group_call_id: data?.event?.id })
      }
      console.log(data)
      paymentApi.captureOrder({ token: code, call_id: data?.call_id })
    }

    setPro(data?.pro)

    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    document.title = 'TalkPro | Call Booked'
  }, [])

  useEffect(() => {
    props.setIsConfirmPage(true)
    return () => {
      props.setIsConfirmPage(false)
    }
  }, [props])

  console.log('data', data)

  const endDate = data?.event
    ? addMinutes(new Date(data?.event?.scheduled_at), 60)
    : addMinutes(new Date(data?.callRequest?.scheduled_at), 60)
  return (
    <section className="section profile">
      <div className="book__cols">
        <div className="book__col book__col--sidebar book__col--mobile_hidden_mod">
          <ProfileSideBar profile={pro} windowWidth={windowWidth} />
        </div>
        <div className="book__col book__col--main">
          <div className="book__col_content">
            <div className="profile_calendar">
              <div className="signup__col_in">
                <div className="signup__info">
                  <h1 className="signup__title signup__title--lg_mod">You're booked!</h1>
                  {/* <div className="signup__subtitle">
                    {new Date(
                      data?.callRequest?.scheduled_at || data?.event?.scheduled_at
                    ).toLocaleDateString('en-US', dateOptions)}
                  </div> */}
                  <div className="signup__descr">
                    We emailed you and the other attendees a calendar invitation with all
                    the details.
                  </div>
                  <ul className="signup__descr_list">
                    <li className="signup_descr_list_item">Thursday, July 27, 2023 </li>
                    <li className="signup_descr_list_item">
                      10:30 - 11:00 (Eastern Daylight Time)
                    </li>
                    <li className="signup_descr_list_item">30 Minute talk</li>
                  </ul>
                </div>

                <div className="signup__tabs">
                  <div className="signup__tabs_content">
                    <div className="signup__tabs_content_item signup__tabs_content_item--active">
                      <div className="signup__form">
                        {localStorage.getItem('login-method') === 'apple' && (
                          <div className="form_group">
                            <button
                              type="submit"
                              className="form_group__button form_group__button--icon_mod form_group__button--blue_mod"
                              onClick={(e) => {
                                data?.event
                                  ? cal.addEvent(
                                      `TALKPRO - Classes in ${data?.event?.category}`,
                                      'Join TalkPro Classes https://www.talkpro.com/user/login',
                                      'https://www.talkpro.com/user/login',
                                      `${format(
                                        new Date(data?.event?.scheduled_at),
                                        'M/dd/yyyy p'
                                      )}`,
                                      `${format(endDate, 'M/dd/yyyy p')}`
                                    )
                                  : cal.addEvent(
                                      `TALKPRO - Meeting with ${data?.pro?.channel_name}`,
                                      'Join TalkPro Meeting https://www.talkpro.com/user/login',
                                      'https://www.talkpro.com/user/login',
                                      `${format(
                                        new Date(data?.callRequest?.scheduled_at),
                                        'M/dd/yyyy p'
                                      )}`,
                                      `${format(endDate, 'M/dd/yyyy p')}`,
                                      `${props.user?.user?.email}`,
                                      `${props.user?.user?.name}`
                                    )

                                cal.download()
                              }}
                            >
                              <span className="form_group__button_img">
                                <img
                                  className="form_group__button_img_in"
                                  src={LogoDownloadICS}
                                  alt="Download ICS"
                                ></img>
                              </span>
                              Add to your Calendar
                            </button>
                          </div>
                        )}

                        <div class="form_group">
                          <button
                            type="submit"
                            class="form_group__button form_group__button--icon_2_mod form_group__button--blue_mod"
                          >
                            <span class="form_group__button_img form_group__button_img--v2_mod form_group__button_img--size_mod">
                              <img
                                class="form_group__button_img_in"
                                src={LogoDownloadIcon}
                                alt="Google icon"
                              />
                            </span>
                            Add to your Calendar
                          </button>
                        </div>
                        <div class="form_group">
                          <button
                            type="submit"
                            class="form_group__button form_group__button--icon_2_mod form_group__button--bg_mod"
                          >
                            <span class="form_group__button_img form_group__button_img--v2_mod">
                              <img
                                class="form_group__button_img_in"
                                src="/static/media/google.ac687461.svg"
                                alt="Google icon"
                              />
                            </span>
                            Added to Google Calendar
                          </button>
                        </div>
                        <div className="form_group">
                          <div class="form_group__descr">
                            Need to make a change? <a href="#">Reschedule</a> or{' '}
                            <a href="">Cancel</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes } }) => ({
  user,
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setPro,
}

export default connect(mapStateToProps, willMapDispatchToProps)(BookedPage)
