import React from 'react'

import classNames from 'classnames'

import styles from './CallInfo.module.scss'
import { Logo } from 'components/ui/Logo'

export const Title = ({ children }) => <div className={styles.title}>{children}</div>
export const Content = ({ children }) => <div className={styles.content}>{children}</div>
export const Buttons = ({ children }) => <div className={styles.buttons}>{children}</div>

export function CallInfo({ children, logoHref, variant }) {
  return (
    <div
      className={classNames(styles.component, {
        [styles.variantSecondary]: variant === 'secondary',
      })}
    >
      <div className={styles.logo}>
        <Logo size="lg" variant="secondary" href={logoHref} />
      </div>
      {children}
    </div>
  )
}

CallInfo.Title = Title
CallInfo.Content = Content
CallInfo.Buttons = Buttons

export default CallInfo
