import { React, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import logo from 'i/logo_black.png'
import textLogo from 'i/talkpro-n.png'

import AuthHeaderPro from '../../components/Auth/AuthHeaderPro'
import LoginHeaderClient from '../../components/Auth/LoginHeaderClient'

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showLogin, setShowLogin] = useState(false)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <header className="header">
      <div className="header_in limit_mod padding_mod mobile_wrap_mod">
        <div className="header_logo_w">
          <img className="inner_img" src={logo} alt="" />
        </div>
        <div className="header_text">
          <img className="header_text_img" src={textLogo} alt="talkpro" />
        </div>

        {windowWidth < 768 && (
          <div
            className="header_hamburger_icon"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            &#9776;
          </div>
        )}

        {showMobileNav && (
          <div className="header_hamburger_wrap">
            <div className="header_hamburger_in">
              <a className="btn_v8 header_mod" href="http://help.talkpro.com/en">
                Help
              </a>
              <button
                className="btn_v8 header_mod"
                onClick={() => history.push('/pro/login', { login: true })}
              >
                Sign in
              </button>
              <button
                className="btn_v3 width_mod signup_mod"
                onClick={() => history.push('/pro/login', { login: false })}
              >
                Sign up free
              </button>
            </div>
          </div>
        )}

        {windowWidth >= 768 && (
          <div className="header_btn_w flex_mod">
            <a className="btn_v8 header_mod help_mod" href="http://help.talkpro.com/en">
              Help
            </a>
            <button
              className="btn_v8 header_mod"
              onClick={() => history.push('/pro/login', { login: true })}
            >
              Sign in
            </button>
            <button
              className="btn_v3 signup_mod"
              onClick={() => history.push('/pro/login', { login: false })}
            >
              Sign up free
            </button>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
