import React from 'react'

import Checkbox from '../Checkbox/Checkbox'
import { FormTimeSelect } from 'components/Form'

import styles from './AvailableDay.module.scss'
import FormInputWithLabel from 'components/Form/FormInputWithLabel/FormInputWithLabel'

const AvailableDay = ({
  name,
  checked,
  timeTo,
  timeFrom,
  dayNameFormProperty,
  handleChange,
}) => {
  return (
    <div className={styles.day}>
      <div className={styles.col}>
        <Checkbox
          id={name}
          title={name}
          name={`${dayNameFormProperty}.checked`}
          value={checked}
          onChange={handleChange}
          defaultChecked={checked}
        />
      </div>
      <div className={styles.col}>
        <div className={styles.fieldWrapper}>
          <div className={styles.field}>
            <FormInputWithLabel select label="From">
              <FormTimeSelect
                name={`${dayNameFormProperty}.start`}
                placeholder="From"
                defaultOptionValue="From"
                selectedValue={timeFrom}
                handleChange={handleChange}
              />
            </FormInputWithLabel>
          </div>
          <div className={styles.field}>
            <FormInputWithLabel select label="To">
              <FormTimeSelect
                name={`${dayNameFormProperty}.end`}
                placeholder="To"
                defaultOptionValue="To"
                selectedValue={timeTo}
                handleChange={handleChange}
              />
            </FormInputWithLabel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvailableDay
