import React, { useContext, useState, useRef } from 'react'
import { UiContext } from 'context/ui'
import { useClickOutside } from 'hooks'
import classNames from 'classnames'

import styles from './HelpPopup.module.scss'
import { ReactComponent as NoteIcon } from 'i/icons/note.svg'
import { ReactComponent as BackIcon } from 'i/icons/back.svg'
import { ReactComponent as SearchIcon } from 'i/icons/search_2.svg'
import { ReactComponent as InfoIcon } from 'i/icons/info.svg'
import Popup from '../Popup/Popup'
import { Button } from 'components/ui/Button'

const helpData = [
  {
    title: 'Contact billing Admin',
    content:
      '<p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p><p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p>',
  },
  {
    title: 'Must be a project owner',
    content:
      '<p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p><p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p>',
  },
  {
    title: 'Policy violations FAQ',
    content:
      '<p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p><p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p>',
  },
  {
    title: 'Account Migration FAQ',
    content:
      '<p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p><p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p>',
  },
  {
    title: 'How to deploy on your site',
    content:
      '<p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p><p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p>',
  },
  {
    title: 'Other Google Cloud Services and APIs',
    content:
      '<p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p><p>This article provides answers to frequently asked questions about why you need to migrate your Google Cloud Platform (GCP) billing account, how to complete the account migration process, and how to troubleshoot any problems that arise during migration.</p>',
  },
]

const HelpPopup = ({ isOpen }) => {
  const $container = useRef()
  const { setActivePopupId } = useContext(UiContext)
  const [activeIndex, setActiveIndex] = useState(null)

  const handleClose = () => {
    setActivePopupId(null)
    setActiveIndex(null)
  }

  useClickOutside($container, handleClose)

  const handleHelpArticle = (index) => {
    setActiveIndex(index)
  }

  const [value, setValue] = useState('')

  
  return (
    <Popup isOpen={isOpen} type="v4">
      <div
        className={classNames(styles.helpWrap, {
          [styles.helpWrapActive]: Number.isInteger(activeIndex),
        })}
      >
        {!Number.isInteger(activeIndex) && (
          <>
            <div className={styles.head}>
              <div className={styles.title}>Help for Pro’s</div>
              <div className={styles.description}>
                Commonly asked questions and answers
              </div>
            </div>
            <div className={styles.help}>
              <form className={styles.helpSearch}>
                <button type="submit" className={styles.helpSearchSubmit}>
                  <SearchIcon className={styles.helpSearchSubmitIcon} />
                </button>
                <input
                  type="text"
                  placeholder="Search Help"
                  className={styles.helpSearchInput}
                  name="search"
                  value={value} 
                  onChange={e => setValue(e.target.value)} 
                />
              </form>
              <div className={styles.helpList}>
                {helpData && helpData
                  .filter(item => {
                    if (!value) return true
                    if (item.title.toLowerCase().includes(value.toLowerCase()) || item.content.toLowerCase().includes(value.toLowerCase())) {
                      return true
                    }
                  }).map((item, index) => {
                    return(        
                      <div className={styles.helpItem} key={index}>
                        <button
                          type="button"
                          className={styles.helpBtn}
                          onClick={() => handleHelpArticle(index)}
                        >
                          <div className={styles.helpIcon}>
                            <NoteIcon className={styles.helpIconEl} />
                          </div>
                          <div className={styles.helpTitle}>{item.title}</div>
                        </button>
                      </div>
                    )
                  })
                }                

                {/*{helpData &&
                  helpData.map(({ title }, index) => {
                    return (
                      <div className={styles.helpItem} key={index}>
                        <button
                          type="button"
                          className={styles.helpBtn}
                          onClick={() => handleHelpArticle(index)}
                        >
                          <div className={styles.helpIcon}>
                            <NoteIcon className={styles.helpIconEl} />
                          </div>
                          <div className={styles.helpTitle}>{title}</div>
                        </button>
                      </div>
                    )
                  })} */}
                  
              </div>             
            </div>
          </>
        )}
        {Number.isInteger(activeIndex) && (
          <div className={styles.article}>
            <div className={styles.articleHead}>
              <div className={styles.articleBack} onClick={() => handleHelpArticle(null)}>
                <BackIcon className={styles.articleBackIcon} />
              </div>
            </div>
            <div className={styles.articleTitle}>{helpData[activeIndex].title}</div>
            <div
              className={styles.articleText}
              dangerouslySetInnerHTML={{ __html: helpData[activeIndex].content }}
            ></div>
            <Button variant="dark" size="base" fullWidth>
              Still have a question
            </Button>
          </div>
        )}
        <div className={styles.feedbackWrap}>
          <a href="#" className={styles.feedback}>
            <div className={styles.feedbackIcon}>
              <InfoIcon className={styles.feedbackIconEl} />
            </div>
            <div className={styles.feedbackText}>Send feedback</div>
          </a>
        </div>
      </div>
    </Popup>
  )
}

export default HelpPopup
