import React, { useContext } from 'react'
import { UiContext } from 'context/ui'
import { MEDIA_BREAKPOINTS } from 'utils/constants'

import { DashboardSchedule } from '../DashboardSchedule'
import { useWindowSize } from 'hooks'

import styles from './MeetingsAndCalls.module.scss'

const MeetingsAndCalls = () => {
  const windowSize = useWindowSize()
  const { dashboardTabIndex } = useContext(UiContext)
  return (
    <>
      <div className={styles.meetingsAndCalls}>
        {windowSize.width >= MEDIA_BREAKPOINTS.lg && <DashboardSchedule />}
        {windowSize.width < MEDIA_BREAKPOINTS.lg && dashboardTabIndex === 0 && (
          <div className={styles.meetingsAndCallsCol}>
            <DashboardSchedule />
          </div>
        )}
      </div>
    </>
  )
}

export default MeetingsAndCalls
