import { callApi, eventApi } from 'api'
import { useEffect, useState } from 'react'

import { PROMISE_STATES } from 'utils/constants'

export function useFetchProCalls(props) {
  const [loading, setLoading] = useState(PROMISE_STATES.idle)
  const [error, setError] = useState(null)
  const [previousCalls, setPreviousCalls] = useState(null)
  const [previousRecordedCalls, setPreviousRecordedCalls] = useState(null)

  const convertPreviousCalls = (proCalls, type = 'calls') => {
    const convertedData = []

    for (let index = 0; index < proCalls.length; index++) {
      const element = proCalls[index]

      if (type === 'records' && element.result?.records) {
        convertedData.push(...element.result?.records)
      } else {
        convertedData.push(...element.result)
      }
    }

    return convertedData
  }

  const sortPreviousCalls = (calls) => {
    const sortedCalls = [...calls]

    return sortedCalls.sort((a, b) => new Date(a.scheduled_at) - new Date(b.scheduled_at))
  }

  const filterPreviousCalls = (calls) => {
    return calls.filter((e) => e.finished_at)
  }

  const fetchData = async () => {
    try {
      setLoading(PROMISE_STATES.pending)

      const previousCallsPromises = [callApi.getCalls(), eventApi.getProEvents()]
      const previousRecordedCallsPromises = [
        callApi.getProPersonalCalls(),
        callApi.getProGroupCalls(),
      ]
      const previousCallsResult = await Promise.all(previousCallsPromises)
      const previousRecordedCallsResult = await Promise.all(previousRecordedCallsPromises)

      const updatedPreviousCalls = convertPreviousCalls(previousCallsResult)
      const updatedPreviousRecordedCalls = convertPreviousCalls(
        previousRecordedCallsResult,
        'records'
      )

      if (updatedPreviousCalls?.length) {
        const sortedPreviousCalls = sortPreviousCalls(updatedPreviousCalls)
        const filteredPreviousCalls = filterPreviousCalls(sortedPreviousCalls)

        props.setCalls(sortedPreviousCalls)
        setPreviousCalls(filteredPreviousCalls.reverse())
      }

      setPreviousRecordedCalls(updatedPreviousRecordedCalls)

      setLoading(PROMISE_STATES.fulfilled)
    } catch (unknownError) {
      setLoading(PROMISE_STATES.rejected)
      if (unknownError instanceof Error) {
        setError(unknownError)
      } else {
        setError(new Error(String(unknownError)))
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    loading,
    error,
    previousCalls,
    previousRecordedCalls,
  }
}

export default useFetchProCalls
