import React, { useContext, useState } from 'react'
import { UiContext } from 'context/ui'
import classNames from 'classnames'

import edit from 'i/icons/edit.svg'
import cancel from 'i/cancel.png'

import { POPUPS_ID } from 'utils/constants'

import styles from './DashboardMeeting.module.scss'

const DashboardMeeting = ({
  name,
  avatar,
  users,
  time,
  cost,
  descr,
  sessionType,
  callInfo,
  client,
}) => {
  const { setActivePopupId } = useContext(UiContext)
  const [showAllUsrs, setShowAllUsers] = useState(false)

  const handleShowAllUsers = () => {
    setShowAllUsers(true)
  }

  return (
    <div className={styles.dashboardMeeting}>
      <div className={styles.dashboardMeetingAvatar}>
        {avatar !== null && (
          <img src={avatar} alt={name} className={styles.dashboardMeetingAvatar_in} />
        )}
      </div>
      <div className={styles.dashboardMeetingInfo}>
        <div className={styles.dashboardMeetingCol}>
          <div className={styles.dashboardMeetingTime}>{time}</div>
          <div
            className={classNames(styles.dashboardMeetingMain, {
              [styles.dashboardMeetingMainRow]: descr,
            })}
          >
            {(descr || client) && (
              <div className={styles.dashboardMeetingUsersItem}>{name}</div>
            )}
            {users && !descr && (
              <div
                className={classNames(styles.dashboardMeetingUsers, {
                  [styles.dashboardMeetingUsersAll]: showAllUsrs,
                })}
              >
                {users.map((item) => {
                  return (
                    <>
                      <div className={styles.dashboardMeetingUsersItem}>{item}</div>
                    </>
                  )
                })}
                {users.length > 2 && !showAllUsrs && (
                  <button
                    className={styles.dashboardMeetingUsersTrigger}
                    type="button"
                    onClick={handleShowAllUsers}
                  >
                    {users.length - 2} More
                  </button>
                )}
              </div>
            )}
            {!descr && !client && (
              <div className={styles.dashboardMeetingCost}>{name}</div>
            )}
            {cost && client && (
              <div className={styles.dashboardMeetingCost}>
                {cost} {sessionType}
              </div>
            )}
            <div className={styles.dashboardMeetingDescr}>{descr}</div>
          </div>
        </div>
        <div className={styles.dashboardMeetingCol}></div>
      </div>
      <div class="dashboardMeetingAction">
        <div
          class="actionEdit actionWrap"
          onClick={(e) => {
            e.preventDefault()
            setActivePopupId(POPUPS_ID.meetingInfo)
            window.localStorage.setItem('callInfo', JSON.stringify(callInfo))
          }}
        >
          <img src={edit} class="btn btn_edit" />
        </div>
        <div class="actionDelete actionWrap">
          <img src={cancel} class="btn btn_cancel" />
        </div>
      </div>
    </div>
  )
}

export default DashboardMeeting
