import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { add, isBefore, isAfter, isWithinInterval, sub } from 'date-fns'
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

import { callApi } from 'api'

import { setEventSettings } from 'store/actions'

import { ReactComponent as LeftArrowIcon } from 'i/icons/left_arrow.svg'
import { ReactComponent as RightArrowIcon } from 'i/icons/right_arrow.svg'

const TimePicker = ({ newDate, pro, user, disabledDates, availableDates, timezone }) => {
  const history = useHistory()
  const [timeSelect, setTimeSelect] = useState('')
  const [clicked, setClicked] = useState(true)
  const [clickedButton, setClickedButton] = useState(null)
  const [message, setMessage] = useState('')
  // const [newDate, setNewDate] = useState(date)

  // These funcitons are used to format the time string into an object
  // that can be used by the date-fns library

  // I know, I know, this is a super hacky way to do this,
  // but if you feel that strongly about it,
  // feel free to refactor every time selector component in the app

  const formatTimeToString = (time) => {
    let hours = time.getHours()
    let minutes = time.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  const formatStringtoTime = (time) => {
    const timeParts = time.split(' ')
    let hours = parseInt(timeParts[0].split(':')[0])
    let minutes = parseInt(timeParts[0].split(':')[1])
    let isPM = timeParts[1] === 'PM'

    // If the time is AM and the hour is 12, set it to 0
    if (!isPM && hours === 12) {
      hours = 0
    }

    // If the time is PM and the hour is not 12, add 12 to the hours
    if (isPM && hours !== 12) {
      hours += 12
    }

    return {
      hours,
      minutes,
    }
  }

  let weekDay = newDate.toLocaleDateString('en-us', { weekday: 'long' }).toLowerCase()
  let localizedDate = newDate.setHours(0, 0, 0, 0)
  let proOffset = getTimezoneOffset(pro.timezone) / 60 / 60 / 1000
  let userOffset = getTimezoneOffset(timezone.name) / 60 / 60 / 1000
  let offset = -(proOffset - userOffset)

  let availableEarliestTime = availableDates[weekDay]?.start
  let availableLatestTime = availableDates[weekDay]?.end

  let earliestTime = formatTimeToString(
    add(add(localizedDate, formatStringtoTime(availableEarliestTime)), { hours: offset })
  )
  let latestTime = formatTimeToString(
    add(add(localizedDate, formatStringtoTime(availableLatestTime)), { hours: offset })
  )

  let trueEarliestTime = isAfter(
    add(localizedDate, formatStringtoTime(earliestTime)),
    add(localizedDate, formatStringtoTime(latestTime))
  )
    ? latestTime
    : earliestTime
  let trueLatestTime = isAfter(
    add(localizedDate, formatStringtoTime(earliestTime)),
    add(localizedDate, formatStringtoTime(latestTime))
  )
    ? earliestTime
    : latestTime

  const callRequest = {
    pro_id: pro?.id,
    scheduled_at:
      timeSelect !== ''
        ? formatInTimeZone(
            sub(new Date(timeSelect), { hours: offset }),
            pro.timezone,
            'MM/dd/yyyy HH:mm XXXX'
          )
        : '',
    category_name: pro?.category,
  }

  const filterAvailableTimes = (times) =>
    times.filter((e) => {
      const selectedTime = formatStringtoTime(e)
      const selectedDateTime = add(localizedDate, selectedTime)

      // Check if the selected time is after the current time
      if (isBefore(selectedDateTime, new Date())) {
        return false
      }

      // Check if the selected time falls within the available time range
      const startTimeDateTime = add(localizedDate, formatStringtoTime(trueEarliestTime))
      const endTimeDateTime = add(localizedDate, formatStringtoTime(trueLatestTime))

      if (
        isBefore(selectedDateTime, startTimeDateTime) ||
        isAfter(selectedDateTime, endTimeDateTime)
      ) {
        return false
      }

      // Check if the selected time is disabled
      const isDisabled = disabledDates?.some((a) =>
        isWithinInterval(selectedDateTime, {
          start: add(
            localizedDate,
            formatStringtoTime(formatInTimeZone(a.start, timezone.name, 'HH:mm XXXX'))
          ),
          end: add(
            localizedDate,
            formatStringtoTime(formatInTimeZone(a.end, timezone.name, 'HH:mm XXXX'))
          ),
        })
      )
      return !isDisabled
    })

  console.log(callRequest.scheduled_at)

  const availableTimes = [
    '12:00 AM',
    '12:15 AM',
    '12:30 AM',
    '12:45 AM',
    '1:00 AM',
    '1:15 AM',
    '1:30 AM',
    '1:45 AM',
    '2:00 AM',
    '2:15 AM',
    '2:30 AM',
    '2:45 AM',
    '3:00 AM',
    '3:15 AM',
    '3:30 AM',
    '4:00 AM',
    '4:15 AM',
    '4:30 AM',
    '4:45 AM',
    '5:00 AM',
    '5:15 AM',
    '5:30 AM',
    '5:45 AM',
    '6:00 AM',
    '6:15 AM',
    '6:30 AM',
    '6:45 AM',
    '7:00 AM',
    '7:15 AM',
    '7:30 AM',
    '7:45 AM',
    '8:00 AM',
    '8:15 AM',
    '8:30 AM',
    '8:45 AM',
    '9:00 AM',
    '9:15 AM',
    '9:30 AM',
    '9:45 AM',
    '10:00 AM',
    '10:15 AM',
    '10:30 AM',
    '10:45 AM',
    '11:00 AM',
    '11:15 AM',
    '11:30 AM',
    '11:45 AM',
    '12:00 PM',
    '12:15 PM',
    '12:30 PM',
    '12:45 PM',
    '1:00 PM',
    '1:15 PM',
    '1:30 PM',
    '1:45 PM',
    '2:00 PM',
    '2:15 PM',
    '2:30 PM',
    '2:45 PM',
    '3:00 PM',
    '3:15 PM',
    '3:30 PM',
    '3:45 PM',
    '4:00 PM',
    '4:15 PM',
    '4:30 PM',
    '4:45 PM',
    '5:00 PM',
    '5:15 PM',
    '5:30 PM',
    '5:45 PM',
    '6:00 PM',
    '6:15 PM',
    '6:30 PM',
    '6:45 PM',
    '7:00 PM',
    '7:15 PM',
    '7:30 PM',
    '7:45 PM',
    '8:00 PM',
    '8:15 PM',
    '8:30 PM',
    '8:45 PM',
    '9:00 PM',
    '9:15 PM',
    '9:30 PM',
    '9:45 PM',
    '10:00 PM',
    '10:15 PM',
    '10:30 PM',
    '10:45 PM',
    '11:00 PM',
    '11:15 PM',
    '11:30 PM',
    '11:45 PM',
  ]

  if (
    timeSelect !== '' &&
    timeSelect?.toDateString() !== newDate.toDateString() &&
    clicked
  ) {
    setTimeSelect('')
    setClicked(false)
  }

  return (
    <>
      {filterAvailableTimes(availableTimes)?.length > 0 && (
        <form className="time_select_form">
          <div className="time_select_form__head">
            <div className="date_select">
              <button
                className="date_select__arrow"
                type="button"
                aria-label=""
                // onClick={(e) => {
                //   setNewDate(subDays(newDate, 1))
                //   date = subDays(newDate, 1)
                // }}
              >
                <LeftArrowIcon className="icon size_mod" />
              </button>
              <div className="date_select__selected">
                {formatInTimeZone(localizedDate, timezone.name, 'cccc, LLLL do')}
              </div>
              <button
                className="date_select__arrow"
                type="button"
                aria-label=""
                // onClick={(e) => {
                //   setNewDate(addDays(newDate, 1))
                //   date = addDays(newDate, 1)
                // }}
              >
                <RightArrowIcon className="icon size_mod" />
              </button>
            </div>
          </div>

          <div className="time_select__wrap">
            <ul className="time_select time_select_form__select">
              {filterAvailableTimes(availableTimes)?.map((val) => {
                const id = `radio_${availableTimes.indexOf(val)}`

                return (
                  <li className="time_select__item" key={id}>
                    <input
                      className="time_select__item_input"
                      id={id}
                      type="radio"
                      onClick={() => {
                        setClicked(true)
                        setClickedButton(id)
                        setTimeSelect(add(localizedDate, formatStringtoTime(val)))
                      }}
                      name="time"
                    />
                    <label className="time_select__item_label" htmlFor={id}>
                      {val}
                    </label>
                    {timeSelect && (
                      <button
                        className="time_select__submit"
                        type="submit"
                        hidden={id === clickedButton ? false : true}
                        onClick={(e) => {
                          e.preventDefault()
                          user
                            ? callApi.sendCallRequest(callRequest).then((res) => {
                                window.localStorage.setItem(
                                  'info',
                                  JSON.stringify({
                                    callRequest,
                                    pro,
                                    timeSelect,
                                    call_id: res?.result?.call_info,
                                  })
                                )
                                res?.result?.redirect
                                  ? history.push('/user/callback', res?.result?.call_info)
                                  : history.push('/user/payments', {
                                      callRequest,
                                      pro,
                                      timeSelect,
                                      call_id: res?.result?.call_info,
                                    })
                              })
                            : history.push('/user/confirm', {
                                callRequest,
                                pro,
                                timeSelect,
                              })
                        }}
                      >
                        Confirm
                      </button>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
          {message && <span className="form_error">{message}</span>}
        </form>
      )}
    </>
  )
}

const mapStateToProps = ({ auth: { user, eventSettings, isPro } }) => ({
  user,
  eventSettings,
  isPro,
})

const willMapDispatchToProps = {
  setEventSettings,
}

export default connect(mapStateToProps, willMapDispatchToProps)(TimePicker)
