import React from 'react'

import { ReactComponent as ArrowBackIcon } from 'i/icons/arrow_back.svg'

const ArrowBack = () => {
  return (
    <a className="arrow_back" href="#" aria-label="">
      <ArrowBackIcon className="icon size_mod" />
    </a>
  )
}

export default ArrowBack
