import { createEvent, getProEvents, editEvent, cancelEvent } from './Pro'
import { getUserEvents, attendEvent, eventList, proEventList } from './User'

export const eventApi = {
  createEvent,
  editEvent,
  cancelEvent,
  eventList,
  proEventList,
  getProEvents,
  getUserEvents,
  attendEvent,
}
