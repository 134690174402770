import React, { useState, useRef } from 'react';

import closeIcon from 'i/close.svg'
import acc1 from 'i/acc-1.png';
import openAI from 'i/icons/open-ai.svg';
import lock from 'i/icons/lock_white.svg';
import playIcon from 'i/icons/play-icon.svg';

import styles from './DashboardAccordion.module.scss'


const DashboardAccordion = () => {
  const [openAccordion, setOpenAccordion] = useState(0);
  const [playingAccordion, setPlayingAccordion] = useState(null);
  const videoRefs = useRef({});

  const handleAccordionClick = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
    setPlayingAccordion(null);
  };

  const handlePlayClick = (index) => {
    setPlayingAccordion(index);
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
    }
  };

  const handleCloseClick = (index) => {
    setPlayingAccordion(null);
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
      videoRefs.current[index].currentTime = 0;
    }
  };


  const accordionItems = [
    {
      title: 'How to use Talkpro for meetings',
      content: (
        <div>       
          <h1 className={styles['content-heading']}>How to use Talkpro for meetings</h1>
          <div className={styles['content-subheading']}>Be on time, always check your connection</div>
          <div className={styles['content-bottom']}>
            <div className={styles['list-item']}>
              <div className={styles.item}>
                <div className={styles['item-icon']}><img src={openAI} className={styles['image']} /></div>
                <div className={styles['item-text']}>Meeting notes by Open AI</div>
              </div>
              <div className={styles.item}>
                <div className={styles['item-icon']}><img src={lock} className={styles['image']} /></div>
                <div className={styles['item-text']}>End-to-end encrypted meetings</div>
              </div>         
            </div>
          </div>
        </div>
      ),
      videoSrc: 'https://www.w3schools.com/html/mov_bbb.mp4',
      bgImage: acc1,
      videoType: 'mp4',
    },
    {
      title: 'How to use my meeting room',
      content: (
        <div>       
          <h1 className={styles['content-heading']}>How to use Talkpro for meetings</h1>
          <div className={styles['content-subheading']}>Be on time, always check your connection</div>
          <div className={styles['content-bottom']}>
            <div className={styles['list-item']}>
              <div className={styles.item}>
                <div className={styles['item-icon']}><img src={openAI} className={styles['image']} /></div>
                <div className={styles['item-text']}>Meeting notes by Open AI</div>
              </div>
              <div className={styles.item}>
                <div className={styles['item-icon']}><img src={lock} className={styles['image']} /></div>
                <div className={styles['item-text']}>End-to-end encrypted meetings</div>
              </div>         
            </div>
          </div>
        </div>
      ),
      videoSrc: 'https://www.w3schools.com/html/mov_bbb.mp4',
      bgImage: acc1,
      videoType: 'mp4',
    },
    {
      title: 'How to share by personal booking page',
      content: (
        <div>       
          <h1 className={styles['content-heading']}>How to use Talkpro for meetings</h1>
          <div className={styles['content-subheading']}>Be on time, always check your connection</div>
          <div className={styles['content-bottom']}>
            <div className={styles['list-item']}>
              <div className={styles.item}>
                <div className={styles['item-icon']}><img src={openAI} className={styles['image']} /></div>
                <div className={styles['item-text']}>Meeting notes by Open AI</div>
              </div>
              <div className={styles.item}>
                <div className={styles['item-icon']}><img src={lock} className={styles['image']} /></div>
                <div className={styles['item-text']}>End-to-end encrypted meetings</div>
              </div>         
            </div>
          </div>
        </div>
      ),
      videoSrc: 'https://www.w3schools.com/html/mov_bbb.mp4',
      bgImage: acc1,
      videoType: 'mp4',
    },
  ];

  return (
    <div className={styles.accordion}>
      {accordionItems.map((item, index) => (
        <div
          className={`${styles['accordion-item']} ${openAccordion === index ? styles.open : ''}`}
          key={index}
        >
          <div
            className={styles['accordion-header']}
            onClick={() => handleAccordionClick(index)}
          >
            <div className={styles['header-icon']}><img src={playIcon} className={styles.icon} /></div>
            <div className={styles['header-title']}>{item.title}</div>
          </div>
          <div className={styles['accordion-content']}>
            {playingAccordion === index ? (
              <div className={styles['video-container']}>
                <video
                  className={styles['video-background']}
                  ref={(el) => (videoRefs.current[index] = el)}
                  controls
                >
                  <source src={item.videoSrc} type={`video/${item.videoType}`} />
                </video>
                <div
                  className={styles['close-button']}
                  onClick={() => handleCloseClick(index)}
                >
                  <img src={closeIcon} className={styles.icon} />
                </div>
              </div>
            ) : (
              <div
                className={styles['content-with-background']}
              >
                <div 
                  className={styles['background-image']} 
                  style={{ backgroundImage: `url(${item.bgImage})` }}
                >
                  <div className={styles.content}>
                    {item.content}

                    <div
                      className={styles['btn-play']}
                      onClick={() => handlePlayClick(index)}
                    >
                      Enter
                    </div>
                  </div>                  
                  <div
                    className={`${styles['close-button']} ${styles['accordion-close']}`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <img src={closeIcon} className={styles.icon} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardAccordion;
