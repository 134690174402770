import React, { useContext, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'

import { UiContext } from 'context/ui'
import { useClickOutside } from 'hooks'

import Popup from '../Popup/Popup'
import { FormCustomSelect, FormInputWithLabel } from 'components/Form'
import { MANAGE_SUBSCRIPTION_OPTIONS, PROMISE_STATES } from 'utils/constants'
import { paymentApi } from 'api'
import { AlertMessage } from 'components/AlertMessage'

const formSchema = Yup.object().shape({
  message: Yup.string().required('Message is required'),
})

const ManageSubscriptionPopup = ({ isOpen, user, title, description, ...props }) => {
  const $container = useRef()
  const [unsubscribingReason, setUnsubscribingReason] = useState(
    MANAGE_SUBSCRIPTION_OPTIONS[0].value
  )
  const [status, setStatus] = useState(PROMISE_STATES.idle)
  const [errorMessage, setErrorMessage] = useState(null)
  const { setActivePopupId } = useContext(UiContext)

  const handleClose = () => {
    setActivePopupId(null)
  }

  useClickOutside($container, handleClose)

  return (
    <Popup isOpen={isOpen} title={title} description={description} {...props}>
      <Formik
        initialValues={{}}
        validationSchema={formSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setStatus(PROMISE_STATES.pending)

          try {
            const data = {
              cancel_reason: `Reason: ${unsubscribingReason}. Message: ${values.message}`,
            }

            const result = await paymentApi.cancelSubscription(data)

            if (result.result?.error) {
              throw new Error(result.result?.error)
            } else {
              setStatus(PROMISE_STATES.fulfilled)
              handleClose()
            }
          } catch (error) {
            setErrorMessage(error.message)
            setStatus(PROMISE_STATES.rejected)
          }
        }}
      >
        {({ errors, touched, submitForm, isSubmitting, handleChange, values }) => (
          <>
            <div className="signup__tabs_content signup__tabs_content--border_mod">
              <div className="signup__tabs_content_item signup__tabs_content_item--active">
                <div className="signup__form">
                  <Form className="signup__form_in">
                    <div className="form_group profile_mod">
                      <FormCustomSelect
                        v4
                        placeholder="Why would you like to cancel"
                        options={MANAGE_SUBSCRIPTION_OPTIONS}
                        onChange={(e) => {
                          setUnsubscribingReason(e.value)
                        }}
                      />
                    </div>
                    <div className="form_group">
                      <FormInputWithLabel textarea label="Message" colorV2>
                        <Field
                          className="form_group__field"
                          name="message"
                          as="textarea"
                          disabled={status === PROMISE_STATES.pending}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormInputWithLabel>
                      {errors && errors['message'] ? (
                        <AlertMessage message={errors['message']} />
                      ) : null}
                    </div>
                    <div className="form_group">
                      <button
                        type="submit"
                        disabled={status === PROMISE_STATES.pending}
                        onClick={(e) => {
                          e.preventDefault(e)
                          submitForm()
                        }}
                        className="form_group__button style_v2_mod"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="form_group">
                      {errorMessage ? <AlertMessage message={errorMessage} /> : null}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Popup>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(ManageSubscriptionPopup)
