import { React, useEffect } from 'react'

import { Image } from 'components/ui/Image'
import { BaseNavigation } from 'components/ui/BaseNavgiation'
import { SERVICE_NAVIGATION } from 'shared/constants'
import { AuthHeader } from 'components/ui/AuthHeader'
import { AuthItem } from 'components/ui/AuthItem'
import { AuthContent } from 'components/ui/AuthContent'
import { AuthRow } from 'components/ui/AuthRow'
import { AuthCol } from 'components/ui/AuthCol'
import ProLogin from 'components/Auth/ProLogin'
import { Button } from 'components/ui/Button'

import SignImg from 'i/signup_img.jpg'
import { WhyNeedAccount } from 'components/WhyNeedAccount'

const RoleSelect = (props) => {
  const login = true

  useEffect(() => {
    document.title = 'TalkPro | Login'
  }, [])

  return (
    <AuthRow>
      <AuthCol type="gradient" />
      <AuthCol>
        <AuthHeader title="Login as Pro" offsetMod />
        <AuthItem variant="divider">
          <AuthContent>
            You don’t need to remember an email and password, <br /> plus we’ll send you a
            reminders before your talk
          </AuthContent>
        </AuthItem>
        <AuthItem variant="divider">
          <Button variant="secondary" iconName="google" fullWidth>
            {login && <ProLogin />}
            Continue with Google
          </Button>
        </AuthItem>
        <AuthItem variant="divider">
          <WhyNeedAccount />
        </AuthItem>
      </AuthCol>
    </AuthRow>
  )
}

export default RoleSelect
