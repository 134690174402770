import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isFuture, addMinutes } from 'date-fns'

import PopupShare from 'components/Popups/PopupShare'

import { ReactComponent as Calendar2Icon } from 'i/icons/calendar_2.svg'
import { ReactComponent as Chat2Icon } from 'i/icons/chat-2.svg'
import { ReactComponent as Calendar3Icon } from 'i/icons/calendar_3.svg'
import { ReactComponent as CheckInIcon } from 'i/icons/check_in.svg'
import { ReactComponent as EmailIcon } from 'i/icons/email.svg'

const BottomNav = (props) => {
  const [showPopup, setShowPopup] = useState(false)

  const navList = props.isPro
    ? [
        {
          mod: 'active_mod',
          Img: Calendar2Icon,
          href: '/dashboard/pro',
        },
        {
          mod: 'selected_mod',
          Img: Chat2Icon,
          href: `/talk/${
            props.calls?.filter(
              (e) => !isFuture((addMinutes(new Date(e.scheduled_at), 15), new Date()))
            ).length > 0
              ? props.calls?.filter(
                  (e) => !isFuture(addMinutes(new Date(e.scheduled_at), 15), new Date())
                )[
                  props.calls?.filter(
                    (e) => !isFuture(addMinutes(new Date(e.scheduled_at), 15), new Date())
                  ).length - 1
                ]?.call_request
              : null
          }`,
          disabled:
            props.calls?.filter((e) =>
              isFuture((addMinutes(new Date(e.scheduled_at), 15), new Date()))
            ).length > 0,
          state:
            props.calls?.filter(
              (e) => !isFuture((addMinutes(new Date(e.scheduled_at), 15), new Date()))
            ).length > 0
              ? props.calls?.filter(
                  (e) => !isFuture(addMinutes(new Date(e.scheduled_at), 15), new Date())
                )[
                  props.calls?.filter(
                    (e) => !isFuture(addMinutes(new Date(e.scheduled_at), 15), new Date())
                  ).length - 1
                ]
              : null,
        },
        {
          Img: EmailIcon,
          href: '/pro/share',
        },
      ]
    : [
        {
          mod: 'active_mod',
          Img: Calendar2Icon,
          href: '/dashboard/user',
        },
        {
          mod: 'selected_mod',
          Img: Chat2Icon,
          href: `/talk/${props.calls?.length > 0 ? props.calls[0]?.id : null}`,
          disabled:
            !props.calls?.filter((e) => isFuture(new Date(e.scheduled_at))).length > 0,
          state:
            props.calls?.filter((e) => isFuture(new Date(e.scheduled_at))).length > 0
              ? props.calls[0]
              : null,
        },
        {
          Img: Calendar3Icon,
          href: '/popup/schedule',
        },
        {
          Img: CheckInIcon,
          href: '/popup/reschedule',
        },
      ]

  return (
    <div className="bottom_nav">
      <div className={showPopup ? 'popup_container show' : 'popup_container'}>
        <PopupShare showPopup={showPopup} onClose={() => setShowPopup(false)} />
      </div>
      <div className="bottom_nav_list">
        {navList &&
          navList.map((item) => {
            return (
              <>
                {!item.disabled && (
                  <div className="bottom_nav_item">
                    <Link
                      className={`bottom_nav_link ${item.mod || ''}`}
                      to={{ pathname: item.href, state: item.state }}
                      onClick={() => item.href === '#' && setShowPopup(true)}
                    >
                      <item.Img className="icon nav_mod" />
                    </Link>
                  </div>
                )}
              </>
            )
          })}
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth: { isPro, calls } }) => ({
  isPro,
  calls,
})

export default connect(mapStateToProps)(BottomNav)
