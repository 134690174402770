import { apiRequest } from 'api/apiRequest'

export const createEvent = (data) => apiRequest('/pro/event/create/').post(data)

export const editEvent = (data, id) =>
  apiRequest('/pro/event/edit/').post({ ...data, id })

export const cancelEvent = (id) => apiRequest(`/event/${id}/cancel`).post()

export const getProEvents = () => apiRequest('/pro/events/').get()
