import { apiRequest } from '../apiRequest'

const getChats = () => apiRequest(`/chats/`).get()

const startChat = (data) => apiRequest(`/chats/`).post(data)

const getChat = ({ id }) => apiRequest(`/chats/${id}/`).get()

const sendChat = ({ id }) => apiRequest(`/chats/${id}/`).put()

const getMessages = ({ id, target }) =>
  apiRequest(`/chats/messages/?chat_id=${id}&target=${target}`).get()

const sendMessage = (data) => apiRequest(`/chats/messages/`).post(data)

export const chatApi = {
  getChats,
  getChat,
  getMessages,
  sendChat,
  sendMessage,
  startChat,
}
