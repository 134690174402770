import { apiRequest } from '../../apiRequest'

export const getUserInfo = () => apiRequest('/user/settings/profile/').get()

export const setUserInfo = (data) => apiRequest('/user/settings/profile/').put(data)

export const uploadUserAvi = (data) =>
  apiRequest('/user/settings/upload-photo/').post(data)

export const getProList = () => apiRequest('/user/pro-list/').get()

export const getProInfo = (data) =>
  apiRequest('/user/pro-info/', { error: { message: 'ERROR' } }).post(data)
