import React, { useEffect, useState } from 'react'
import { Switch, Route, useLocation, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { BottomNav } from 'components/BottomNav'
import {
  Header,
  HeaderHelp,
  HeaderBook,
  HeaderLogin,
  HeaderProfile,
} from 'components/Header'
import { Footer } from 'components/Footer'
import { SchedulePopup, ReschedulePopup } from 'components/Popups'
import PrivateRoute from 'components/PrivateRoute'

import * as PAGES from './pages'

import {
  authFail,
  initializeHistory,
  setIsOnlineState,
  logIn,
  setIsPro,
  showSidebar,
} from 'store/actions'

import { authApi, profileApi } from 'api'

const App = (props) => {
  const location = useLocation()
  const [logged, setLogged] = useState(true)
  const [isBookPage, setIsBookPage] = useState(false)
  const [isConfirmPage, setIsConfirmPage] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    const loggedIn = window.localStorage.getItem('token')

    if (loggedIn) {
      document.body.classList.add('logged-in');
    }else{
      document.body.classList.remove('logged-in');
    }

    // FIXME: uncomment
    if (loggedIn) {
      authApi
        .validate(loggedIn)
        .then(async (res) =>
          (await res.error)
            ? (window.localStorage.clear(),
              window.location.replace('https://join.talkpro.com'))
            : null
        )
    }
    if (loggedIn && logged) {
      JSON.parse(window.localStorage.getItem('isPro'))
        ? // authApi.validate(window.localStorage.getItem("token")).then((res)=>  res.result ? window.localStorage.clear(): null)
          profileApi
            .getProfileInfo()
            .then((res) => (props.logIn(res?.result?.profile), props.setIsPro(true)))
        : profileApi
            .getUserInfo()
            .then((res) => (props.logIn(res?.result?.profile), props.setIsPro(false)))
    }
    setLogged(false)
  }, [logged, props])

  return (
    <div className="wrapper">
      {logged && location.pathname === '/' ? (
        <HeaderLogin />
      ) : (isBookPage && !isLoading) ||
        isConfirmPage ||
        location.pathname === '/book/time-picker' ? (
        <HeaderProfile isBookPage={isBookPage} isConfirmPage={isConfirmPage} />
      ) : location.pathname === '/user/confirmed' ? (
        <HeaderHelp />
      ) : location.pathname === '/pro/join' ||
        location.pathname === '/pro/rate' ||
        location.pathname === '/pro/rate-b' ||
        location.pathname === '/pro/stripe' ||
        location.pathname === '/user/login' ||
        location.pathname === '/pro/login' ||
        location.pathname === '/user/join' ||
        location.pathname === '/user/reset-password' ||
        location.pathname === '/pro/SignUp1' ||
        location.pathname.includes('/summary/') ||
        location.pathname.includes('/talk/') ||
        location.pathname.includes('/room') ||
        location.pathname.includes('/event/') ||
        location.pathname === '/pro/SignUp2' ||
        location.pathname === '/pro/SignUp3' ||
        location.pathname === '/user/confirm' ||
        isBookPage ||
        location.pathname === '/dashboard/pro' ||
        location.pathname === '/' ? null : location.pathname.split('/').length === 2 ? (
        <HeaderBook />
      ) : (
        <Header />
      )}
      <div
        className={`base ${
          location.pathname === '/pro/join' ||
          location.pathname === '/pro/rate' ||
          location.pathname === '/pro/rate-b' ||
          location.pathname === '/pro/stripe' ||
          location.pathname === '/user/login' ||
          location.pathname === '/pro/login' ||
          location.pathname === '/user/join' ||
          location.pathname === '/dashboard/user' ||
          location.pathname === '/user/reset-password' ||
          location.pathname.includes('/summary/') ||
          location.pathname.includes('/talk/') ||
          location.pathname.includes('/room') ||
          location.pathname.includes('/event/') ||
          location.pathname === '/pro/SignUp1' ||
          location.pathname === '/pro/SignUp2' ||
          location.pathname === '/pro/SignUp3' ||
          location.pathname === '/' ||
          location.pathname === '/dashboard/pro' ||
          ((isBookPage || isConfirmPage) && windowWidth < 1024)
            ? 'no_header_mod'
            : ''
        }`}
      >
        <div
          className={`${
            isBookPage || isConfirmPage ? 'section_wrap profile_mod' : 'section_wrap'
          }`}
        >
          <Switch location={location}>
            <Route
              exact
              path="/"
              component={() => {
                // FIXME: uncomment
                window.location.replace('https://join.talkpro.com')
                return null
              }}
            />
            <PrivateRoute component={PAGES.SummaryPage} path="/summary/group/:id" exact />
            <PrivateRoute
              component={PAGES.SummaryPage}
              path="/summary/private/:id"
              exact
            />
            <PrivateRoute component={PAGES.During} path="/talk/:id" exact />
            <Route component={PAGES.Huddle} path="/:slug/room/" exact />
            <PrivateRoute component={PAGES.Event} path="/event/:id" exact />
            <PrivateRoute
              component={PAGES.AccountSettings}
              path="/account/settings"
              exact
            />
            <PrivateRoute
              component={PAGES.AccountProfile}
              path="/account/profile"
              exact
            />
            <PrivateRoute component={PAGES.ResultPage} path="/result" exact />
            <PrivateRoute
              component={PAGES.SharePage}
              path="/pro/share/:merchant?"
              exact
            />
            {/* User paths */}
            <Route
              path="/:proId"
              exact
              render={(props) => (
                <PAGES.BookPage
                  {...props}
                  setIsLoading={setIsLoading}
                  setIsBookPage={setIsBookPage}
                />
              )}
            />
            <Route component={PAGES.JoinPage} path="/user/join:code?" exact />
            <Route component={PAGES.LoginPage} path="/user/login" exact />
            <Route component={PAGES.TimePickerPage} path="/book/time-picker" exact />
            <PrivateRoute component={PAGES.TuberPage} path="/dashboard/user" exact />
            <PrivateRoute component={PAGES.RequestSent} path="/request/confirmed" exact />
            <Route
              path="/user/confirm/:data?"
              exact
              render={(props) => (
                <PAGES.JoinConfirmPage {...props} setIsConfirmPage={setIsConfirmPage} />
              )}
            />
            <Route
              path="/user/payments"
              exact
              render={(props) => (
                <PAGES.PaymentsPage {...props} setIsConfirmPage={setIsConfirmPage} />
              )}
            />
            <Route
              path="/user/callback/:data?"
              exact
              render={(props) => (
                <PAGES.BookedPage {...props} setIsConfirmPage={setIsConfirmPage} />
              )}
            />
            {/* Pro paths */}
            <Route component={PAGES.SignupPage} path="/pro/join" exact />
            <Route component={PAGES.RoleSelect} path="/pro/login" exact />
            <Route component={PAGES.ProfilePage} path="/pro/profile" exact />
            <PrivateRoute component={PAGES.TalkerPage} path="/dashboard/pro" exact />
            <PrivateRoute component={PAGES.StripePage} path="/pro/stripe" exact />
            <PrivateRoute component={PAGES.RateBpage} path="/pro/rate" exact />
            <PrivateRoute component={PAGES.Callback} path="/pro/callback" exact />
            {logged ? (
              <Route component={PAGES.WelcomePage} path="/" exact />
            ) : (
              <Route
                exact
                path="/"
                component={() => {
                  // FIXME: uncomment
                  window.location.replace('https://join.talkpro.com')
                  return null
                }}
              />
            )}
            <PrivateRoute component={SchedulePopup} path="/popup/schedule" exact />
            <PrivateRoute component={ReschedulePopup} path="/popup/reschedule" exact />
          </Switch>
          {location.pathname === '/popup/schedule' ||
          location.pathname === '/user/join' ||
          location.pathname === '/pro/login' ||
          location.pathname === '/user/login' ||
          location.pathname === '/popup/reschedule' ||
          location.pathname === '/book/time-picker' ||
          location.pathname === '/dashboard/user' ||
          location.pathname === '/pro/join' ||
          location.pathname === '/pro/rate' ||
          location.pathname === '/pro/rate-b' ||
          location.pathname === '/pro/stripe' ||
          isBookPage ||
          isConfirmPage ||
          location.pathname.split('/').includes('summary') ||
          location.pathname.split('/').includes('talk') ||
          location.pathname.split('/').includes('room') ||
          location.pathname.split('/').includes('event') ||
          location.pathname === '/' ||
          (location.pathname === '/dashboard/user' && windowWidth < 1024) ||
          location.pathname === '/dashboard/pro' ? null : (
            <Footer />
          )}
        </div>
      </div>

      {location.pathname === '/' ? null : location.pathname === '/pro/join' ||
        location.pathname === '/pro/rate' ||
        location.pathname === '/pro/rate-b' ||
        location.pathname === '/dashboard/pro' ||
        location.pathname === '/user/login' ||
        location.pathname === '/pro/login' ||
        location.pathname.includes('/summary/') ||
        isBookPage ||
        isConfirmPage ||
        location.pathname === '/pro/stripe' ? null : windowWidth < 1024 ? (
        <BottomNav />
      ) : null}
    </div>
  )
}

const mapStateToProps = ({ auth: { user, attributes, isPro, isOnline, pro } }) => ({
  user,
  attributes,
  isPro,
  isOnline,
  pro,
})

const willMapDispatchToProps = {
  authFail,
  initializeHistory,
  setIsOnlineState,
  logIn,
  setIsPro,
  showSidebar,
}

export default withRouter(connect(mapStateToProps, willMapDispatchToProps)(App))
