import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { useHistory } from 'react-router-dom'
import { format, subDays, isBefore, isFuture } from 'date-fns'

import { calendarApi } from 'api'

const ProfileCalendar = (props) => {
  const calendarInfo = [
    'Get billed $6 per minute',
    'Talk and text with Tom for up to 1 hour',
    'First 15 minute free on your first call',
  ]
  const history = useHistory()
  const [calendarValue, setCalendarValue] = useState(
    new Date(format(new Date(), 'MMMM dd yyyy'))
  )
  const [disabledDates, setDisabledDates] = useState([])
  const [pro, setPro] = useState()

  const getBusy = () =>
    calendarApi.getBusyDays(pro?.id).then((res) => {
      const dates = res?.result?.pro?.calendars?.primary?.busy?.map(
        (e) => new Date(e.start)
      )
      setDisabledDates(dates)
    })
  let [called, setCalled] = useState(3)

  if (called > 0 && pro !== undefined) {
    getBusy()
    setCalled(0)
  }

  return (
    <div className="profile_calendar">
      <div className="profile_calendar__block">
        <div className="profile_calendar__title">Select a day for a 1 on 1 talk</div>
        <Calendar
          onChange={(e) =>
            history.push('/book/time-picker', {
              calendarValue: e,
              pro,
              user: props.user,
              disabledDates: disabledDates?.filter(
                (e) => e.getDate() === calendarValue.getDate()
              ),
            })
          }
          value={calendarValue}
          calendarType="US"
          showNeighboringMonth={false}
          tileDisabled={({ date, view }) =>
            (view === 'month' &&
              disabledDates?.some(
                (current) =>
                  date.getFullYear() === current?.getFullYear() &&
                  date.getMonth() === current?.getMonth() &&
                  date.getDate() === current?.getDate() &&
                  date.getHours() === current.getHours()
              )) ||
            date.getDay() === 0 ||
            date.getDay() === 6 ||
            isBefore(date, subDays(new Date(), 1))
          }
          tileClassName={({ date, view }) =>
            view === 'month' && date.getDay() > 0 && date.getDay() < 6 ? 'border_mod' : ''
          }
        />
      </div>
      <div className="profile_calendar__info">
        {calendarInfo.map((item) => {
          return <div className="profile_calendar__info_item">{item}</div>
        })}
      </div>
    </div>
  )
}

export default ProfileCalendar
