import { apiRequest } from '../../apiRequest'

export const setProRate = (data) => apiRequest('/pro/settings/profile/').put(data)

export const connectStripe = () => apiRequest('/payment/onboard-seller/').post('')

export const onboardSeller = (data) =>
  apiRequest('/payment/onboard-seller/callback').post(data)

export const createSubscription = (data) =>
  apiRequest('/payment/subscription/').post(data)

export const cancelSubscription = (data) =>
  apiRequest('/payment/subscription/cancel').post(data)

export const registerSubscription = (data) =>
  apiRequest('/payment/subscription/callback').post(data)
