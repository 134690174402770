import React, { useContext, useState } from 'react'
import classNames from 'classnames'

import { CallVideoPopup } from 'components/Popups'
import { UiContext } from 'context/ui'
import { POPUPS_ID } from 'utils/constants'
import { DashboardChat } from 'components/Dashboard'
import { DashboardSearch } from 'components/Dashboard'

import TalksMessage from './TalksMessage'

const getTransctriptions = (calls) => {
  let transcriptions = []
  calls.forEach((chat) => {
    if (chat.transcription) {
      let evalTranscription = JSON.stringify(eval(`(${chat.transcription})`))
      let parsedTranscription = JSON.parse(evalTranscription)
      transcriptions.push({ transcription: parsedTranscription, id: chat.id })
    }
  })
  return transcriptions
}

const TalksList = (props) => {
  console.log('===')
  console.log(props)
  const listClasses = classNames('talks_list', {
    limit_mod: props.limitMod,
  })

  const [chat, setChat] = useState(null)
  const [roomID, setRoomID] = useState(null)
  const [isSearchActive, setIsSearchActive] = useState(false)

  const transcriptions = getTransctriptions(props.calls)

  const { activePopupId, setActivePopupId, activeChatVideo, setActiveChatVideo } =
    useContext(UiContext)

  const openChatPopup = () => {
    setActivePopupId(POPUPS_ID.chat)
  }

  return (
    <>
      {activePopupId !== POPUPS_ID.chat && !isSearchActive && (
        <ul className={listClasses}>
          {props.calls.length === 0 && (
            <li className="talks_no_previous">
              No previous {props.type === 'events' ? 'events' : 'calls'}
            </li>
          )}
          {props.calls.map((chat, index) => {
            const recordedCall = props.recordedCalls?.find(
              (call) => call.room_name === chat.room_name
            )

            return (
              <li className="talks_list_item" key={chat.id}>
                <TalksMessage
                  chat={chat}
                  schedule={props.schedule}
                  reschedule={props.reschedule}
                  recordedCall={recordedCall}
                  onRecordedCallClick={(callVideoLink) => {
                    setRoomID(props.calls[index].id)

                    setChat(chat)
                    openChatPopup()
                    if (callVideoLink) {
                      setActiveChatVideo(callVideoLink)
                    }
                  }}
                />
              </li>
            )
          })}
        </ul>
      )}
      <CallVideoPopup
        isOpen={activePopupId === POPUPS_ID.callVideo}
        videoLink={activeChatVideo}
      />
      <DashboardChat
        isOpen={activePopupId === POPUPS_ID.chat}
        chat={chat}
        roomID={roomID}
        toggleSidebar={props.toggleSidebar}
        transcription={chat?.transcription}
      />
      <DashboardSearch
        transcriptions={transcriptions}
        // isOpen={activePopupId === POPUPS_ID.search}
        setIsSearchActive={setIsSearchActive}
        isOpen={true}
        chats={props.calls}
        setChat={setChat}
      />
    </>
  )
}

export default TalksList
