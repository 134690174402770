import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { format, isThisWeek, startOfWeek } from 'date-fns'
import { Code } from 'react-content-loader'

import { DashboardIndicator } from '../DashboardIndicator'

import styles from './DashboardStatistics.module.scss'

import { callApi } from 'api'

const DashboardStatistics = (props) => {
  const [analytics, setAnalytics] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const startOfWeekDate = format(startOfWeek(new Date()), 'MM/dd/yy')

  useEffect(() => {
    callApi.getCalls().then((res) => {
      setAnalytics(res?.result.filter((e) => e?.finished_at))
      setIsLoading(false)
    })
  }, [])

  const totalCost = analytics?.reduce((acc, val) => acc + Number(val?.total_cost), 0)
  const totalMinutes = analytics?.reduce((acc, val) => acc + Number(val?.total_time), 0)
  const revenueThisWeek = analytics
    ?.filter((e) => isThisWeek(new Date(e.finished_at)))
    ?.reduce((acc, val) => acc + Number(val?.total_cost), 0)
  const minutesThisWeek = analytics
    ?.filter((e) => isThisWeek(new Date(e.finished_at)))
    ?.reduce((acc, val) => acc + Number(val?.total_time), 0)

  return (
    <>
      {isLoading && (
        <div className={styles.root}>
          <Code className={styles.item} style={{ minWidth: '20em' }} />
        </div>
      )}
      {totalMinutes > 0 && (
        <div className={styles.root}>
          <div className={styles.item}>
            <DashboardIndicator
              title="Talk time"
              info={`Total since ${startOfWeekDate}`}
              value={isLoading ? '' : `${totalMinutes} mins`}
              ratioColor="pink"
              ratioValue={`${
                100 - (minutesThisWeek && minutesThisWeek / totalMinutes) * 100
              }%`}
            />
          </div>
          {(parseInt(props.user?.rate) && parseInt(props.user?.rate)) > 0 ? (
            <div className={styles.item}>
              <DashboardIndicator
                title="Revenue"
                info={`Paid out $${revenueThisWeek || 0} on ${startOfWeekDate}`}
                value={isLoading ? '' : `$${totalCost}`}
                ratioColor="orange"
                ratioValue={`${
                  100 - (revenueThisWeek && revenueThisWeek / totalCost) * 100
                }%`}
              />
            </div>
          ) : (
            <div className={styles.item}>
              <DashboardIndicator
                title="Talks"
                info={`Total since ${startOfWeekDate}`}
                value={
                  isLoading
                    ? ''
                    : `${props.calls.filter((e) => e.finished_at).length || 0}`
                }
                ratioColor="orange"
                ratioValue={`${
                  100 - (revenueThisWeek && revenueThisWeek / totalCost) * 100
                }%`}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user, calls } }) => ({
  authError: error,
  calls,
  user,
  isPro,
})

export default connect(mapStateToProps)(DashboardStatistics)
