import { profileApi } from 'api'
import { useEffect, useState } from 'react'
import { PROMISE_STATES } from 'utils/constants'
import { generateUniqueId } from 'utils/generate-unique-id'

export function useUserVideos() {
  const [videos, setVideos] = useState([])
  const [status, setStatus] = useState(PROMISE_STATES.idle)
  const [error, setError] = useState(null)

  const createNewVideo = () => {
    return {
      title: '',
      videoId: generateUniqueId(),
    }
  }

  const updateVideoTitle = (id, title) => {
    const clonedVideos = [...videos]

    clonedVideos.forEach(({ videoId }, index) => {
      if (videoId === id) {
        clonedVideos[index].title = title
      }
    })

    setVideos(clonedVideos)
  }

  const addVideo = () => {
    const newVideo = createNewVideo()
    const updatedVideos = [...videos, newVideo]

    setVideos(updatedVideos)
  }

  const removeVideo = (id) => {
    const updatedVideos = videos.filter(({ videoId }) => videoId !== id)

    setVideos(updatedVideos)
  }

  const updateAllVideos = (videos) => {
    setVideos([...videos])
  }

  const fetchVideos = async () => {
    setStatus(PROMISE_STATES.pending)

    const res = await profileApi.getProfileInfo()

    if (res?.error) {
      setError(res.error?.message)
      setStatus(PROMISE_STATES.rejected)
    } else {
      if (res?.result?.profile?.video_urls) {
        const videos = JSON.parse(res.result.profile.video_urls)

        setVideos(videos)
      }

      setStatus(PROMISE_STATES.fulfilled)
    }
  }

  useEffect(() => {
    fetchVideos()
  }, [])

  return {
    userVideos: videos,
    updateAllUserVideos: updateAllVideos,
    fetchError: error,
    fetchStatus: status,
    addVideo,
    removeVideo,
    updateVideoTitle,
  }
}

export default useUserVideos
