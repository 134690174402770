import React, { useContext } from 'react'
import { UiContext } from 'context/ui'
import classNames from 'classnames'

import styles from './DashboardMobileMenu.module.scss'

import { POPUPS_ID } from 'utils/constants'

import { ReactComponent as AddIcon } from 'i/icons/add_white.svg'

const menuList = [{ title: 'Calendar', counter: 1 }, { title: 'Talks' }]

const DashboardMobileMenu = ({ activeIndex, mobileTabsHandler }) => {
  const { setActivePopupId } = useContext(UiContext)
  return (
    <div className={styles.DashboardMobileMenu}>
      <div
        className={styles.DashboardMobileMenuAdd}
        onClick={(e) => {
          e.preventDefault()
          setActivePopupId(POPUPS_ID.sendInvite)
        }}
      >
        <AddIcon className={styles.DashboardMobileMenuAddIcon} />
      </div>

      <div className={styles.DashboardMobileMenuList}>
        {menuList &&
          menuList.map(({ title, counter }, index) => {
            return (
              <div
                onClick={() => mobileTabsHandler(index)}
                className={classNames(styles.DashboardMobileMenuItem, {
                  [styles.DashboardMobileMenuItemActive]: index === activeIndex,
                })}
                key={index}
              >
                <div className={styles.DashboardMobileMenuItemTitle}>{title}</div>
                {counter && (
                  <div className={styles.DashboardMobileMenuItemCounter}>{counter}</div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default DashboardMobileMenu
