import React, { useContext, useState } from 'react'
import { useUserVideos } from 'hooks'
import { UiContext } from 'context/ui'

import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'

import { VideoList } from './ui/VideoList'

import Popup from '../Popup/Popup'

import { profileApi } from 'api'

const AddVideoPopup = ({ isOpen, title, description }) => {
  const [inputError] = useState(null)
  const { setActivePopupId } = useContext(UiContext)
  const { userVideos, updateAllUserVideos } = useUserVideos()

  const handleClose = () => {
    if (!inputError) {
      setActivePopupId(null)
    }
  }

  const addSelectedYoutubeVideos = (selectedVideos) => {
    updateAllUserVideos(selectedVideos)
    profileApi.setProInfo({ video_urls: JSON.stringify(selectedVideos) })
    handleClose()
  }

  return (
    <Popup
      isOpen={isOpen}
      type="border"
      title={title}
      description={description}
      icon={<YoutubeIcon />}
      centerContent
    >
      <VideoList onSave={addSelectedYoutubeVideos} currentVideos={userVideos} />
    </Popup>
  )
}

export default AddVideoPopup
