import React, { useState } from 'react'
import classNames from 'classnames'

import styles from './FormFile.module.scss'
import { ReactComponent as UploadIcon } from 'i/icons/upload.svg'
import { ReactComponent as UserIcon } from 'i/icons/user.svg'

const FormFile = ({ text, onChange, v2, userIcon }) => {
  const [fileName, setFileName] = useState(null)

  const handleChange = (e) => {
    if (v2) {
      setFileName(e.target.value.split(/[\\/]/g).pop())
    }

    onChange()
  }

  return (
    <div className={styles.root}>
      <label
        tabIndex="0"
        className={classNames(styles.label, {
          [styles.labelV2]: v2,
        })}
      >
        <input className={styles.input} type="file" onChange={handleChange} />
        <div className={styles.icon}>
          {userIcon && <UserIcon className="icon size_mod" />}
          {!userIcon && <UploadIcon className="icon size_mod" />}
        </div>
        {fileName || text}
      </label>
    </div>
  )
}

export default FormFile
