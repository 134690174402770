import { useEffect, useState } from 'react'
import { profileApi } from 'api'
import { PROMISE_STATES } from 'utils/constants'

export function useYoutubeVideos() {
  const [videos, setVideos] = useState([])
  const [status, setStatus] = useState(PROMISE_STATES.idle)
  const [error, setError] = useState(null)

  const fetchVideos = async () => {
    setStatus(PROMISE_STATES.pending)

    const res = await profileApi.getYoutubeVideos()

    if (res?.error) {
      setError(res.error?.message)
      setStatus(PROMISE_STATES.rejected)
    } else {
      setVideos(res?.result?.videos)
      setStatus(PROMISE_STATES.fulfilled)
    }
  }

  useEffect(() => {
    fetchVideos()
  }, [])

  return { videos, fetchError: error, fetchStatus: status }
}

export default useYoutubeVideos
