export const generateUniqueId = (length = 20) => {
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const getRandomChar = (string) => {
    return string[randomInt(0, string.length - 1)]
  }

  const generateId = () => {
    const chars = 'abcdefghyjklmnopqrstuvwxyz0123456789'
    let res = ''

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      res +=
        randomInt(1, 2) === 1 ? getRandomChar(chars).toUpperCase() : getRandomChar(chars)
    }

    return res
  }

  const uniqueId = generateId()

  return uniqueId
}
