import React from 'react'

import { AccountSidebar } from 'components/AccountSidebar'
import { CallHistory } from 'components/CallHistory'

const AccountSettings = () => {
  return (
    <section className="section wrap_mod">
      <div className="section_col sidebar_mod left_bar_mod">
        <AccountSidebar />
      </div>
      <div className="section_col content_2_mod col_mod">
        <CallHistory />
      </div>
    </section>
  )
}

export default AccountSettings
