import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as CalendarIcon } from 'i/icons/calendar_4_white.svg'
import { ReactComponent as AvailableIcon } from 'i/icons/available_white.svg'
import { ReactComponent as ClassesIcon } from 'i/icons/classes_white.svg'
import { ReactComponent as VideoIcon } from 'i/icons/play-media_white.svg'
import { ReactComponent as ProfileIcon } from 'i/icons/profile_white.svg'
import { ReactComponent as WalletIcon } from 'i/icons/wallet_white.svg'
import { ReactComponent as HelpIcon } from 'i/icons/help_white.svg'

import { MENU_DATA, PAGE_NAMES } from '../../data'

import styles from './Menu.module.scss'
import { UiContext } from 'context/ui'
import { POPUPS_ID } from 'utils/constants'

const Menu = ({ clickToggle, setDashboardContent, dashboardContentCurrentState }) => {
  const { setActivePopupId } = useContext(UiContext)

  const renderIcon = (title) => {
    if (!title) return null

    const menuLinkIcon = styles.menuLinkIcon

    switch (title) {
      case PAGE_NAMES.schedule:
        return <CalendarIcon className={menuLinkIcon} />

      case PAGE_NAMES.availability:
        return <AvailableIcon className={menuLinkIcon} />

      case PAGE_NAMES.classes:
        return <ClassesIcon className={menuLinkIcon} />

      case PAGE_NAMES.videoes:
        return <VideoIcon className={menuLinkIcon} />

      case PAGE_NAMES.profile:
        return <ProfileIcon className={menuLinkIcon} />

      case PAGE_NAMES.help:
        return <HelpIcon className={menuLinkIcon} />

      default:
        return null
    }
  }

  const renderContent = (title) => {
    return (
      <>
        {renderIcon(title)}
        {title}
      </>
    )
  }

  const showPopup = (popupId) => {
    clickToggle()
    setActivePopupId(popupId)
  }

  return (
    <nav className={styles.menu}>
      <div className={styles.menuButtonWrap}>
        <button
          type="button"
          className={styles.menuButton}
          onClick={() => showPopup(POPUPS_ID.sendInvite)}
        >
          <span>+</span> Invite
        </button>
      </div>

      <ul className={styles.menuList}>
        {MENU_DATA &&
          MENU_DATA.map(({ title, url, target, popupId, dashboardContent }) => {
            const linkActiveState =
              dashboardContent && dashboardContentCurrentState
                ? dashboardContent === dashboardContentCurrentState
                  ? styles.menuLinkActive
                  : false
                : styles.menuLinkActive

            return (
              <li className={styles.menuItem} key={title}>
                {popupId ? (
                  <button
                    type="button"
                    className={styles.menuLink}
                    onClick={() => showPopup(popupId)}
                  >
                    {renderContent(title)}
                  </button>
                ) : target ? (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.menuLink}
                  >
                    {renderContent(title)}
                  </a>
                ) : (
                  <NavLink
                    className={styles.menuLink}
                    activeClassName={linkActiveState}
                    to={url}
                    onClick={
                      dashboardContent && setDashboardContent
                        ? () => setDashboardContent(dashboardContent)
                        : null
                    }
                  >
                    {renderContent(title)}
                  </NavLink>
                )}
              </li>
            )
          })}
      </ul>
    </nav>
  )
}

export default Menu
