import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { UiContext } from 'context/ui'
import { format, differenceInMinutes } from 'date-fns'

import { ReactComponent as BackIcon } from 'i/icons/back.svg'
import { ReactComponent as SearchIcon } from 'i/icons/search_white.svg'
import { ReactComponent as HamburgerIcon } from 'i/icons/hamburger_3_white.svg'

import { POPUPS_ID } from 'utils/constants'

import styles from './DashboardChat.module.scss'
import TranscriptionChat from 'components/TranscriptionChat/TranscriptionChat'
import { SanitaizedText } from 'components/SanitaizedText'
import { formatParticipants, getParticipants } from 'domain/talks'

const TABS = {
  notes: 'notes',
  transcription: 'transcription',
}

const DashboardChat = ({ isOpen, chat, user, toggleSidebar, transcription, roomID }) => {
  const [currentTab, setCurrentTab] = useState(TABS.notes)

  const { setActivePopupId } = useContext(UiContext)

  const info =
    chat &&
    `${format(new Date(chat?.scheduled_at), 'M/d/yy h:mm a')} ${
      chat?.entry_fee === '0.00' || chat?.total_cost === '0.00'
        ? ''
        : !chat?.entry_fee
        ? '$' + chat?.total_cost
        : '$' +
          chat?.entry_fee *
            chat?.attendes?.split('')?.filter((e) => e !== ' ' && e !== '')?.length
    }`

  const jsonString = JSON.stringify(eval(`(${transcription})`))

  const participants = getParticipants(
    chat && chat?.transcription,
    user?.user?.user?.name
  )

  const duration = chat
    ? differenceInMinutes(new Date(chat?.finished_at), new Date(chat?.started_at))
    : ''

  const openVideoPopup = () => {
    setActivePopupId(POPUPS_ID.callVideo)
  }

  const togglePopup = () => {
    if (currentTab === TABS.notes) {
      setCurrentTab(TABS.transcription)
    } else {
      setCurrentTab(TABS.notes)
    }
  }

  const toggleSearch = () => {
    setActivePopupId(POPUPS_ID.search)
  }

  const closePopup = () => {
    if (currentTab === TABS.transcription) {
      setCurrentTab(TABS.notes)
    } else {
      setActivePopupId(null)
    }
  }

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 500,
        exit: 350,
      }}
      onExit={() => {
        setCurrentTab(TABS.notes)
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.dashboardChat}>
        <div className={styles.dashboardChatHead}>
          <div className={styles.dashboardChatBack}>
            <BackIcon className={styles.dashboardChatBackIcon} onClick={closePopup} />
          </div>
          <div className={styles.dashboardChatAvatar}>
            <img
              src={
                chat?.title
                  ? chat?.pro?.photo
                  : user?.user?.user?.name === chat?.pro?.user?.name
                  ? chat?.client?.photo
                  : chat?.pro?.photo
              }
              alt={
                chat?.title
                  ? chat?.pro?.user?.name
                  : chat?.pro?.name === user?.user?.user?.name
                  ? chat?.pro?.user?.name
                  : chat?.client?.user?.name
              }
              className={styles.dashboardChatAvatarImg}
            />
          </div>
          <div className={styles.dashboardChatHeadInfo}>
            {participants ? (
              <div className={styles.dashboardChatName}>
                {formatParticipants(participants)}
              </div>
            ) : null}
            <div className={styles.dashboardChatHeadData}>{info}</div>
          </div>
          <div className={styles.dashboardChatHeaIcons}>
            <button
              type="button"
              className={styles.dashboardChatHeadSearch}
              onClick={toggleSearch}
            >
              <SearchIcon className={styles.dashboardChatHeadIcon} />
            </button>
            <button
              type="button"
              className={styles.dashboardChatHeadHamburger}
              onClick={toggleSidebar}
            >
              <HamburgerIcon className={styles.dashboardChatHeadIcon} />
            </button>
          </div>
        </div>
        <div className={styles.dashboardChatMain}>
          <div className={styles.dashboardChatMainIn}>
            {/* {jsonString &&
              JSON.parse(jsonString)?.map((item) => {
                return (
                  <div
                    className={classNames(styles.dashboardChatMessage, {
                      [styles.dashboardChatMessageV2Mod]:
                        item?.name === user?.user?.user?.name,
                    })}
                  >
                    <div className={styles.dashboardChatMessageTime}>
                      {item.name} {item.date && format(new Date(item?.date), 'h:mm a')}
                    </div>
                    <div className={styles.dashboardChatMessageText}>{item?.text}</div>
                  </div>
                )
              })} */}
            {currentTab === TABS.notes && (
              <>
                <div className={styles.dashboardChatMainSubtitle}>Meeting notes</div>
                {chat && <SanitaizedText>{chat?.meeting_notes}</SanitaizedText>}
              </>
            )}
            {currentTab === TABS.transcription && (
              <div className={styles.dashboardChatTranscription}>
                {chat && (
                  <TranscriptionChat
                    transcription={chat.transcription}
                    roomID={chat.id}
                    variant="secondary"
                  />
                )}
              </div>
            )}
          </div>
          {/* <div className={styles.dashboardChatMainBtnWrap}>
            {currentTab === TABS.notes ? (
              <div className={styles.dashboardChatMainBtnItem}>
                <DashboardChatBtn handleClick={togglePopup}>
                  {currentTab === TABS.notes && 'Read transcript'}
                </DashboardChatBtn>
              </div>
            ) : null}
            <div className={styles.dashboardChatMainBtnItem}>
              <DashboardChatBtn title="" isPlayVideo handleClick={openVideoPopup}>
                <Play2Icon />
                Play recoding
              </DashboardChatBtn>
            </div>
          </div> */}
        </div>
      </div>
    </CSSTransition>
  )
}

const mapStateToProps = ({ auth: user }) => ({
  user,
})

export default connect(mapStateToProps)(DashboardChat)
