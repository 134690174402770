import React, { useEffect, useRef } from 'react'
import QRCodeStyling from 'qr-code-styling'
import { connect } from 'react-redux'

import TalkProLogoGreen from 'i/talkpro_logo_green.svg'

const QrMaker = (props) => {
  const ref = useRef(null)

  useEffect(() => {
    qrCode.append(ref.current)
  }, [])

  const qrCode = new QRCodeStyling({
    width: 110,
    height: 110,
    type: 'svg',
    data: `https://www.talkpro.com/${props?.user?.channel_name}`,
    image: TalkProLogoGreen,
    dotsOptions: {
      color: '#000000',
      type: 'classy-rounded',
    },
    backgroundOptions: {
      color: '#ffffff',
      type: 'classy-rounded',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
    },
    cornerSquareOptions: {
      type: 'extra-rounded',
    },
    cornersDotOptions: {
      type: 'square',
    },
  })

  return <div className="bookSideBar-photo" ref={ref}></div>
}
const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(QrMaker)
