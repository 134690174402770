export const INITIALIZE_HISTORY = 'INITIALIZE_HISTORY'
export const UPDATE_HISTORY = 'UPDATE_HISTORY'

// Alarm action
export const ADD_ALARM = 'ADD_ALARM'
export const SET_ALARMS = 'SET_ALARMS'
export const REMOVE_ALARM = 'REMOVE_ALARM'
export const END_ALARM_LAUNCH = 'END_ALARM_LAUNCH'
export const START_ALARM_LAUNCH = 'START_ALARM_LAUNCH'
export const UPDATE_ALARM = 'UPDATE_ALARM'
export const SET_IS_PRO = 'SET_IS_PRO'
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES'

// Auth actions
export const AUTH_RESET_PASS_SUCCESS = 'AUTH_RESET_PASS_SUCCESS'
export const AUTH_START = 'AUTH_START'
export const AUTH_ERROR_RESET = 'AUTH_ERROR_RESET'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SOCIAL_MSG = 'AUTH_SOCIAL_MSG'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN'
export const SET_ONLINE_STATE = 'SET_ONLINE_STATE'
export const SET_REQUESTS = 'SET_REQUESTS'
export const SET_CALLS = 'SET_CALLS'
export const SET_PRO = 'SET_PRO'
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR'
export const SET_EVENT_SETTINGS = 'SET_EVENT_SETTINGS'
export const SET_EVENTS = 'SET_EVENTS'
