export const getParticipants = (transcription, organizerName) => {
  if (transcription === null || organizerName === null) return null

  const parsedTranscription = JSON.parse(transcription)

  const filteredParticipants = parsedTranscription.filter(
    (item) => item.name.toLowerCase() !== organizerName.toLowerCase()
  )

  const uniqueParticipants = [...new Set(filteredParticipants.map((item) => item.name))]

  return uniqueParticipants
}

export const formatParticipants = (participants) => {
  const participantsString = participants.join(', ')

  return participantsString
}
