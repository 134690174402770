import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Spinner } from 'components/Spinner'

import { paymentApi } from 'api'

const CheckoutForm = ({ pro_id, event_id }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [clientSecret, setClientSecret] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (event_id) {
      window.localStorage.setItem('isEvent', 'true')
      paymentApi
        .createCheckout({ pro_id: pro_id, event_id: event_id })
        .then((res) => setClientSecret(res?.result?.token))
    } else {
      window.localStorage.setItem('isEvent', 'false')
      paymentApi
        .createCheckout({ pro_id: pro_id, event_id: 0 })
        .then((res) => setClientSecret(res?.result?.token))
    }
  }, [])

  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault()

    if (elements == null) {
      return
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://www.talkpro.com/user/callback/',
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {isLoading ? (
        <Spinner className="form_group__button spinner_mod" button={true} />
      ) : (
        <button
          className="btn_v2 width_mod font_mod"
          style={{ marginTop: '1em' }}
          type="submit"
          disabled={!stripe || !elements}
        >
          Pay
        </button>
      )}
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}

export default CheckoutForm
