const SERVICE_NAVIGATION = [
  {
    title: 'Help',
    href: '#',
  },
  {
    title: 'Terms',
    href: '#',
  },
  {
    title: 'Privacy',
    href: '#',
  },
  {
    title: 'Cookies',
    href: '#',
  },
  {
    title: 'TalkPro.com © 2022',
    href: '#',
  },
]

export default SERVICE_NAVIGATION
