import React from 'react'

import styles from './DashboardTimeLeft.module.scss'

const DashboardTimeLeft = ({ title, width }) => {
  return (
    <div className={styles.block}>
      <div className={styles.title}>{title}</div>
      <div className={styles.bar}>
        <div className={styles.progress} style={{ width: `${width}%` }}></div>
      </div>
    </div>
  )
}

export default DashboardTimeLeft
