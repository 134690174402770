import React from 'react'

import { ReactComponent as BackArrowIcon } from 'i/icons/triangle_right.svg'
import { ReactComponent as LeftArrowIcon } from 'i/icons/left_arrow.svg'
import { ReactComponent as RightArrowIcon } from 'i/icons/right_arrow.svg'

const SelectTimePopup = () => {
  const times = [
    {
      id: 0,
      time: '9:00 AM',
    },
    {
      id: 1,
      time: '10:30 AM',
    },
    {
      id: 2,
      time: '11:30 AM',
    },
    {
      id: 3,
      time: '12:00 PM',
    },
    {
      id: 4,
      time: '1:00 PM',
    },
    {
      id: 5,
      time: '5:00 PM',
    },
  ]

  return (
    <div className="select_time_popup">
      <button className="select_time_popup_back">
        <span className="select_time_popup_back__icon">
          <BackArrowIcon className="icon size_mod" />
        </span>
        <span className="select_time_popup_back__text">Back</span>
      </button>

      <div className="select_time_popup_scroll_area">
        <form className="select_time_popup_content">
          <div className="select_time_popup_title">Select a time</div>

          <div className="select_time_popup_date_select">
            <button
              className="select_time_popup_date_select__button"
              type="button"
              aria-label=""
            >
              <LeftArrowIcon className="icon size_mod" />
            </button>
            <div className="select_time_popup_date_select__selected">
              Tues, April 20 2022
            </div>
            <button
              className="select_time_popup_date_select__button"
              type="button"
              aria-label=""
            >
              <RightArrowIcon className="icon size_mod" />
            </button>
          </div>

          <ul className="select_time_popup_times">
            {times.map((item) => {
              const inputId = `${item.id}_${Date.now()}`

              return (
                <li className="select_time_popup_times__item" key={item.id}>
                  <input
                    className="select_time_popup_times__item_input"
                    id={inputId}
                    type="radio"
                    name="time"
                  />
                  <label
                    className="select_time_popup_times__item_label"
                    htmlFor={inputId}
                  >
                    {item.time}
                  </label>
                </li>
              )
            })}
          </ul>
        </form>
      </div>
    </div>
  )
}

export default SelectTimePopup
