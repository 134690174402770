import React, { useContext, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { UiContext } from 'context/ui'
import { useClickOutside } from 'hooks'
import { format } from 'date-fns'

import Popup from '../Popup/Popup'
import styles from './MeetingInfoPopup.module.scss'

import { callApi, eventApi } from 'api'

const MeetingInfoPopup = ({ isOpen, user }) => {
  const $container = useRef()
  const { setActivePopupId } = useContext(UiContext)
  const [callInfo, setCallInfo] = useState({})

  useEffect(() => {
    if (window.localStorage.getItem('callInfo')) {
      setCallInfo(JSON.parse(window.localStorage.getItem('callInfo')))
    }
  }, [isOpen])

  const handleClose = () => {
    window.localStorage.removeItem('callInfo')
    setActivePopupId(null)
  }

  const date =
    isOpen &&
    callInfo?.scheduled_at &&
    format(new Date(callInfo?.scheduled_at), 'EEEE, MMMM d yyyy')
  const time =
    isOpen && callInfo?.scheduled_at && format(new Date(callInfo?.scheduled_at), 'h:mm a')
  const cost = isOpen && !callInfo?.entry_fee ? callInfo?.total_cost : callInfo?.entry_fee
  const title = isOpen && callInfo?.title ? callInfo?.title : 'Call Info'

  useClickOutside($container, handleClose)

  return (
    <Popup isOpen={isOpen} type="custom-offsets" title={title}>
      {callInfo && isOpen && (
        <div className={styles.meetingInfo}>
          <div class={styles.meetingInfoBlock}>
            <div className={styles.meetingInfoRow}>
              <div className={styles.meetingInfoCol}>
                <div className={styles.meetingInfoName}>{date}</div>
              </div>
            </div>
            <div className={styles.meetingInfoCol}>
              <div className={styles.meetingInfoName}>{time}</div>
            </div>
            <div className={styles.meetingInfoCol}>
              <div className={styles.meetingInfoName}>
                {cost > 0 ? `$${cost}` : 'Free'}
              </div>
              {/* <div className={styles.meetingInfoName}>18 Seats left</div> */}
            </div>
          </div>
          {callInfo?.title && (
            <div className={styles.meetingInfoBlock}>
              <div class={styles.meetingInfoColTitle}>Notes</div>
              <div className={styles.meetingInfoName}>{callInfo?.description}</div>
            </div>
          )}
          <div class={styles.meetingInfoBlock}>
            <div class={styles.meetingInfoColTitle}>Attending</div>
            {callInfo?.title ? (
              callInfo?.attendes
                .split(',')
                .filter((e) => e !== ' ' && e !== '' && e !== callInfo?.pro?.user?.email)
                .map((user) => (
                  <div className={styles.meetingInfoUser}>
                    <div className={styles.userAvatar}>{user.split('')[0]}</div>
                    <div className={styles.meetingInfoName}>{user}</div>
                  </div>
                ))
            ) : (
              <div className={styles.meetingInfoUser}>
                <img
                  src={callInfo?.client?.photo}
                  alt={callInfo?.client?.user?.name}
                  className={styles.userAvatar}
                />
                <div className={styles.meetingInfoName}>
                  {callInfo?.client?.user?.name}
                </div>
              </div>
            )}
          </div>
          <div className={styles.buttons}>
            {callInfo?.title && (
              <div class="form_group form_group--offset_mod">
                <button
                  type="submit"
                  className="form_group__button cancel_mod"
                  onClick={(e) => {
                    e.preventDefault(e)
                    window.localStorage.setItem(
                      'callInfo',
                      JSON.stringify({
                        scheduled_at: callInfo.scheduled_at,
                        entry_fee: parseInt(callInfo.entry_fee),
                        max_participants: callInfo.max_participants,
                        category_name: callInfo.category_name,
                        title: callInfo.title,
                        pro_id: callInfo.pro_id,
                        id: callInfo.id,
                        description: callInfo.description,
                        invited_users: callInfo?.attendes
                          ?.split(',')
                          ?.filter(
                            (client) =>
                              client !== ' ' &&
                              client !== '' &&
                              client !== callInfo?.pro?.user?.email
                          ),
                      })
                    )
                    setActivePopupId('create-class')
                  }}
                >
                  Edit
                </button>
              </div>
            )}
            <div class="form_group form_group--offset_mod">
              <button
                type="submit"
                className="form_group__button"
                onClick={(e) => {
                  e.preventDefault(e)
                  window.localStorage.removeItem('callInfo')
                  if (callInfo?.title) {
                    eventApi.cancelEvent(callInfo?.id)
                    window.location.reload()
                  } else {
                    callApi.cancelCall({ id: callInfo?.id })
                    window.location.reload()
                  }
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(MeetingInfoPopup)
