import { React, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isFuture } from 'date-fns'

import { Logo } from 'components/Header'
import { Spinner } from 'components/Spinner'

import { ReactComponent as DefaultAvatar } from 'i/icons/default-avatar.svg'
import { ReactComponent as SearchIcon } from 'i/icons/search.svg'

import { showSidebar } from 'store/actions'

const Header = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <header className="header">
      <div className="header_in">
        <div className="header_col">
          <div className="header_logo_w border_mod">
            <Logo />
          </div>
          <div className="header_col_in_w">
            <div className="header_col_in">
              <div className="header_info_text">
                {props.calls?.filter((e) =>
                  props.isPro
                    ? isFuture(new Date(e?.scheduled_at)) && !e.canceled
                    : isFuture(new Date(e?.scheduled_at)) && !e.canceled
                ).length || 0}{' '}
                talks today{' '}
                {props.requests?.filter((e) =>
                  props.isPro
                    ? isFuture(new Date(e?.scheduled_at)) && !e.confirmed
                    : isFuture(new Date(e?.scheduled_at))
                ).length || 0}{' '}
                requests
              </div>
              {windowWidth < 1024 &&
              window.location.pathname.split('/').includes('profile') ? (
                <div className="header_user_block">
                  <div className="user_img_w v1_mod">
                    <img
                      className="user_img"
                      onClick={() => props.showSidebar(!props.sidebar)}
                      src={props.user?.photo}
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                windowWidth < 1024 && (
                  <Link to="/account/profile">
                    <div className="header_user_block">
                      <div className="user_img_w v1_mod">
                        <img className="user_img" src={props.user?.photo} alt="" />
                      </div>
                    </div>
                  </Link>
                )
              )}
            </div>
            {windowWidth >= 1024 && (
              <div className="header_col_in">
                {props.user?.strikes > 0 && (
                  <div className="header_info_text size_mod">
                    You have received a strike! {props.user?.strikes - 3} left,{' '}
                    <a
                      className="callStrikePopup"
                      href={`http://help.talkpro.com/en/articles/5627604-what-is-a-strike`}
                    >
                      Click here
                    </a>
                    &nbsp;to learn more
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {windowWidth >= 1024 && (
          <div className="header_user">
            <div className="header_col">
              <div className="header_side_block">
                <Link className="header_search_w" to="/result">
                  <div className="header_search_icon">
                    <SearchIcon className="icon icon-search size_mod" />
                  </div>
                </Link>
                <Link to="/account/profile">
                  <div className="header_user_block">
                    <div className="user_img_w v1_mod">
                      {props.user ? (
                        props.user.photo ? (
                          <img className="user_img" src={`${props.user?.photo}`} alt="" />
                        ) : (
                          <DefaultAvatar className="user_img" />
                        )
                      ) : (
                        <Spinner className={'spinner_button'} />
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

const mapStateToProps = ({ auth: { user, isPro, requests, calls, sidebar } }) => ({
  user,
  isPro,
  requests,
  calls,
  sidebar,
})

const willMapDispatchToProps = {
  showSidebar,
}

export default withRouter(connect(mapStateToProps, willMapDispatchToProps)(Header))
