import { cva } from 'class-variance-authority'
import React from 'react'

import styles from './InputLabel.module.scss'

const labelStyles = cva(styles.label, {
  variants: {
    variant: {
      suffix: styles.labelVariantSuffix,
      preffix: styles.labelVariantPreffix,
    },
  },
})

const InputLabel = ({ id, children, variant }) => {
  return (
    <label className={labelStyles({ variant })} htmlFor={id}>
      {children}
    </label>
  )
}

export default InputLabel
