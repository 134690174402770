import React, { useState, useEffect, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import { UiContext } from 'context/ui'
import classNames from 'classnames'
import { MEDIA_BREAKPOINTS } from 'utils/constants'

import { DashboardStatistics } from '../DashboardStatistics'
import DashboardUserInfoCard from '../DashboardUserInfoCard/DashboardUserInfoCard'
import { DashboardReport } from '../DashboardReport'

import { ReactComponent as CloseIcon } from 'i/icons/close_white.svg'

import 'swiper/swiper-bundle.css'

import styles from './DashboardUserInfo.module.scss'

import { POPUPS_ID } from 'utils/constants'

const DashboardUserInfo = ({ isNewUser, info, loading }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showSlider, setShowSlider] = useState(true)
  const { createdClass, sentInvite, dashboardTabIndex } = useContext(UiContext)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  }, [])

  const hideSliderHandler = () => {
    setShowSlider(false)
  }

  SwiperCore.use([Autoplay, EffectFade])

  const getStartedCardData = [
    {
      completed: true,
      title: 'Your booking page',
      popupID: POPUPS_ID.availability,
    },
    {
      completed: sentInvite,
      title: 'Invite to talk',
      popupID: POPUPS_ID.sendInvite,
    },
    {
      completed: createdClass,
      title: 'Create a class',
      popupID: POPUPS_ID.createClass,
    },
  ]

  const whyCardData = [
    {
      icon: 'lock',
      title: 'End-to-end encryption',
    },
    {
      icon: 'voice',
      title: 'Voice Text Transcriptions',
    },
    {
      icon: 'screen',
      title: 'Automatic video recordings?',
    },
  ]

  return (
    <>
      {windowWidth >= MEDIA_BREAKPOINTS.lg ? (
        <div
          className={`${classNames(styles.root, {
            [styles.rootGridMod]: isNewUser,
          })}`}
        >
          <div className={styles.item}>
            <DashboardReport type="report" />
          </div>
          <div className={styles.item}>
            <DashboardReport type="ai" />
          </div>

          {/* {isNewUser && dashboardTabIndex === 0 && (
            <>
              <div className={styles.item}>
                <DashboardUserInfoCard title="Get Started" data={getStartedCardData} />
              </div>
              <div className={styles.item}>
                <DashboardUserInfoCard title="Why TalkPro?" data={whyCardData} />
              </div>
            </>
          )}
          {(!isNewUser || !loading) && (
            <>
              {(info || !loading) && (
                <div className={styles.item}>
                  <DashboardStatistics />
                </div>
              )}
            </>
          )} */}
        </div>
      ) : (
        windowWidth < MEDIA_BREAKPOINTS.lg &&
        showSlider && (
          <>
            {isNewUser && dashboardTabIndex === 0 && (
              <div
                className={`${classNames(styles.root, {
                  [styles.rootGridMod]: true,
                })}`}
              >
                <div className={styles.item}>
                  <DashboardReport type="report" />
                </div>
                <div className={styles.item}>
                  <DashboardReport type="ai" />
                </div>
                {/*<div className={styles.item}>
                  <DashboardUserInfoCard title="Get Started" data={getStartedCardData} />
                </div>
                <div className={styles.item}>
                  <DashboardUserInfoCard title="Why TalkPro?" data={whyCardData} />
                </div> */}
              </div>
            )}
            {!isNewUser && (
              <div className={styles.slider}>
                <button
                  type="button"
                  className={styles.sliderClose}
                  onClick={hideSliderHandler}
                >
                  <CloseIcon className={styles.sliderCloseIcon} />
                </button>
                <Swiper
                  autoHeight={true}
                  loop={true}
                  effect={'fade'}
                  spaceBetween={0}
                  slidesPerView={1}
                  // autoplay={{
                  //   delay: 4000,
                  //   disableOnInteraction: false,
                  // }}
                >
                  <SwiperSlide>
                    <DashboardReport type="report" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <DashboardReport type="ai" />
                  </SwiperSlide>
                  {info && (
                    <SwiperSlide>
                      <DashboardStatistics />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            )}
          </>
        )
      )}
    </>
  )
}

export default DashboardUserInfo
