import { CALL_TYPES } from './constants'

const getCallType = () => {
  const callType =
    window.location.href.indexOf(`/${CALL_TYPES.group}/`) >= 0
      ? CALL_TYPES.group
      : CALL_TYPES.private

  return callType
}

export default getCallType
