import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Spinner } from 'components/Spinner'

import { ReactComponent as DefaultAvatar } from 'i/icons/default-avatar.svg'

import { authApi } from 'api'

const AccountSidebar = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const history = useHistory()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <div className="account_sidebar">
      {windowWidth >= 1024 && (
        <div className="account_sidebar_photo_block">
          <div className="account_sidebar_photo_w">
            <div className="user_img_w v2_mod">
              {props.user ? (
                props.user?.photo ? (
                  <img className="user_img" src={`${props.user?.photo}`} alt="" />
                ) : (
                  <DefaultAvatar className="user_img" />
                )
              ) : (
                <Spinner />
              )}
            </div>
          </div>
          <div className="account_sidebar_name">{props.user?.user?.name}</div>
          <div className="account_sidebar_post">
            {props?.isPro ? props.user?.category : ''}
          </div>
        </div>
      )}
      <div className="account_sidebar_nav">
        <div className="account_sidebar_nav_list">
          <div className="result_menu_item">
            <Link
              className="result_menu_link account_mod active_mod"
              to="/account/profile"
            >
              <div className="result_menu_title">About</div>
              <div className="result_menu_text">Profile photo, name &amp; language</div>
            </Link>
          </div>
          <div className="result_menu_item">
            <Link className="result_menu_link account_mod" to="/account/settings">
              <div className="result_menu_title">Billing</div>
              <div className="result_menu_text">View history of payments</div>
            </Link>
          </div>
          {props.isPro && (
            <>
              <div className="result_menu_item">
                <div
                  style={{ cursor: 'pointer' }}
                  className="result_menu_link account_mod"
                  onClick={() => history.push('/pro/stripe', props.user)}
                >
                  <div className="result_menu_title">Payment</div>
                  <div className="result_menu_text">Change your payment Method</div>
                </div>
              </div>
            </>
          )}
          <div className="result_menu_item">
            <div
              style={{ cursor: 'pointer' }}
              className="result_menu_link account_mod"
              onClick={() =>
                authApi.logOut().then((history.push('/'), window.localStorage.clear()))
              }
            >
              <div className="result_menu_title">Logout</div>
              <div className="result_menu_text">Sign out of current session</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes, isPro } }) => ({
  user,
  authError: error,
  attributes,
  isPro,
})

export default connect(mapStateToProps)(AccountSidebar)
