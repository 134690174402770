import React, { useCallback, useContext, useRef } from 'react'
import { connect } from 'react-redux'

import QrMaker from 'components/QrMaker'
import { Logo, Menu } from './ui'
import { ReactComponent as CloseIcon } from 'i/icons/close_white.svg'

import talkProLogo from 'i/icons/talkpro-logo_new.svg'

import styles from './DashboardSidebar.module.scss'
import { useClickOutside, useWindowSize } from 'hooks'
import { MEDIA_BREAKPOINTS } from 'utils/constants'
import { UiContext } from 'context/ui'

const DashboardSidebar = (props) => {
  const { activePopupId } = useContext(UiContext)
  const windowSize = useWindowSize()
  const $container = useRef()

  const handleClose = useCallback(() => {
    if (windowSize.width < MEDIA_BREAKPOINTS.lg && activePopupId === null) {
      props.toggleSidebar()
    }
  }, [windowSize, activePopupId])

  useClickOutside($container, handleClose)

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarContainer} ref={$container}>
        {windowSize.width < MEDIA_BREAKPOINTS.lg && (
          <div className={styles.header}>
            <button
              className={styles.closeButton}
              type="button"
              onClick={props.toggleSidebar}
            >
              <CloseIcon className="icon size_mod" />
            </button>
          </div>
        )}
        {windowSize.width >= MEDIA_BREAKPOINTS.lg && <Logo />}
        <div className={styles.container}>
          <Menu
            setDashboardContent={props?.setDashboardContent}
            dashboardContentCurrentState={props?.dashboardContentCurrentState}
            clickToggle={handleClose}
          />
          {/* <div className={styles.footer}>
            <div className={styles.qrBlock}>
              <QrMaker />
            </div>
          </div> */}
          <div class="bottom_footer">
            <div class="bottomFooter_title">
              <img src={talkProLogo} alt="" class="bottomFooter_logo" />
            </div>
            <div class="menu_bottom">
              <ul class="bottom_menuList">
                <li class="bottom_menuItem"><a class="bottom_menuLink" href="https://talkpro.info/terms/">Terms</a></li>
                <li class="bottom_menuItem"><a class="bottom_menuLink" href="https://talkpro.info/privacy/">Privacy</a></li>
                <li class="bottom_menuItem"><a class="bottom_menuLink" href="https://talkpro.info/rules/">Rules</a></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes, isPro } }) => ({
  user,
  authError: error,
  attributes,
  isPro,
})

export default connect(mapStateToProps)(DashboardSidebar)
