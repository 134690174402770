import { React } from 'react'

const FooterProfile = (props) => {
  const footerMenu = ['Help', 'Terms', 'Privacy']
  return (
    <div className={`footer_profile ${props.v2 ? 'pos_mod' : ''}`}>
      <div className="footer_profile__in">
        <div className="footer_profile__menu">
          {footerMenu.map((item, index) => {
            return (
              <div className="footer_profile__menu_item" key={index}>
                <a href="" className="footer_profile__menu_link">
                  {item}
                </a>
              </div>
            )
          })}
        </div>
        <div className="footer_profile__copyright">TalkPro.com © 2022</div>
      </div>
    </div>
  )
}

export default FooterProfile
