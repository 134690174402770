import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Logo } from 'components/Header'
import Login from 'components/Auth/Login'
import { FormCheckBox, FormFieldVersionTwo } from 'components/Form'
import { ReactComponent as Google2Icon } from 'i/icons/brands/google_2.svg'

import JoinHeroImg from 'i/pro_join_hero_img.jpg'

import Auth from 'components/Auth/Auth'

import { setIsPro, logIn } from 'store/actions'

const LoginPage = (props) => {
  const request = props?.location?.state
  const [channelName, setChannelName] = useState()
  const [checked, setChecked] = useState()

  useEffect(() => {
    document.title = 'TalkPro | Join'
  }, [])

  return (
    <section className="section pro_join_mod full_mod_v2">
      <Logo proJoinPageMod />
      <div className="section_row flex_mod">
        <div className="section_col image_mod">
          <div className="section_w_img full_mod">
            <img className="cover_img" src={JoinHeroImg} alt="pro join" />
          </div>
        </div>
        <div className="section_col login_mod col_mod">
          <div className="pro_join_block">
            <h1 className="section_title offset_mod">Get started with Talkpro</h1>
            <FormFieldVersionTwo
              className="form_field_input color_mod"
              id="profile_name"
              labelText="Choose a account name"
              type="text"
              name="name"
              placeholder="Enter full name or brand"
              defaultValue=""
              onChange={(e) => setChannelName(e.target.value)}
            />
            <br />
            <div className="form_span_wrap_v3">
              <FormCheckBox
                id="bookHeader-name"
                name="terms"
                value={checked}
                onChange={() => setChecked(!checked)}
              />
              <span className="form_term_conditions">
                I agree with{' '}
                <a href="http://help.talkpro.com/en/articles/5527110-terms-and-conditions-of-service">
                  terms & conditions
                </a>
              </span>
            </div>
            <div className="btn_wrap width_mod offset_4_mod">
              <Auth isPro={true} channelName={channelName} />
            </div>
            <br />
            <div className="section_descr center_decor_mod mobile_margin_mod_v2">
              <span>Have an account?</span>
            </div>
            <br />
            <button
              className="btn_v3 user_join_mod btn_wrap offset_3_mod pos_relative"
              onClick={(e) => e.preventDefault()}
            >
              <div className="user_join_btn_icon_w">
                <Google2Icon className="icon icon-google btn_base_mod size_mod" />
              </div>
              <Login isPro={true} data={request} buttonText="Login with Google" />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { error, attributes } }) => ({
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(LoginPage)
