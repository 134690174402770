import React from 'react'
import classNames from 'classnames'
import { ReactComponent as LogoutIcon } from 'i/icons/logout.svg'
import { authApi } from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styles from './LogoutButton.module.scss'

function LogoutButton({ className, text }) {
  const history = useHistory()

  const handleLogout = () => {
    authApi.logOut().then((history.push('/'), window.localStorage.clear()))
  }

  return (
    <button
      type="button"
      className={classNames(styles.logout, className)}
      onClick={handleLogout}
    >
      {text}
      <div className={styles.logoutIcon}>
        <LogoutIcon />
      </div>
    </button>
  )
}

export { LogoutButton }
