import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { ProfileSideBar } from '../Book/ProfileSideBar'

import CheckoutForm from 'components/CheckoutForm/CheckoutForm'

import { setPro } from 'store/actions'

const PaymentsPage = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const history = useHistory()
  const [pro, setPro] = useState(null)
  const [proId, setProId] = useState(props.location.state.pro.id)
  const [rate, setRate] = useState(props.location.state.pro.rate * 100)
  const [eventId, setEventId] = useState(props.location.state.event?.id)
  const info = props?.location?.state

  const fontPath = `${window.location.origin}${
    require('fonts/FuturaPTMedium.woff2').default
  }`

  useEffect(() => {
    document.title = 'TalkPro | Payment'
  }, [])

  useEffect(() => {
    if (info?.event) setEventId(info?.event?.id)
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.localStorage.setItem('info', JSON.stringify(info))

    if (!props?.location?.state?.pro) history.push('/')

    setPro(props?.location?.state?.pro)
    setProId(props?.location?.state?.pro?.id)
    info?.event
      ? setRate(props?.location?.state?.event?.entry_fee * 100)
      : setRate(props?.location?.state?.pro?.rate * 100)

    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    props.setIsConfirmPage(true)
    return () => {
      props.setIsConfirmPage(false)
    }
  }, [])

  const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const stripePromise = loadStripe(
    'pk_test_51NGkIUBGgfDoOnET5BB8pbmtOPYnYqnNBlSBkmJhSc0LCYRmmR7u1c3ZvlMk5J2KQ6LLEnZvi1er5BTPK3gTjpBZ00IUp3ItaM'
  )

  const options = {
    mode: 'payment',
    amount: rate,
    currency: 'usd',
    appearance: {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false,
      },
      fonts: [
        {
          src: `url(${fontPath})`,
          family: 'FuturaPT',
          style: 'normal',
        },
      ],
      theme: 'flat',
    },
    payment_method_types: ['card'],
  }

  return (
    <section className="section profile">
      <div className="book__cols">
        <div className="book__col book__col--sidebar book__col--mobile_hidden_mod">
          <ProfileSideBar
            profile={pro}
            windowWidth={windowWidth}
            eventEntryFee={info?.event?.entry_fee}
          />
        </div>
        <div className="book__col book__col--main">
          <div class="book__col_content">
            <div className="book__payment">
              <div className="signup__col_in">
                <div className="signup__info signup__info--offset_3_mod">
                  <h1 className="signup__title">{`Confirm ${
                    info?.event ? 'your class' : ''
                  }`}</h1>
                  <div className="signup__subtitle">
                    {new Date(
                      info?.callRequest?.scheduled_at || info?.event.scheduled_at
                    ).toLocaleDateString('en-US', dateOptions)}
                  </div>
                  <div className="signup__descr">
                    You don’t need to remember an email and password, plus we’ll send you
                    a reminders before your talk
                  </div>
                </div>

                <div className="signup__tabs">
                  {/* <div className="signup__tabs_head">
                  <div className="signup__tabs_item">
                    <div className="signup__tabs_link">Connect calendar</div>
                  </div>
                  <div className="signup__tabs_item">
                    {pro?.rate !== '0' && (
                      <div className="signup__tabs_link signup__tabs_link--active">
                        Payment information
                      </div>
                    )}
                  </div>
                </div> */}
                  <div className="signup__tabs_content">
                    <div className="signup__tabs_content_item signup__tabs_content_item--active">
                      <div className="signup__form">
                        <Elements stripe={stripePromise} options={options}>
                          <CheckoutForm pro_id={proId} event_id={eventId} />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes } }) => ({
  user,
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setPro,
}

export default connect(mapStateToProps, willMapDispatchToProps)(PaymentsPage)
