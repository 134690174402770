import React from 'react'

const Spinner = ({ children, className, style = {}, button }) => (
  <div style={style} className={`spinner_container ${className ? className : ''}`}>
    <div className={button ? 'spinner btn_mod' : 'spinner'}></div>
    {children}
  </div>
)

export { Spinner }
