import * as actionTypes from './actionTypes'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  }
}

export const authSuccess = (user) => async (dispatch) => {
  // TODO: Remove when add fetching of settings from backend or attributes
  const payload = {
    user,
  }

  dispatch({
    type: actionTypes.AUTH_SUCCESS,
    payload,
  })
}

export const logIn = (payload) => async (dispatch, getState) => {
  dispatch(authStart())
  try {
    const user = payload
    // Will launch setting new password if logging in for the first time
    dispatch(authSuccess(user, null))
  } catch (err) {
    console.log('Error logging in', err)
    dispatch(authFail(err))
  }
}

export const showSidebar = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.SHOW_SIDEBAR, payload })
}

export const authFail = (error) => (dispatch) => {
  return dispatch({
    type: actionTypes.AUTH_FAIL,
    payload: error,
  })
}

export const resetAuthError = () => (dispatch) => {
  return dispatch({ type: actionTypes.AUTH_ERROR_RESET })
}

export const setPro = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_PRO, payload })
}

const saveSettings = (settingsToUpdate, userId) => {
  const settingsItem = `settings_${userId}`
  const settings = JSON.parse(localStorage.getItem(settingsItem)) || {}
  const updatedSettings = { ...settings, ...settingsToUpdate }
  localStorage.setItem(settingsItem, JSON.stringify(updatedSettings))
}

export const setAttributes = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_ATTRIBUTES, payload })
}

export const setIsPro = (proBool) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_IS_PRO, payload: proBool })
}

const getSettings = (userId) => {
  const settings = JSON.parse(localStorage.getItem(`settings_${userId}`)) || {}
  return settings
}

export const getParseAttributes = async (user, isOnline) => {
  const attributesToDecode = process.env.USER_ATTRIBUTES_TO_DECODE.split(',')

  const attributesArr = Object.entries(user.attributes).map(([key, val]) => ({
    Name: key,
    Value: val,
  }))

  const parsedAttributes = attributesArr
    .filter(({ Name }) => Name !== 'identities')
    .reduce((acc, { Name, Value }) => {
      const parsedName = Name.replace(/^custom:/, '')

      acc[parsedName] = attributesToDecode.includes(parsedName)
        ? JSON.parse(decodeURIComponent(Value))
        : Value

      return acc
    }, {})
  // Add additional properties not in cognito attributes for ease of access

  return parsedAttributes
}

export const setIsOnlineState = (isOnline) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_ONLINE_STATE, payload: isOnline })
}

export const setRequests = (requests) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_REQUESTS, payload: requests })
}

export const setCalls = (calls) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_CALLS, payload: calls })
}

export const setEvents = (events) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_EVENTS, payload: events })
}

export const setEventSettings = (settings) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_EVENT_SETTINGS, payload: settings })
}

export const authRefreshToken = () => async (dispatch) => {
  try {
    const cognitoUser = localStorage.getItem('refreshToken')
    const currentSession = await cognitoUser.signInUserSession
    await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      console.log(session)
    })
    dispatch({ type: actionTypes.AUTH_REFRESH_TOKEN })
  } catch (e) {
    console.log(e)
    dispatch({ type: actionTypes.AUTH_FAIL, payload: e.message })
    return false
  }
}
