import React, { useEffect, useState } from 'react'
import { ErrorMessage, Formik, Field } from 'formik'
import { useHistory } from 'react-router-dom'

import { AvailableDay } from './ui'

import styles from './AvailabilityForm.module.scss'
import { AlertMessage } from 'components/AlertMessage'
import { DAYS_WEEK, oneOnOneServiceName, PROMISE_STATES } from 'utils/constants'
import { createInitialFormValues } from './helpers'
import { paymentApi, profileApi } from 'api'
import { timezoneOptions } from 'utils/constants'
import times from 'utils/times.json'
import { FormCustomSelect } from 'components/Form'
import PaymentsImg from 'i/accept_payments.png'
import { connect } from 'react-redux'

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {}

  if (values) {
    const isNotValid = Object.values(values).every((value) => value.checked === false)

    if (isNotValid) {
      errors.monday = 'One of the days must be selected'
    }
  }

  return errors
}

const AvailabilityForm = ({
  initialValues,
  setFieldValue,
  onSubmitCallback,
  setTimezone,
  user,
}) => {
  const [status, setStatus] = useState(PROMISE_STATES.idle)
  const [errorSubmitMessage, setErrorSubmitMessage] = useState(null)
  const [rate, setRate] = useState(user?.rate)
  const [formInitialValues, setFormInitialValues] = useState()
  const [isRatePage, setIsRatePage] = useState(window.location.pathname === '/pro/rate')
  const history = useHistory()

  useEffect(() => {
    const values = initialValues ? JSON.parse(initialValues) : createInitialFormValues()
    // values.monday.checked = true
    setFormInitialValues(values)
  }, [])

  useEffect(() => {
    console.log('-----')
    console.log(formInitialValues)
  }, [formInitialValues])

  return (
    <>
      {formInitialValues && (
        <Formik
          initialValues={formInitialValues}
          validate={validate}
          onSubmit={async (values) => {
            const formData = { ...values }

            const defaultStart = times[33]?.time
            const defaultEnd = times[73]?.time

            Object.values(formData).forEach((value) => {
              if (value.checked === false) {
                value.start = defaultStart
                value.end = defaultEnd
              }
            })

            if (setFieldValue && typeof setFieldValue === 'function') {
              setFieldValue(oneOnOneServiceName, formData)
              onSubmitCallback()
              setFieldValue('rate', rate)
              return
            }
            setStatus(PROMISE_STATES.pending)

            try {
              await profileApi.setProInfo({
                [oneOnOneServiceName]: JSON.stringify(formData),
              })
              setStatus(PROMISE_STATES.fulfilled)
              if (parseInt(values.rate) === 0 && !user?.subscription_timestamp) {
                history.push('/pro/stripe', values.rate)
              }
              if (user?.rate < parseInt(values.rate) && !user?.stripe_info) {
                paymentApi
                  .connectStripe()
                  .then((res) => (window.location = res.result.action_url))
              }
              onSubmitCallback()
            } catch (error) {
              setStatus(PROMISE_STATES.rejected)
              setErrorSubmitMessage(error.message)
            }
          }}
        >
          {({ values, handleSubmit, handleChange, submitForm }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.container}>
                <div className="form_group form_group--offset_v3_mod profile_mod">
                  <FormCustomSelect
                    v2
                    placeholder="Select time zone"
                    options={timezoneOptions}
                    onChange={(e) => {
                      setTimezone(e.value)
                    }}
                  />
                </div>
                <ul className={styles.list}>
                  {Object.entries(formInitialValues).map(([day, data]) => (
                    <>
                      <li className={styles.item} key={day}>
                        <AvailableDay
                          name={day}
                          checked={data.checked}
                          timeFrom={data.start}
                          timeTo={data.end}
                          dayNameFormProperty={day}
                          handleChange={handleChange}
                        />
                      </li>
                    </>
                  ))}
                  {/* {DAYS_WEEK.map(({ name }, index) => {
                    const dayName = name.toLowerCase()
                    const {
                      start: dayTimeFrom,
                      end: dayTimeTo,
                      checked,
                    } = values[dayName]

                    const isDayChecked = isRatePage
                      ? index === 0
                        ? true
                        : false
                      : checked
                    const timeTo = isRatePage
                      ? index === 0
                        ? '5:15 PM'
                        : null
                      : dayTimeFrom
                    const timeFrom = isRatePage
                      ? index === 0
                        ? '8:00 AM'
                        : null
                      : dayTimeTo

                    return (
                      <li className={styles.item} key={name}>
                        <AvailableDay
                          name={name}
                          checked={isDayChecked}
                          timeTo={timeTo}
                          timeFrom={timeFrom}
                          dayNameFormProperty={dayName}
                          handleChange={handleChange}
                        />
                      </li>
                    )
                  })} */}
                </ul>
                <div className="form_group form_group--border_mod profile_mod">
                  <label className="form_group__label form_group__label--v2_mod">
                    <strong>Do you charge for your time?</strong>
                    If yes, you will be re-directed to enter banking details to receive
                    payments.
                  </label>
                  <div class="form_row form_row--session_mod">
                    <div class="form_group form_group--session_mod">
                      <span className="form_group__preffix">$</span>
                      <Field
                        className="form_group__field"
                        name="rate"
                        type="number"
                        placeholder={user?.rate ? user?.rate : `Enter amount`}
                        onChange={(e) => setRate(e.target.value)}
                      />
                      <span className="form_group__suffix session_mod">/ Meeting</span>
                    </div>
                    <div class="form_payments">
                      <div class="form_payments__title">Accept payments:</div>
                      <div class="form_payments__pic">
                        <img src={PaymentsImg} alt="" class="form_payments__img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage name="monday">
                {(msg) => <AlertMessage message={msg} formMod />}
              </ErrorMessage>
              {errorSubmitMessage && (
                <AlertMessage message={errorSubmitMessage} formMod />
              )}
              <button
                type="submit"
                className="form_group__button"
                disabled={status === PROMISE_STATES.pending}
                onClick={(e) => (e.preventDefault(), submitForm())}
              >
                {isRatePage ? 'Continue' : 'Save schedule'}
              </button>
            </form>
          )}
        </Formik>
      )}
    </>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(AvailabilityForm)
