import { React, useState, useEffect } from 'react'

import { Logo } from 'components/Header'

import textLogo from 'i/talkpro.png'

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <header className="header">
      <div className="header_in limit_mod padding_mod">
        <div className="header_logo_w">
          <Logo />
        </div>
        <div className="header_text">
          <img className="header_text_img" src={textLogo} alt="talkpro" />
        </div>
        <div className="header_btn_w">
          <a className="btn_v5" href="http://help.talkpro.com/en">
            Help
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
