import React from 'react'
import styles from './TranscriptionChat.module.scss'
import ChatMessage from 'components/ChatMessage/ChatMessage'

import { FetchResult } from 'components/FetchResult'
import { Spinner } from 'components/Spinner'

import { useFetch } from 'hooks'
import { callApi } from 'api'
import getCallType from 'utils/getCallType'

function TranscriptionChat({ transcription, roomID, variant }) {
  const callType = getCallType()

  const { loading, error, data } = useFetch(() =>
    callApi.getCallTranscription(callType, roomID)
  )

  return (
    <div className={styles.component}>
      {transcription &&
        JSON.parse(transcription).map((item, index) => {
          return (
            <ChatMessage
              variant={variant}
              data={item}
              key={index}
              index={index}
              myName="Jake Smith"
            />
          )
        })}
      {!transcription && (
        <FetchResult
          error={error}
          status={loading}
          data={data}
          indicator={<Spinner className={styles.spinner} />}
        >
          <div className={styles.list}>
            {data &&
              JSON.parse(data?.result?.trascription).map((item, index) => {
                return (
                  <ChatMessage
                    variant={variant}
                    data={item}
                    key={index}
                    index={index}
                    myName="Jake Smith"
                  />
                )
              })}
          </div>
        </FetchResult>
      )}
    </div>
  )
}

export default TranscriptionChat
