import React from 'react'

import ProfileImage from 'i/profile_sidebar.jpg'

import { ReactComponent as PinIcon } from 'i/icons/pin-3.svg'
import { ReactComponent as GlobeIcon } from 'i/icons/globe_2.svg'
import { ReactComponent as BagIcon } from 'i/icons/bag.svg'
import { ReactComponent as TagIcon } from 'i/icons/a-tag.svg'

import { ReactComponent as LinkedInIcon } from 'i/icons/social_media/logo-linkedin.svg'
import { ReactComponent as InstagramIcon } from 'i/icons/social_media/logo-instagram.svg'
import { ReactComponent as FacebookIcon } from 'i/icons/social_media/logo-fb-simple.svg'
import { ReactComponent as TwitterIcon } from 'i/icons/social_media/logo-twitter.svg'
import { ReactComponent as TikTokIcon } from 'i/icons/social_media/logo-tiktok.svg'
import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'

const ProfileSidebar = () => {
  const meta = [
    {
      id: 0,
      icon: PinIcon,
      text: 'Based in Miami, FL, USA',
    },
    {
      id: 1,
      icon: GlobeIcon,
      text: 'Fluent in English and Spanish',
    },
    {
      id: 2,
      icon: BagIcon,
      text: '3 years of experience ',
    },
    {
      id: 3,
      isLink: true,
      icon: TagIcon,
      text: 'www.mysite.com',
    },
  ]

  const social = [
    {
      id: 0,
      icon: LinkedInIcon,
    },
    {
      id: 1,
      icon: InstagramIcon,
    },
    {
      id: 2,
      icon: FacebookIcon,
    },
    {
      id: 3,
      icon: TwitterIcon,
    },
    {
      id: 4,
      icon: TikTokIcon,
    },
    {
      id: 5,
      icon: YoutubeIcon,
    },
  ]

  return (
    <div className="profile_sidebar">
      <div className="profile_sidebar_img profile_sidebar__img">
        <img className="profile_sidebar_img__in" src={ProfileImage} alt="" />
      </div>

      <div className="profile_sidebar_profession profile_sidebar__profession">
        Fashion Influencer
      </div>

      <div className="profile_sidebar_name profile_sidebar__name">Karl Mattenfield</div>

      <ul className="profile_sidebar_salaries profile_sidebar__salaries">
        <li className="profile_sidebar_salaries__item profile_sidebar_salaries__item--accent">
          <span className="profile_sidebar_salaries__item_money">$6</span>
          <span className="profile_sidebar_salaries__item_period">/min</span>
        </li>
        <li className="profile_sidebar_salaries__item">
          <span className="profile_sidebar_salaries__item_money">$72</span>
          <span className="profile_sidebar_salaries__item_period">/hour</span>
        </li>
      </ul>

      <div className="profile_sidebar_description profile_sidebar__description">
        Financial expert with 10+ years of experience.
        <br />
        Create videos about finance and the stock market.
      </div>

      <ul className="profile_sidebar_meta profile_sidebar__meta">
        {meta.map((item) => (
          <li className="profile_sidebar_meta__item" key={item.id}>
            <div className="profile_sidebar_meta__item_icon">
              <item.icon className="icon size_mod" />
            </div>
            {item.isLink ? (
              <a
                className="profile_sidebar_meta__item_text profile_sidebar_meta__item_text--link"
                href="#"
              >
                {item.text}
              </a>
            ) : (
              <div className="profile_sidebar_meta__item_text">{item.text}</div>
            )}
          </li>
        ))}
      </ul>

      <div className="profile_sidebar_social profile_sidebar__social">
        <ul className="profile_sidebar_social__list">
          {social.map((item) => (
            <li className="profile_sidebar_social__item" key={item.id}>
              <a className="profile_sidebar_social__item_link" href="#">
                <div className="profile_sidebar_social__item_icon">
                  <item.icon className="icon size_mod" />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ProfileSidebar
