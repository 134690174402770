export const daysOfWeekMap = new Map([
  [0, 'sunday'],
  [1, 'monday'],
  [2, 'tuesday'],
  [3, 'wednesday'],
  [4, 'thursday'],
  [5, 'friday'],
  [6, 'saturday'],
])

export const MEDIA_BREAKPOINTS = {
  xxl: 1400,
  xl: 1200,
  lg: 1024,
}

export const PROMISE_STATES = {
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
  idle: 'idle',
}

export const POPUPS_ID = {
  meetingInfo: 'meeting-info',
  createClass: 'create-class',
  availability: 'availability',
  addVideo: 'add-video',
  callVideo: 'call-video',
  sendInvite: 'send-invite',
  classes: 'classes',
  profile: 'profile',
  billing: 'billing',
  help: 'help',
  chat: 'chat',
  search: 'search',
  summary: 'summary',
  manageSubscriptionPopup: 'subscription-popup',
}

export const professionalTypeOptions = [
  {
    value: { name: 'Therapist' },
    label: 'Therapist',
  },
  {
    value: { name: 'Practitioner' },
    label: 'Practitioner',
  },
  {
    value: { name: 'Business' },
    label: 'Business',
  },
  {
    value: { name: 'Technology' },
    label: 'Technology',
  },
  {
    value: { name: 'Other' },
    label: 'Other',
  },
]

export const timezoneOptions = [
  {
    value: { name: 'America/New_York', tag: 'en-us' },
    label: 'Eastern Time (US & Canada)',
  },
  {
    value: { name: 'America/Chicago', tag: 'en-us' },
    label: 'Central Time (US & Canada)',
  },
  {
    value: { name: 'America/Denver', tag: 'en-us' },
    label: 'Mountain Time (US & Canada)',
  },
  { value: { name: 'America/Phoenix', tag: 'en-us' }, label: 'Arizona' },
  {
    value: { name: 'America/Los_Angeles', tag: 'en-us' },
    label: 'Pacific Time (US & Canada)',
  },
  { value: { name: 'America/Anchorage', tag: 'en-us' }, label: 'Alaska' },
  { value: { name: 'Pacific/Honolulu', tag: 'en-us' }, label: 'Hawaii' },
  { value: { name: 'America/Adak', tag: 'en-us' }, label: 'Aleutian Islands' },
  { value: { name: 'Pacific/Guam', tag: 'en-us' }, label: 'Guam' },
  { value: { name: 'America/Puerto_Rico', tag: 'en-us' }, label: 'Puerto Rico' },
  { value: { name: 'America/Port-au-Prince', tag: 'en-us' }, label: 'Haiti' },
  { value: { name: 'America/Managua', tag: 'en-us' }, label: 'Central America' },
  { value: { name: 'America/Caracas', tag: 'en-us' }, label: 'Caracas' },
  { value: { name: 'America/Asuncion', tag: 'en-us' }, label: 'Asuncion' },
  { value: { name: 'America/Buenos_Aires', tag: 'en-us' }, label: 'Buenos Aires' },
  { value: { name: 'America/Santiago', tag: 'en-us' }, label: 'Santiago' },
  { value: { name: 'America/St_Johns', tag: 'en-us' }, label: 'Newfoundland' },
  { value: { name: 'America/Sao_Paulo', tag: 'en-us' }, label: 'Brasilia' },
  { value: { name: 'America/Godthab', tag: 'en-us' }, label: 'Greenland' },
  { value: { name: 'Atlantic/Cape_Verde', tag: 'en-us' }, label: 'Cape Verde Is.' },
  { value: { name: 'Atlantic/Azores', tag: 'en-us' }, label: 'Azores' },
  { value: { name: 'Africa/Casablanca', tag: 'en-us' }, label: 'Casablanca' },
  { value: { name: 'Europe/London', tag: 'en-us' }, label: 'London' },
  { value: { name: 'Europe/Berlin', tag: 'en-us' }, label: 'Berlin' },
  { value: { name: 'Europe/Athens', tag: 'en-us' }, label: 'Athens' },
  { value: { name: 'Europe/Moscow', tag: 'en-us' }, label: 'Moscow' },
  { value: { name: 'Asia/Tehran', tag: 'en-us' }, label: 'Tehran' },
  { value: { name: 'Asia/Dubai', tag: 'en-us' }, label: 'Abu Dhabi' },
  { value: { name: 'Asia/Kabul', tag: 'en-us' }, label: 'Kabul' },
  { value: { name: 'Asia/Karachi', tag: 'en-us' }, label: 'Islamabad' },
  { value: { name: 'Asia/Kolkata', tag: 'en-us' }, label: 'New Delhi' },
  { value: { name: 'Asia/Kathmandu', tag: 'en-us' }, label: 'Kathmandu' },
  { value: { name: 'Asia/Dhaka', tag: 'en-us' }, label: 'Dhaka' },
  { value: { name: 'Asia/Bangkok', tag: 'en-us' }, label: 'Bangkok' },
  { value: { name: 'Asia/Hong_Kong', tag: 'en-us' }, label: 'Hong Kong' },
  { value: { name: 'Asia/Tokyo', tag: 'en-us' }, label: 'Tokyo' },
  { value: { name: 'Australia/Perth', tag: 'en-us' }, label: 'Perth' },
  { value: { name: 'Asia/Vladivostok', tag: 'en-us' }, label: 'Vladivostok' },
  { value: { name: 'Asia/Magadan', tag: 'en-us' }, label: 'Magadan' },
  { value: { name: 'Asia/Kamchatka', tag: 'en-us' }, label: 'Kamchatka' },
  { value: { name: 'Pacific/Auckland', tag: 'en-us' }, label: 'Auckland' },
  { value: { name: 'Pacific/Fiji', tag: 'en-us' }, label: 'Fiji' },
  { value: { name: 'Pacific/Tongatapu', tag: 'en-us' }, label: 'Tonga' },
]

export const oneOnOneServiceName = 'one_on_one_service'
export const liveClassesServiceName = 'live_classes_service'
export const addVideoServiceName = 'add_video_service'

export const DAYS_WEEK = [
  {
    name: 'Monday',
  },
  {
    name: 'Tuesday',
  },
  {
    name: 'Wednesday',
  },
  {
    name: 'Thursday',
  },
  {
    name: 'Friday',
  },
  {
    name: 'Saturday',
  },
  {
    name: 'Sunday',
  },
]

export const MANAGE_SUBSCRIPTION_OPTIONS = [
  {
    value: 'option-a',
    label: 'Option A',
  },
  {
    value: 'option-b',
    label: 'Option B',
  },
  {
    value: 'option-c',
    label: 'Option C',
  },
]

export const DASHBOARD_CONTENT_STATES = {
  default: 'default',
  profile: 'profile',
  billing: 'billing',
}

export const GOOGLE_API_KEY = 'AIzaSyBIJ4YTnA9JQWo9YjBVkm5ZTE_VkMEjS8c'

export const CALL_TYPES = {
  private: 'private',
  group: 'group',
}
