import React, { useEffect, useState } from 'react'

import { AlertMessage } from 'components/AlertMessage'
import { Spinner } from 'components/Spinner'

import { useYoutubeVideos } from '../../hooks'

import { PROMISE_STATES } from 'utils/constants'

import styles from './VideoList.module.scss'

const VideoList = ({ onSave, currentVideos }) => {
  const [selectedVideos, setSelectedVideos] = useState([])
  const { videos, fetchError, fetchStatus } = useYoutubeVideos()

  const onChange = (e, videoId) => {
    const videos = [...selectedVideos]
    const videoIndex = videos.findIndex((video) => video.videoId === videoId)
    if (videoIndex !== -1) {
      videos[videoIndex].checked = e.target.checked
    }
    setSelectedVideos(videos)
  }

  const saveVideos = () => {
    const res = selectedVideos.filter((video) => {
      return video.checked
    })

    onSave(res)
  }

  const handleVideoes = (vid) => {
    let secondArrayVideoIds = new Set()

    if (currentVideos) {
      secondArrayVideoIds = new Set(currentVideos?.map((item) => item.videoId))
    }

    const result = vid.map((item) => ({
      ...item,
      checked: secondArrayVideoIds.has(item.videoId),
    }))

    return result
  }

  useEffect(() => {
    const result = handleVideoes(videos)
    setSelectedVideos(result)
  }, [videos])

  return (
    <div className={styles['container']}>
      {fetchStatus === PROMISE_STATES.fulfilled &&
        (selectedVideos?.length ? (
          <>
            <div className={styles['list']}>
              {selectedVideos?.map(
                ({ title, videoId, videoLink, thumbnails, checked }, index) => {
                  return (
                    <div className={styles['item']}>
                      <input
                        className={styles.checkbox}
                        id={videoId}
                        type="checkbox"
                        name={videoId}
                        value={videoId}
                        defaultChecked={checked}
                        onChange={(e) => {
                          onChange(e, videoId)
                        }}
                      />
                      <label for={videoId} className={styles['item__link']}>
                        <div className={styles['item__image_wrap']}>
                          <img
                            className={styles['item__image']}
                            src={thumbnails?.default?.url}
                            alt={title}
                          />
                        </div>
                        <div className={styles['item__content']}>{title}</div>
                      </label>
                    </div>
                  )
                }
              )}
            </div>
            <button type="button" onClick={saveVideos} className="form_group__button">
              Continue
            </button>
          </>
        ) : (
          <div className={styles['message']}>No YouTube videos were found</div>
        ))}
      {fetchStatus === PROMISE_STATES.pending && <Spinner className="spinner_button" />}
      {fetchError && fetchStatus === PROMISE_STATES.rejected ? (
        <AlertMessage message={fetchError} />
      ) : null}
    </div>
  )
}

export default VideoList
