import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

const LoginHeaderClient = (props) => {
  const history = useHistory()

  return (
    <div
      className={`${
        props.isPro
          ? 'btn_base login_mod position_relative_mod'
          : props.isMobile
          ? 'btn_v8 user_join_mod btn_wrap offset_3_mod margin_mod'
          : 'btn_v7_lower user_join_mod btn_wrap offset_3_mod'
      }`}
      onClick={(e) => e.preventDefault()}
    >
      <button
        onClick={() => history.push('/user/login')}
        style={{
          backgroundColor: `${props.isPro ? '#6b59cc' : 'none'}`,
          color: '#339f78',
          cursor: `pointer`,
          textDecoration: `none`,
          fontSize: `${props.isPro ? 'auto' : '1.5rem'}`,
          fontWeight: `${props.isPro ? 'auto' : '700'}`,
          minWidth: `${props.isPro ? '105%' : '100%'}`,
          height: `${props.isPro ? '100%' : ''}`,
          borderRadius: `${props.isPro ? '1rem' : ''}`,
        }}
      >
        Client
      </button>
    </div>
  )
}

const mapStateToProps = ({ auth: { error } }) => ({
  authError: error,
})

export default connect(mapStateToProps)(LoginHeaderClient)
