import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { applyMiddleware, createStore, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import App from './App'

import authReducer from './store/reducers/authReducer'
import historyReducer from './store/reducers/historyReducer'

import './styles/main_global.scss'
import { UiState } from 'context/ui'

const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  auth: authReducer,
  history: historyReducer,
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <UiState>
        <App />
      </UiState>
    </Router>
  </Provider>,
  document.getElementById('root')
)
