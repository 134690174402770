import React from 'react'

import styles from './FormInputDropdown.module.scss'

import { ReactComponent as CloseIcon } from 'i/icons/close.svg'
import { useFormikContext } from 'formik'

const FormInputDropdown = ({
  className,
  data,
  fieldName,
  isOpen,
  onMouseEnter,
  onMouseLeave,
  removeContact,
  hover,
  emails,
  setEmails,
  setTextInput,
}) => {
  const { setFieldValue } = useFormikContext()

  const updateInputValue = (value) => {
    if (!emails.includes(value)) {
      setEmails([...emails, value])
      setFieldValue(fieldName, value)
    }
  }

  return (
    <div className={className}>
      {isOpen &&
        (hover ? (
          <div
            className={hover ? styles.dropdownHover : styles.dropdown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {data &&
              data
                ?.filter((e) => !emails.includes(e.email) && e)
                .map(({ displayName, email }) => {
                  return (
                    <div className={styles.dropdownItem}>
                      <div
                        className={styles.dropdownItemTrigger}
                        onClick={() => {
                          updateInputValue(email)
                          setTextInput('')
                          onMouseLeave()
                        }}
                      >
                        <div className={styles.dropdownItemAvatar}>
                          {displayName
                            ? displayName.split('')[0]
                            : email
                            ? email.split('')[0]
                            : ''}
                        </div>
                        <div className={styles.dropdownItemContent}>
                          <div className={styles.dropdownItemName}>
                            {displayName ? displayName : email ? email.split('@')[0] : ''}
                          </div>
                          <div className={styles.dropdownItemEmail}>{email}</div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className={styles.dropdownItemClose}
                        onClick={(e) => {
                          removeContact(email)
                        }}
                      >
                        <CloseIcon className="icon size_mod" />
                      </button>
                    </div>
                  )
                })}
          </div>
        ) : (
          emails.map((email) => {
            let displayName
            let mailAddress
            let isInvited = false
            if (data?.map((e) => e.email).includes(email)) {
              displayName = data?.filter((e) => e.email === email)[0].displayName
              mailAddress = data?.filter((e) => e.email === email)[0].email
            }
            if (typeof email === 'object') {
              mailAddress = email.email
              displayName = email.displayName
              isInvited = email.isInvited
            }
            if (!data?.map((e) => e.email).includes(email) && typeof email === 'string') {
              mailAddress = email
              displayName = ''
              isInvited = false
            }

            return (
              <div className={styles.dropdownItemOverlay}>
                <div className={styles.dropdownItemTrigger}>
                  <div className={styles.dropdownItemAvatar}>
                    {mailAddress?.split('')[0]}
                  </div>
                  <div className={styles.dropdownItemContent}>
                    {displayName && (
                      <div className={styles.dropdownItemName}>{displayName}</div>
                    )}
                    {!displayName && (
                      <div className={styles.dropdownItemName}>{mailAddress}</div>
                    )}
                  </div>
                </div>

                {!isInvited && (
                  <button
                    type="button"
                    className={styles.dropdownItemClose}
                    onClick={(e) => {
                      setEmails(emails.filter((e) => e !== email))
                    }}
                  >
                    <CloseIcon className="icon size_mod" />
                  </button>
                )}
              </div>
            )
          })
        ))}
    </div>
  )
}

export default FormInputDropdown
