import { apiRequest } from '../apiRequest'

import { getUserInfo, setUserInfo, getProList, getProInfo, uploadUserAvi } from './User'

import { getProfileInfo, setProInfo, getYoutubeVideos, uploadPhoto } from './Pro'

const getAnalytics = () => apiRequest('/analytics/').get()

export const profileApi = {
  getProfileInfo,
  getAnalytics,
  getUserInfo,
  getProList,
  getProInfo,
  setProInfo,
  setUserInfo,
  getYoutubeVideos,
  uploadPhoto,
  uploadUserAvi,
}
