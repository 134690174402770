import DOMPurify from 'isomorphic-dompurify'

export function SanitaizedText({ children }) {
  if (!children) return null

  const formattedChildren = children.replace(/\n/g, '<br>')
  const sanitized = DOMPurify.sanitize(formattedChildren)

  return <div dangerouslySetInnerHTML={{ __html: sanitized }} />
}

export default SanitaizedText
