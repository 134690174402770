import { apiRequest } from '../../apiRequest'
import axios from 'axios'

export const googleLogInPro = (data) => apiRequest(`/pro/social-login/google/`).post(data)

export const getGoogleContacts = async (access_token) => {
  return await axios.get(
    'https://content-people.googleapis.com/v1/people/me/connections',
    {
      headers: { Authorization: `Bearer ${access_token.access_token}` },
      params: { pageSize: 2000, personFields: 'emailAddresses,names' },
    }
  )
}
