import React from 'react'

const FormCheckBox = ({ id, name, title, handleChange, defaultChecked }) => {
  return (
    <div className="form_field">
      <label className="form_field_label" htmlFor={id}>
        <input
          className="form_field_radiocheck"
          id={id}
          type="checkbox"
          name={name}
          onChange={handleChange}
          defaultChecked={defaultChecked}
        />
        <span className="form_field__text form_field_text_check">{title}</span>
      </label>
    </div>
  )
}

export default FormCheckBox
