import { React, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormCheckBox } from 'components/Form'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { Spinner } from 'components/Spinner'

import { ReactComponent as TikTokIcon } from 'i/icons/social_media/logo-tiktok.svg'
import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'
import { ReactComponent as FacebookIcon } from 'i/icons/social_media/facebook2.svg'
import { ReactComponent as InstagramIcon } from 'i/icons/social_media/instagram2.svg'
import { ReactComponent as LinkedinIcon } from 'i/icons/social_media/linkedin2.svg'
import { ReactComponent as TwitterIcon } from 'i/icons/social_media/twitter2.svg'

import { profileApi, authApi } from 'api'

import { logIn } from 'store/actions'

import sessionTypes from 'utils/sessionTypes.json'

const ProfileForm = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [success, setSuccess] = useState(false)
  const [checked, setChecked] = useState()

  const history = useHistory()

  const rateSchema = Yup.object().shape({
    rate: Yup.number().required('Rate is required').max(10000, 'Rate is too high'),
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <Formik
      initialValues={{
        rate: Math.trunc(props.user?.rate),
        location: `${props.user?.location ? props.user?.location : ''}`,
        // Need to review
        language: `${
          props.user?.language ? props.user?.language?.code?.toLowerCase() : 'en'
        }`,
        category: props.user?.category || '',
        website: `${props.user?.youtube ? props.user?.youtube : ''}`,
        expectations: `${props.user?.expectations ? props.user?.expectations : ''}`,
        bio: `${props.user?.bio ? props.user?.bio : ''}`,
        name: `${props.user?.user?.name ? props.user?.user?.name : ''}`,
        website_url: `${props.user?.website_url ? props.user?.website_url : ''}`,
        linkedin_usr: `${props.user?.linkedin_usr ? props.user?.linkedin_usr : ''}`,
        insta_usr: `${props.user?.insta_usr ? props.user?.insta_usr : ''}`,
        facebook_usr: `${props.user?.facebook_usr ? props.user?.facebook_usr : ''}`,
        twitter_usr: `${props.user?.twitter_usr || ''}`,
        youtube: `${props.user?.youtube || ''}`,
        tiktok_usr: `${props.user?.tiktok_usr || ''}`,
      }}
      validationSchema={rateSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        const data = {
          rate: values.rate.toString(),
          rate_currency_code: 'USD',
          language: props.user?.language
            ? props.user?.language?.code?.toLowerCase()
            : 'en',
          location: values.location,
          category: values.category || '',
          expectations: values.expectations,
          bio: values.bio,
          name: values.name,
          website_url: values.website_url,
          linkedin_usr: values.linkedin_usr,
          insta_usr: values.insta_usr,
          facebook_usr: values.facebook_usr,
          twitter_usr: values.twitter_usr,
          youtube: values.youtube,
          tiktok_usr: values.tiktok_usr,
        }

        Object.keys(data).forEach((key) => {
          if (data[key] === '') delete data[key]
        })

        await profileApi.setProInfo(data)

        setSuccess(true)

        window.location.reload()
      }}
    >
      {({ errors, touched, submitForm, isSubmitting, handleChange, values }) => (
        <>
          <div className="signup__tabs_content">
            <div className="signup__tabs_content_item signup__tabs_content_item--active">
              <div className="signup__form">
                <Form className="signup__form_in">
                  <div className="form_row form_row--grid_mod">
                    <div className="form_group form_group--icon_mod profile_mod">
                      <label className="form_group__label">Your rate</label>
                      <Field
                        required
                        className="form_group__field"
                        name="rate"
                        type="number"
                        placeholder={[
                          props.user
                            ? parseInt(props.user?.rate).toFixed(0)
                            : 'Enter Amount',
                        ]}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="form_group__preffix">$</span>
                      <span className="form_group__suffix session_mod">
                        / {sessionTypes[`${values.category}`]}
                      </span>
                    </div>
                    <div className="form_group form_group--icon_mod profile_mod">
                      <label className="form_group__label">Pro Type</label>
                      <Field
                        name="category"
                        id="category"
                        as="select"
                        className="form_group__field"
                        disabled={isSubmitting || success}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                      >
                        <option value="Professional Type" disabled hidden>
                          Professional Type
                        </option>
                        <option value="Therapist">Therapist</option>
                        <option value="Practitioner">Practitioner</option>
                        <option value="Business">Business</option>
                        <option value="Technology">Technology</option>
                        <option value="Other">Other</option>
                      </Field>
                    </div>
                  </div>
                  <div className="form_group">
                    <label className="form_group__label">
                      What should someone expect (short and sweet)
                    </label>
                    <Field
                      name="expectations"
                      as="textarea"
                      className="form_group__field"
                      placeholder={props.user?.expectations || 'Enter what you get'}
                      disabled={isSubmitting || success}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="form_span_wrap_v3 profile_mod">
                    <FormCheckBox
                      id="bookHeader-name"
                      name="terms"
                      value={props.recording_allowed}
                      onChange={() => setChecked(!checked)}
                    />
                    <span className="form_term_conditions profile_mod">
                      Allow clients to record calls?
                    </span>
                  </div>
                  <div className="form_group__divider">
                    <div className="form_group__divider_in">Profile</div>
                  </div>
                  <div className="form_group">
                    <label className="form_group__label">Display name</label>
                    <Field
                      className="form_group__field"
                      name="name"
                      type="text"
                      placeholder={
                        props.user?.user?.name || 'Enter your title or headline'
                      }
                      disabled={isSubmitting || success}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="form_group">
                    <label className="form_group__label">Your bio (max 400 char)</label>
                    <Field
                      className="form_group__field"
                      name="bio"
                      as="textarea"
                      placeholder={props.user?.bio || 'Write a short bio'}
                      disabled={isSubmitting || success}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="form_group">
                    <label className="form_group__label">Location</label>
                    <Field
                      className="form_group__field"
                      name="location"
                      type="text"
                      placeholder={props.user?.location || 'New York, NY'}
                      disabled={isSubmitting || success}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="form_group">
                    <label className="form_group__label">Handles (optional)</label>
                    <Field
                      className="form_group__field"
                      name="website_url"
                      type="text"
                      placeholder={props.user?.website_url || 'Enter website url'}
                      disabled={isSubmitting || success}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="form_row form_row--grid_mod">
                    <div className="form_group form_group--icon_mod">
                      <div className="form_group__icon">
                        <LinkedinIcon className="form_group__icon_in" />
                      </div>
                      <Field
                        className="form_group__field"
                        name="linkedin_usr"
                        type="text"
                        placeholder={props.user?.linkedin_usr || 'Enter LinkedIn'}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form_group form_group--icon_mod">
                      <div className="form_group__icon">
                        <InstagramIcon className="form_group__icon_in" />
                      </div>
                      <Field
                        className="form_group__field"
                        name="insta_usr"
                        type="text"
                        placeholder={props.user?.insta_usr || 'Enter Instagram'}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form_group form_group--icon_mod">
                      <div className="form_group__icon">
                        <FacebookIcon className="form_group__icon_in" />
                      </div>
                      <Field
                        className="form_group__field"
                        name="facebook_usr"
                        type="text"
                        placeholder={props.user?.facebook_usr || 'Enter Facebook'}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form_group form_group--icon_mod">
                      <div className="form_group__icon">
                        <TwitterIcon className="form_group__icon_in" />
                      </div>
                      <Field
                        className="form_group__field"
                        name="twitter_usr"
                        type="text"
                        placeholder={props.user?.twitter_usr || 'Enter Twitter'}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form_group form_group--icon_mod">
                      <div className="form_group__icon">
                        <YoutubeIcon className="form_group__icon_in" />
                      </div>
                      <Field
                        className="form_group__field"
                        name="youtube"
                        type="text"
                        placeholder={props.user?.youtube || 'Enter Youtube Channel'}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form_group form_group--icon_mod">
                      <div className="form_group__icon">
                        <TikTokIcon className="form_group__icon_in" />
                      </div>
                      <Field
                        className="form_group__field"
                        name="tiktok_usr"
                        type="text"
                        placeholder={props.user?.tiktok_usr || 'Enter TikTok'}
                        disabled={isSubmitting || success}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  {isSubmitting ? (
                    <Spinner className="form_group__button spinner_mod" button={true} />
                  ) : (
                    <div className="form_group">
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault(e)
                          submitForm()
                        }}
                        className="form_group__button"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  <button
                    onClick={(e) =>
                      authApi
                        .logOut()
                        .then((history.push('/'), window.localStorage.clear()))
                    }
                    className="form_group__button share_mod"
                  >
                    Log Out
                  </button>
                  <br />
                  <br />
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({ auth: { user, error, isPro } }) => ({
  authError: error,
  user,
  isPro,
})

const willMapDispatchToProps = {
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(ProfileForm)
