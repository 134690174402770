import React, { useContext, useRef } from 'react'
import { UiContext } from 'context/ui'

import styles from './CallVideoPopup.module.scss'
import { useClickOutside } from 'hooks'
import Popup from '../Popup/Popup'

const CallVideoPopup = ({ isOpen, videoLink }) => {
  const { setActivePopupId } = useContext(UiContext)
  const $container = useRef()

  const handleClose = () => {
    setActivePopupId(null)
  }

  useClickOutside($container, handleClose)

  return (
    <Popup isOpen={isOpen} type="v5">
      <video
        className={styles.video}
        playsInline
        muted
        autoPlay
        controls
        controlsList="nodownload"
      >
        <source src={videoLink} type="video/mp4" />
      </video>
    </Popup>
  )
}

export default CallVideoPopup
