import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { authApi, profileApi, callApi } from 'api'

import { setIsPro, logIn } from '../../store/actions'
import redirectOnLogin from 'utils/redirectOnLogin'

const PRO_USER_SCOPE =
  'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly'
const NOT_PRO_USER_SCOPE =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid'

const Login = (props) => {
  const history = useHistory()

  const responseGoogle = (res) => {
    const avi = res?.profileObj?.imageUrl
    props.isPro
      ? authApi.getGoogleContacts(res?.tokenObj?.access_token).then((res) => {
          let contacts = res?.filter((e) => e.emailAddresses)
          window.localStorage.setItem('contacts', JSON.stringify(contacts))
        }) &&
        authApi
          .googleLogInPro({ access_token: res?.tokenObj?.access_token })
          .then(async (res) => {
            if (res?.result?.access_token) {
              props.setIsPro(true)
              localStorage.setItem('token', res?.result.access_token)
              localStorage.setItem('refreshToken', res?.result.refresh_token)
              localStorage.setItem('isPro', true)
              profileApi.setProInfo({
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
              await profileApi
                .getProfileInfo()
                .then((res) => props.logIn(res?.result?.profile))
              history.push('/dashboard/pro')
            }
          })
      : authApi
          .googleLogInUser({ access_token: res?.tokenObj?.access_token })
          .then(async (res) => {
            if (res?.result?.access_token) {
              props.setIsPro(false)
              localStorage.setItem('token', res?.result.access_token)
              localStorage.setItem('refreshToken', res?.result.refresh_token)
              localStorage.setItem('isPro', false)
              profileApi.setUserInfo({
                photo: avi,
              })
              await profileApi
                .getUserInfo()
                .then((res) => props.logIn(res?.result?.profile))
              props.data?.callDetails || props.data?.event
                ? callApi.sendCallRequest(props.data.callRequest).then((res) =>
                    res?.result?.redirect
                      ? history.push('/user/callback', res?.result?.call_info)
                      : history.push('/user/payments', {
                          ...props.data,
                          call_id: res?.result?.call_info,
                        })
                  )
                : redirectOnLogin(history)
            }
          })
  }

  return (
    <GoogleLogin
      clientId={
        process.env.REACT_APP_GOOGLE_ID ||
        `762923432718-9ifhiu7igag6c2902uh9nejfo28uuc69.apps.googleusercontent.com`
      }
      buttonText={props.buttonText || 'Sign up with Google'}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
      scope={props.isPro ? PRO_USER_SCOPE : NOT_PRO_USER_SCOPE}
      icon={false}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          style={{
            backgroundColor: 'none',
            color: '#1a1c1d',
            cursor: `pointer`,
            textDecoration: `none`,
            fontSize: '1.6rem',
            fontWeight: '700',
            minWidth: '100%',
            height: '100%',
            borderRadius: '',
            position: 'absolute',
            top: '0',
            left: '0',
          }}
        >
          {props.buttonText || ''}
        </button>
      )}
    />
  )
}

const mapStateToProps = ({ auth: { error } }) => ({
  authError: error,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(Login)
