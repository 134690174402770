import React from 'react'

import styles from './Checkbox.module.scss'

const Checkbox = ({ id, name, title, value, onChange, defaultChecked }) => {
  return (
    <div className={styles.root}>
      <input
        className={styles.checkbox}
        id={id}
        type="checkbox"
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
      <label className={styles.checkboxLabel} htmlFor={id}>
        <span className={styles.title}>{title}</span>
      </label>
    </div>
  )
}

export default Checkbox
