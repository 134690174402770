const redirectOnLogin = (history) => {
  const params = new URLSearchParams(new URL(window.location).search)
  const callType = params.get('callType')
  const callId = params.get('callId')

  if (callId && callType) {
    history.push(`/summary/${callType}/${callId}`)
  } else {
    history.push('/dashboard/user')
  }
}

export default redirectOnLogin
