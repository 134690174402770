import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import AppleLogin from 'react-apple-login'

import Auth from 'components/Auth/Auth'
import { FormCheckBox } from 'components/Form'
import { Logo } from 'components/Header'

import { ReactComponent as MailIcon } from 'i/icons/mail.svg'
import JoinHeroImg from 'i/pro_join_hero_img.jpg'

import { setIsPro, logIn } from 'store/actions'

import { authApi, callApi, profileApi } from 'api'

const JoinPage = (props) => {
  const [checked, setChecked] = useState(true)
  const history = useHistory()
  const request = props?.location?.state
  const [error, setError] = useState()

  useEffect(() => {
    document.title = 'TalkPro | Join'
  }, [])

  useEffect(() => {
    let code = [...new URLSearchParams(window.location.search)]
      .find(([key]) => key === 'code')?.[1]
      .toString()

    if (code) {
      authApi.appleLogInUser({ code: code }).then(async (res) => {
        props.setIsPro(false)
        localStorage.setItem('token', res?.result?.access_token)
        localStorage.setItem('refreshToken', res?.result?.refresh_token)
        localStorage.setItem('isPro', false)
        await profileApi.getUserInfo().then((res) => props.logIn(res.result.profile))
        props.data?.callRequest
          ? props.data.event
            ? history.push('/user/payments', props.data)
            : callApi.sendCallRequest(props.data.callRequest).then((res) =>
                res?.result?.redirect
                  ? history.push('/user/callback', res?.result?.call_info)
                  : history.push('/user/payments', {
                      ...props.data,
                      call_id: res?.result?.call_info,
                    })
              )
          : history.push('/dashboard/user')
      })
    }
  }, [])

  return (
    <section className="section pro_join_mod full_mod_v">
      <Logo proJoinPageMod />
      <div className="section_row flex_mod">
        <div className="section_col image_mod">
          <div className="section_w_img full_mod">
            <img className="cover_img" src={JoinHeroImg} alt="pro join" />
          </div>
        </div>
        <div className="section_col login_mod col_mod">
          <div className="section_descr mobile_margin_mod_v2 pro_login_mod">
            <span>
              Already have an account? &nbsp; &nbsp;
              <button
                onClick={(e) => history.push('/user/login', request)}
                className="btn_v4"
              >
                Login
              </button>
            </span>
          </div>
          <br />
          <div className="section_col signup_mod col_mod">
            <h2 className="section_title center_lvl_mod offset_3_mod">
              Welcome to talkpro
              <br /> Sign Up to get started.
            </h2>
            {props.location?.state?.pro && (
              <div className="section_descr center_lvl_mod offset_v2_mod">
                Please enter your details to confirm your talk with{' '}
                {props.location.state.pro.user.name}
              </div>
            )}
            <Formik
              initialValues={{
                email: '',
                password1: '',
                password2: '',
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true)
                await authApi
                  .registerUser({
                    email: values.email,
                    password1: values.password1,
                    password2: values.password2,
                  })
                  .then(async (res) => {
                    if (res?.result?.access_token) {
                      props.setIsPro(false)
                      localStorage.setItem('token', res?.result?.access_token)
                      localStorage.setItem('refreshToken', res?.result?.refresh_token)
                      localStorage.setItem('isPro', false)
                      await profileApi
                        .getUserInfo()
                        .then((res) => props.logIn(res.result.profile))
                      await profileApi.setUserInfo({ name: values?.email })
                      props?.location?.state?.callRequest
                        ? callApi.sendCallRequest(props?.location?.state?.callRequest)(
                            (res) =>
                              res?.result?.redirect
                                ? history.push(
                                    '/user/callback',
                                    props.data,
                                    res?.result?.call_info
                                  )
                                : history.push('/user/payments', {
                                    ...props.data,
                                    call_id: res?.result?.call_info,
                                  })
                          )
                        : (window.location.pathname = '/dashboard/user')
                    } else {
                      setError(res.error.data)
                    }
                  })

                setSubmitting(false)
              }}
            >
              {({ errors, touched, submitForm, isSubmitting, handleChange }) => (
                <Form className="user_join_form">
                  {error && (
                    <div className="form_error">
                      <ul>
                        {Object.keys(error).map(function (key, index) {
                          return (
                            <li>
                              {String.fromCharCode('0x2022')}
                              {error[key]}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                  <div className="pro_join_block_form_w_input">
                    <div className="form_field profile_mod_v2 icon_mod">
                      <label className="form_field_label" htmlFor="email">
                        Email
                      </label>
                      <Field
                        className="form_field_input color_mod height_mod"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="johnsmith@email.com"
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="profile_form_icon_w v2_mod pos_mod">
                        <MailIcon className="icon icon-mail size_mod" />
                      </div>
                    </div>
                    <div className="form_field profile_mod_v2 icon_mod">
                      <label className="form_field_label" htmlFor="password1">
                        Password
                      </label>
                      <Field
                        className="form_field_input color_mod password_letter_spacing height_mod"
                        id="password1"
                        type="password"
                        name="password1"
                        placeholder={`${String.fromCharCode('0x2022').repeat(8)}`}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form_field profile_mod_v2 icon_mod margin_mod">
                      <label className="form_field_label" htmlFor="password2">
                        Confirm Password
                      </label>
                      <Field
                        className="form_field_input color_mod password_letter_spacing height_mod"
                        id="password2"
                        type="password"
                        name="password2"
                        placeholder={`${String.fromCharCode('0x2022').repeat(8)}`}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form_span_wrap_v2">
                    <FormCheckBox
                      id="terms"
                      name="terms"
                      value={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <span className="form_term_conditions">
                      I agree with{' '}
                      <a href="http://help.talkpro.com/en/articles/5527110-terms-and-conditions-of-service">
                        terms & conditions
                      </a>
                    </span>
                  </div>
                  <button
                    className="btn_v6"
                    button
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Sign Up
                  </button>
                </Form>
              )}
            </Formik>
            <div className="section_descr center_decor_mod offset_v5_mod">
              <span>or</span>
            </div>
            <AppleLogin
              clientId="com.talkpro.login"
              redirectURI="https://www.talkpro.com/user/join"
              scope={['email, name']}
            />
            <Auth check={checked} data={props.location.state} />

            {/* <div className="btn_wrap offset_3_mod">
          <div className="btn_v3 user_join_mod">
            <div className="user_join_btn_icon_w">
              <FacebookIcon className="icon size_mod" />
            </div>
            Sign Up with Facebook
          </div>
        </div>
        <div className="btn_wrap offset_3_mod">
          <div className="btn_v3 user_join_mod">
            <div className="user_join_btn_icon_w">
              <TwitterIcon className="icon size_mod" />
            </div>
            Sign Up with Twitter
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { error, attributes } }) => ({
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(JoinPage)
