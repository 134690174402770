import React from 'react'

import { ReactComponent as LogoImage } from 'i/logo.svg'

import styles from './Logo.module.scss'
import { Link } from 'react-router-dom'
import { cva } from 'class-variance-authority'

const buttonStyles = cva(styles.logo, {
  variants: {
    variant: {
      primary: styles.logoPrimaryVariant,
      secondary: styles.logoSecondaryVariant,
    },
    size: {
      base: styles.logoBaseSize,
      lg: styles.logoLgSize,
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

const Logo = ({ href, variant, size }) => {
  return (
    <Link className={buttonStyles({ variant, size })} to={href}>
      <LogoImage className={styles.image} />
    </Link>
  )
}

export default Logo
