import { apiRequest } from '../apiRequest'
import {
  getCalls,
  getProRequests,
  confirmProCall,
  getProPersonalCalls,
  getProGroupCalls,
  sendInvite,
  sendInviteToGroup,
  getContacts,
  saveContacts,
  getCallTranscription,
  shareMessage,
  huddleTalk,
} from './Pro'

import {
  getUserCalls,
  getUserRequests,
  confirmUserCall,
  sendCallRequest,
  getUserPersonalCalls,
  JoinGuestCall,
} from './User'

const startCall = (data) => apiRequest(`/call/${data.id}/start`).post(data)

const cancelCall = (data) => apiRequest(`/call/${data.id}/cancel`).post(data)

const endCall = (data) => apiRequest(`/call/${data.id}/end`).post(data)

const startGroupCall = (data) => apiRequest(`/event/${data.id}/start`).post(data)

const cancellGroupCall = (data) => apiRequest(`/event/${data.id}/cancel`).post(data)

const endGroupCall = (data) => apiRequest(`/event/${data.id}/end`).post(data)

const saveGroupTranscription = (data) =>
  apiRequest(`/event/${data.id}/save-transcript`).post(data)

const saveTranscription = (data) =>
  apiRequest(`/call/${data.id}/save-transcript`).post(data)

export const getCallRecord = (callType, callId) =>
  apiRequest(`/call/record/${callType}/${callId}/`).get()

export const getCallSummary = (callType, callId) =>
  apiRequest(`/call/summary/${callType}/${callId}/`).get()

export const postCallSummary = (callType, callId, data) =>
  apiRequest(`/call/summary/${callType}/${callId}/`).post(data)

export const getActiveInstantCall = (username) =>
  apiRequest(`/active-instant-call/${username}/`).get()

const shareSummary = (data, id) =>
  apiRequest(`pro/share-meeting-notes-instant/${id}/`).post(data)

export const callApi = {
  getProRequests,
  sendCallRequest,
  confirmProCall,
  confirmUserCall,
  getCalls,
  getUserCalls,
  getUserRequests,
  startCall,
  cancelCall,
  endCall,
  saveTranscription,
  getProPersonalCalls,
  getProGroupCalls,
  getUserPersonalCalls,
  sendInvite,
  sendInviteToGroup,
  getCallTranscription,
  shareMessage,
  startGroupCall,
  cancellGroupCall,
  endGroupCall,
  saveGroupTranscription,
  getContacts,
  saveContacts,
  JoinGuestCall,
  getCallRecord,
  getCallSummary,
  huddleTalk,
  getActiveInstantCall,
  shareSummary,
}
