import React from 'react'
import classNames from 'classnames'

const SectionHeading = ({ title, descr, offsetV2Mod, offsetV3Mod }) => {
  const headingClasses = classNames('section_heading', {
    offset_v2_mod: offsetV2Mod,
    offset_v3_mod: offsetV3Mod,
  })

  return (
    <div className={headingClasses}>
      <h2 className="section_title offset_mod">{title}</h2>
      <div className="section_descr">{descr}</div>
    </div>
  )
}

export default SectionHeading
