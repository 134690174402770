import React from 'react'

import styles from './SubscriptionCard.module.scss'

export function SubscriptionCard({ action, price, renewal }) {
  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <div className={styles.detail}>Paid {price}</div>
        <div className={styles.detail}>Renewal {renewal}</div>
        <div className={styles.action}>{action}</div>
      </div>
    </div>
  )
}

export default SubscriptionCard
