import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'

import { authApi, callApi, profileApi } from 'api'
import { ReactComponent as GoogleIcon } from 'i/icons/brands/google.svg'
import { ReactComponent as Google2Icon } from 'i/icons/brands/google_2.svg'

import { setIsPro, logIn } from '../../store/actions'

const Auth = (props) => {
  const history = useHistory()
  const responseGoogle = (res) => {
    const name = res?.profileObj?.name
    const avi = res?.profileObj?.imageUrl
    props.isPro
      ? authApi.getGoogleContacts(res?.tokenObj?.access_token).then((res) => {
          let contacts = res?.filter((e) => e.emailAddresses)
          window.localStorage.setItem('contacts', contacts)
        }) &&
        authApi
          .googleLogInPro({ access_token: res?.tokenObj?.access_token })
          .then(async (res) => {
            if (res?.result?.access_token) {
              props.setIsPro(true)
              localStorage.setItem('token', res?.result?.access_token)
              localStorage.setItem('refreshToken', res?.result?.refresh_token)
              localStorage.setItem('isPro', true)
              profileApi.setProInfo({
                channel_name: props.channelName
                  ?.toString()
                  .split('')
                  .filter((e) => e !== ' ')
                  .join(''),
                name: props.channelName?.toString(),
                photo: avi,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
              await profileApi
                .getProfileInfo()
                .then((res) => props.logIn(res.result.profile))
              history.push('/pro/category')
            }
          })
      : authApi
          .googleLogInUser({ access_token: res?.tokenObj?.access_token })
          .then(async (res) => {
            if (res?.result?.access_token) {
              props.setIsPro(false)
              localStorage.setItem('token', res?.result?.access_token)
              localStorage.setItem('refreshToken', res?.result?.refresh_token)
              localStorage.setItem('isPro', false)
              profileApi.setUserInfo({
                photo: avi,
                name: name,
              })
              await profileApi
                .getUserInfo()
                .then((res) => props.logIn(res.result.profile))
              props.data.callRequest
                ? props.data.event
                  ? history.push('/user/payments', props.data)
                  : callApi.sendCallRequest(props.data.callRequest).then((res) =>
                      history.push('/user/payments', {
                        ...props.data,
                        call_id: res?.result?.call_info,
                      })
                    )
                : history.push('/dashboard/user')
            }
          })
  }
  return (
    <div
      className={`${
        props.isPro
          ? 'btn_base_green position_relative_mod'
          : 'btn_v3 join_page_mod btn_wrap offset_3_mod'
      }`}
    >
      <div
        className={`${
          props.isPro ? 'icon_w btn_base_mod position_mod_v3' : 'user_join_btn_icon_w'
        }`}
      >
        {props.isPro ? (
          <GoogleIcon className="icon icon-google btn_base_mod size_mod" />
        ) : (
          <Google2Icon className="icon icon-google btn_base_mod size_mod" />
        )}
      </div>
      <GoogleLogin
        clientId={
          process.env.REACT_APP_GOOGLE_ID ||
          '762923432718-9ifhiu7igag6c2902uh9nejfo28uuc69.apps.googleusercontent.com'
        }
        buttonText="Sign Up with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly"
        prompt="consent"
        icon={false}
        accessType="offline"
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            style={{
              color: `${props.isPro ? 'white' : '#1a1c1d'}`,
              cursor: `pointer`,
              textDecoration: `none`,
              fontSize: `${props.isPro ? 'auto' : '1.5rem'}`,
              fontWeight: `${props.isPro ? 'auto' : '700'}`,
              minWidth: `${props.isPro ? '100%' : '75%'}`,
              height: `${props.isPro ? '100%' : '100%'}`,
              borderRadius: `${props.isPro ? '1rem' : ''}`,
            }}
          >
            Sign Up With Google
          </button>
        )}
      />
    </div>
  )
}

const mapStateToProps = ({ auth: { error, attributes } }) => ({
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(Auth)
