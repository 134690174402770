import React from 'react'
import { Link } from 'react-router-dom'

import styles from './BaseLink.module.scss'

const BaseLink = ({ href, children, ...props }) => {
  return (
    <Link className={styles.link} to={href} {...props}>
      {children}
    </Link>
  )
}

export default BaseLink
