import React from 'react'
import classNames from 'classnames'

import { ReactComponent as SearchIcon } from 'i/icons/search_2.svg'

const FormFieldSearch = ({
  id,
  labelText,
  type,
  name,
  placeholder,
  defaultValue,
  offsetMod,
}) => {
  const fieldClasses = classNames('form_field search_mod', {
    offset_mod: offsetMod,
  })

  return (
    <div className={fieldClasses}>
      <label className="form_field_label hline_hide_mod" htmlFor={id}>
        {labelText}
      </label>
      <input
        className="form_field_search"
        id="enter_field"
        type={type}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      <button className="form_field_icon search_mod">
        <SearchIcon className="icon size_mod current_mod" />
      </button>
    </div>
  )
}

export default FormFieldSearch
