import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { connect } from 'react-redux'

import animationData from 'i/lottie/Scene9.json'

import { paymentApi } from 'api'
import { useHistory } from 'react-router-dom'

const SharePage = (props) => {
  const [copied, setCopied] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const user = props.user
  const history = useHistory()

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    window.location.search &&
      paymentApi.registerSubscription({
        subscription_id: [...new URLSearchParams(window.location.search)]
          .find(([key]) => key === 'session_id')?.[1]
          .toString(),
      })
    history.push('/dashboard/pro')
  }, [])
  const userLink = `talkpro.com/${user?.channel_name}`

  return (
    <section className="section default_mod_v2 center_mod">
      <div className="section_in v3_mod mobile_max_width">
        <div className="pro_lottie_wrap margin_mod">
          <Lottie
            options={lottieOptions}
            height={windowWidth < 768 ? 300 : 400}
            width={windowWidth < 768 ? 300 : 400}
          />
        </div>
        <h2 className="section_title center_lvl_mod offset_mod">Invite people to Talk</h2>
        <div className="section_descr center_lvl_mod offset_mod">
          This is your link to share anywhere you want people to book talks
        </div>
        <a className="section_link v2_mod" href={userLink}>
          {userLink}
        </a>
        {!!copied && <div className="alert">Link copied to clipboard</div>}
        <div className="btn_wrap center_mod offset_mod">
          <button
            onClick={() => {
              navigator.clipboard.writeText(userLink)
              setCopied(true)
            }}
            className="btn_base"
          >
            Copy link
          </button>
        </div>
        {/* <div className="btn_wrap center_mod offset_2_mod">
          <Link className="btn_v3" to="/dashboard/pro">
            Go to account
          </Link>
        </div> */}
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes } }) => ({
  user,
  authError: error,
  attributes,
})

export default connect(mapStateToProps)(SharePage)
