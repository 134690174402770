const getTimeZoneOffset = (timeZone1, timeZone2) => {
  const now = new Date()
  const date1 = new Date(now.toLocaleString('en-US', { timeZone: timeZone1 }))
  const date2 = new Date(now.toLocaleString('en-US', { timeZone: timeZone2 }))
  const offset = date2 - date1
  const offsetHours = offset / 3600000

  return offsetHours
}

const applyTimeZoneDifference = (date, offset) => {
  const adjustedDate = new Date(date)
  adjustedDate.setHours(adjustedDate.getHours() + offset)
  return adjustedDate
}

const formatDateTime = (date, options) => {
  return date.toLocaleString('en-US', options)
}

const calculateFormattedDateTime = (dateTimeString, fromTimeZone, toTimeZone) => {
  const date = new Date(dateTimeString)
  const offset = getTimeZoneOffset(fromTimeZone, toTimeZone)
  const adjustedDate = applyTimeZoneDifference(date, offset)

  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const formattedDate = formatDateTime(adjustedDate, options)

  console.log(formattedDate)

  return formattedDate
}

export default calculateFormattedDateTime
