import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { UiContext } from 'context/ui'

import { ServiceCheckbox, FormCustomSelect, FormFile } from 'components/Form'
import { AddVideoPopup, Popup } from 'components/Popups'
import { CreateClassForm } from 'components/CreateClassForm'
import { AvailabilityForm } from 'components/AvailabilityForm'
import { AlertMessage } from 'components/AlertMessage'
import { BaseNavigation } from 'components/ui/BaseNavgiation'
import { SERVICE_NAVIGATION } from 'shared/constants'
import { AuthRow } from 'components/ui/AuthRow'
import { AuthCol } from 'components/ui/AuthCol'
import { Image } from 'components/ui/Image'

import LogoImg from 'i/talkpro_logo.png'
import SignImg from 'i/signup_img.jpg'
import { ReactComponent as FacebookIcon } from 'i/icons/social_media/facebook2.svg'
import { ReactComponent as InstagramIcon } from 'i/icons/social_media/instagram2.svg'
import { ReactComponent as LinkedinIcon } from 'i/icons/social_media/linkedin2.svg'
import { ReactComponent as TwitterIcon } from 'i/icons/social_media/twitter2.svg'
import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'

import { profileApi } from 'api'

import APP_DATA from 'utils/appData'
import {
  POPUPS_ID,
  liveClassesServiceName,
  oneOnOneServiceName,
  addVideoServiceName,
  timezoneOptions,
} from 'utils/constants'
import { clearConfigCache } from 'prettier'
import FormInputWithLabel from 'components/Form/FormInputWithLabel/FormInputWithLabel'

const validate = (values) => {
  const errors = {}

  const validateServiceType = (fieldName, errorMessage = 'This field is required...') => {
    if (typeof values[fieldName] === 'boolean' && values[fieldName]) {
      errors[fieldName] = errorMessage
    }
  }

  validateServiceType(liveClassesServiceName, 'You must complete the Live Class Form')
  validateServiceType(oneOnOneServiceName, 'You must complete the 1 on 1 form')

  if (values[liveClassesServiceName] === false && values[oneOnOneServiceName] === false) {
    errors[oneOnOneServiceName] = 'You must choose one of the service'
  }

  return errors
}

const RateBpage = (props) => {
  const { activePopupId, setActivePopupId } = useContext(UiContext)
  const [success, setSuccess] = useState(false)
  const [timezone, setTimezone] = useState({ name: 'America/New_York', tag: 'en-US' })

  const history = useHistory()

  useEffect(() => {
    document.title = 'TalkPro | Join'
  }, [])

  const handleRadioButtonChange = (name, setFieldValue, values) => {
    const isOneOnOne = name === oneOnOneServiceName
    const targetPopupId = isOneOnOne ? POPUPS_ID.availability : POPUPS_ID.createClass

    if (values[name]) {
      setActivePopupId(null)
      setFieldValue(name, false)
    } else {
      setActivePopupId(targetPopupId)
      if (isOneOnOne) {
        if (values.one_on_one_service) {
          setFieldValue(name, true)
        }
      } else {
        setFieldValue(name, true)
      }
    }
  }

  const closePopup = () => {
    setActivePopupId(null)
  }

  return (
    <AuthRow>
      <AuthCol type="gradient" />
      <AuthCol>
        <div className="signup__col_in">
          <div className="signup__logo_w">
            <a className="signup__logo" href="/">
              <img className="signup__logo_in" src={LogoImg} alt="Logo" />
            </a>
          </div>
          <div className="signup__info signup__info--offset_2_mod">
            <h1 className="signup__title">Personalise Account</h1>
            <div className="signup__subtitle">
              talkpro.com/
              {props?.location?.state?.channelName ||
                props?.user?.channel_name ||
                'xxxxxxx'}
            </div>
            <div className="signup__descr">
              Tell your audience about yourself and URLs where they can leaarn more
            </div>
          </div>

          <div className="signup__tabs">
            {/* <div className="signup__tabs_head">
              <div className="signup__tabs_item">
                <div className="signup__tabs_link signup__tabs_link--active">
                  Rate and Profile
                </div>
              </div>
              <div className="signup__tabs_item">
                <div className="signup__tabs_link">Payout Sync</div>
              </div>
            </div> */}
            <Formik
              validate={validate}
              initialValues={{
                [oneOnOneServiceName]: false,
                [liveClassesServiceName]: false,
                [addVideoServiceName]: false,
                rate: `${props.user?.rate ? props.user?.rate : ''}`,
                location: `${props.user?.location ? props.user?.location : ''}`,
                // Need to review
                language: `${
                  props.user?.language ? props.user?.language?.code?.toLowerCase() : 'en'
                }`,
                website: `${props.user?.youtube ? props.user?.youtube : ''}`,
                expectations: `${
                  props.user?.expectations ? props.user?.expectations : ''
                }`,
                bio: `${props.user?.bio ? props.user?.bio : ''}`,
                name: `${props.user?.user?.name ? props.user?.user?.name : ''}`,
                website_url: `${props.user?.website_url ? props.user?.website_url : ''}`,
                linkedin_usr: `${
                  props.user?.linkedin_usr ? props.user?.linkedin_usr : ''
                }`,
                insta_usr: `${props.user?.insta_usr ? props.user?.insta_usr : ''}`,
                facebook_usr: `${
                  props.user?.facebook_usr ? props.user?.facebook_usr : ''
                }`,
                twitter_usr: `${props.user?.twitter_usr || ''}`,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const data = {
                  [oneOnOneServiceName]: JSON.stringify(values[oneOnOneServiceName]),
                  [liveClassesServiceName]: JSON.stringify(
                    values[liveClassesServiceName]
                  ),
                  video_urls: JSON.stringify(values[addVideoServiceName]),
                  rate: values.rate,
                  language: props.user?.language
                    ? props.user?.language?.code?.toLowerCase()
                    : 'en',
                  location: values.location,
                  category_name:
                    props.location?.state?.category || props.user?.category || '',
                  expectations: values.expectations,
                  bio: values.bio,
                  name: values.name,
                  website_url: values.website_url,
                  linkedin_usr: values.linkedin_usr,
                  insta_usr: values.insta_usr,
                  facebook_usr: values.facebook_usr,
                  twitter_usr: values.twitter_usr,
                  timezone: timezone.name,
                }

                Object.keys(data).forEach((key) => {
                  if (data[key] === '') {
                    delete data[key]
                  }
                })

                await profileApi.setProInfo(data)

                setSuccess(true)

                setSubmitting(false)

                history.push('/pro/stripe', values.rate)
              }}
            >
              {({
                errors,
                touched,
                submitForm,
                isSubmitting,
                handleChange,
                setFieldValue,
                values,
              }) => (
                <>
                  <div className="signup__tabs_content signup__tabs_content--no_offset_mod">
                    <div className="signup__tabs_content_item signup__tabs_content_item--active">
                      <div className="signup__form">
                        <Form className="signup__form_in">
                          {/* <div className="form_row form_row form_row--grid_mod">
                            <div className="form_group">
                              <ServiceCheckbox
                                id="on_on_one"
                                title="1 on 1"
                                name={oneOnOneServiceName}
                                disabled={isSubmitting || success}
                                onChange={(e) =>
                                  handleRadioButtonChange(
                                    oneOnOneServiceName,
                                    setFieldValue,
                                    values
                                  )
                                }
                              />
                            </div>
                            <div className="form_group">
                              <ServiceCheckbox
                                id="live_classes"
                                title="Live Classes"
                                name={liveClassesServiceName}
                                disabled={isSubmitting || success}
                                onChange={(e) =>
                                  handleRadioButtonChange(
                                    liveClassesServiceName,
                                    setFieldValue,
                                    values
                                  )
                                }
                              />
                            </div>
                            <FormCustomSelect
                              options={timezoneOptions}
                              onChange={(e) => {
                                setTimezone(e.value)
                              }}
                            />
                          </div> */}
                          <div className="form_group form_group--offset_v2_mod">
                            <FormInputWithLabel
                              type="text"
                              label="Write a short bio"
                              textarea
                            >
                              <Field
                                name="expectations"
                                as="textarea"
                                className="form_group__field"
                                placeholder=""
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                            </FormInputWithLabel>
                          </div>
                          <div className="form_group form_group--offset_mod">
                            <FormFile
                              text="Upload Avatar"
                              userIcon
                              v2
                              onChange={() => {
                                console.log('img uploaded')
                              }}
                            />
                          </div>
                          <div className="form_group form_group--offset_v2_mod">
                            <div className="form_group__divider">
                              <div className="form_group__divider_in">
                                Handles (optional)
                              </div>
                            </div>
                            <FormInputWithLabel
                              type="text"
                              label={props.user?.website_url || 'Enter website url'}
                            >
                              <Field
                                className="form_group__field"
                                name="website_url"
                                type="text"
                                disabled={isSubmitting || success}
                                onChange={(e) => handleChange(e)}
                              />
                            </FormInputWithLabel>
                          </div>
                          <div className="form_row form_row--grid_mod form_row--no_offset_mod">
                            <div className="form_group form_group--icon_mod">
                              <FormInputWithLabel
                                type="text"
                                label={props.user?.linkedin_usr || 'Enter LinkedIn'}
                                icon={<LinkedinIcon />}
                                iconPosition
                              >
                                <Field
                                  className="form_group__field"
                                  name="linkedin_usr"
                                  type="text"
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </FormInputWithLabel>
                            </div>
                            <div className="form_group form_group--icon_mod">
                              <FormInputWithLabel
                                type="text"
                                label={props.user?.insta_usr || 'Enter Instagram'}
                                icon={<InstagramIcon />}
                                iconPosition
                              >
                                <Field
                                  className="form_group__field"
                                  name="insta_usr"
                                  type="text"
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </FormInputWithLabel>
                            </div>
                            <div className="form_group form_group--icon_mod">
                              <FormInputWithLabel
                                type="text"
                                label={props.user?.facebook_usr || 'Enter Facebook'}
                                icon={<FacebookIcon />}
                                iconPosition
                              >
                                <Field
                                  className="form_group__field"
                                  name="facebook_usr"
                                  type="text"
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </FormInputWithLabel>
                            </div>
                            <div className="form_group form_group--icon_mod">
                              <FormInputWithLabel
                                type="text"
                                label={props.user?.twitter_usr || 'Enter Twitter'}
                                icon={<TwitterIcon />}
                                iconPosition
                              >
                                <Field
                                  className="form_group__field"
                                  name="twitter_usr"
                                  type="text"
                                  disabled={isSubmitting || success}
                                  onChange={(e) => handleChange(e)}
                                />
                              </FormInputWithLabel>
                            </div>
                          </div>
                          <ErrorMessage name={oneOnOneServiceName}>
                            {(msg) => <AlertMessage message={msg} formMod />}
                          </ErrorMessage>
                          <ErrorMessage name={liveClassesServiceName}>
                            {(msg) => <AlertMessage message={msg} formMod />}
                          </ErrorMessage>
                          <div className="form_group">
                            <div className="form_group__divider">
                              <div className="form_group__divider_in">
                                Videos (optional)
                              </div>
                            </div>
                            <button
                              type="button"
                              className="form_group__field form_group__field--button_mod"
                              name="video_urls"
                              title="Add Video"
                              onClick={() => setActivePopupId(POPUPS_ID.addVideo)}
                            >
                              <div class="form_group__button_icon">
                                <YoutubeIcon />
                              </div>
                              Select Youtube Videos
                            </button>
                          </div>
                          <div className="form_group">
                            <button
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault()
                                if (!values.one_on_one_service) {
                                  handleRadioButtonChange(
                                    oneOnOneServiceName,
                                    setFieldValue,
                                    values
                                  )
                                } else {
                                  submitForm()
                                }
                              }}
                              className="form_group__button"
                            >
                              Select your availability
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <Popup
                    isOpen={activePopupId === POPUPS_ID.createClass}
                    title={APP_DATA.popups.createClass.title}
                    description={APP_DATA.popups.createClass.description}
                    centerContent
                  >
                    <CreateClassForm
                      onSignup={true}
                      setFieldValue={setFieldValue}
                      onSubmitCallback={closePopup}
                    />
                  </Popup>
                  <Popup
                    type="border"
                    isOpen={activePopupId === POPUPS_ID.availability}
                    title={APP_DATA.popups.availabilityRatePage.title}
                    description={APP_DATA.popups.availabilityRatePage.description}
                    centerContent
                  >
                    <AvailabilityForm
                      setFieldValue={setFieldValue}
                      setTimezone={setTimezone}
                      onSubmitCallback={() => {
                        closePopup()
                        submitForm()
                      }}
                    />
                  </Popup>
                  <AddVideoPopup
                    title="YouTube Sync"
                    description="Choose videos to display on booking page"
                    isOpen={activePopupId === POPUPS_ID.addVideo}
                    setFieldValue={setFieldValue}
                    onSubmitCallback={closePopup}
                    user={props?.user}
                  />
                </>
              )}
            </Formik>
          </div>
        </div>
      </AuthCol>
    </AuthRow>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(RateBpage)
