import React from 'react'

import MailIcon from 'i/gmail.png'
import MailIcon2 from 'i/mail.png'

import styles from './WhyNeedAccount.module.scss'

const WhyNeedAccount = () => {
  return (
    <div className={styles.component}>
      <div className={styles.col}>
        <div className={styles.imageWrap}>
          <img className={styles.image} src={MailIcon} alt="google" />
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.imageWrap}>
          <img className={styles.image} src={MailIcon2} alt="email" />
        </div>
      </div>
      <div className={styles.col}>
        <h3 className={styles.title}>Why do I need Google?</h3>
        <div className={styles.descr}>
          TalkPro is connected to all Google services to sync your calendar, youtube and
          notifications with all of your devices.
        </div>
      </div>
    </div>
  )
}

export default WhyNeedAccount
