import React from 'react'
import { TimePicker } from 'components/TimePicker'

import { ReactComponent as BackArrowIcon } from 'i/icons/triangle_right.svg'
import { FooterProfile } from 'components/Footer'

const TimePickerPage = (props) => {
  let calendarData = props.location.state

  return (
    <section className="section time_select_section">
      <button className="time_select_section_back">
        <span className="time_select_section_back__icon">
          <BackArrowIcon className="icon size_mod" />
        </span>
        <span className="time_select_section_back__text">Back</span>
      </button>

      <div className="time_select_section__in">
        <TimePicker
          date={calendarData.calendarValue}
          pro={calendarData.pro}
          user={calendarData.user}
          disabledDates={calendarData.disabledDates}
        />
        <FooterProfile v2={true} />
      </div>
    </section>
  )
}

export default TimePickerPage
