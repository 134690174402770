import React from 'react'

import { ReactComponent as TikTokIcon } from 'i/icons/social_media/logo-tiktok.svg'
import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'
import { ReactComponent as LinkedInIcon } from 'i/icons/social_media/logo-linkedin.svg'
import { ReactComponent as InstagramIcon } from 'i/icons/social_media/logo-instagram.svg'
import { ReactComponent as FacebookIcon } from 'i/icons/social_media/logo-fb-simple.svg'
import { ReactComponent as TwitterIcon } from 'i/icons/social_media/logo-twitter.svg'

import sessionTypes from 'utils/sessionTypes.json'

function ProfileSideBar({ profile, eventEntryFee }) {
  if (!profile) return null

  const domain_from_url = (url) => {
    let result
    let match
    if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im))) {
      result = match[1]
      if ((match = result.match(/^[^.]+\.(.+\..+)$/))) {
        result = match[1]
      }
    }
    return result
  }

  const info = [
    { title: '30 minutes or more' },
    { title: 'End-to-end encryption' },
    // { title: 'Transcribed' },
    // { title: 'Synced with', icons: [GoogleIcon, AppleIcon] },
  ]

  return (
    <div className="profile_sidebar">
      <div className="profile_sidebar__head">
        <div className="profile_sidebar_img profile_sidebar__img">
          <img
            className="profile_sidebar_img__in"
            src={profile?.photo}
            alt={profile?.user?.name}
          />
        </div>
        <div className="profile_sidebar__head_text">
          <div className="profile_sidebar_name profile_sidebar__name">
            {profile?.user?.name}
          </div>
          <div className="profile_sidebar_profession profile_sidebar__profession">
            {profile?.category}
          </div>
        </div>
      </div>
      <div className="profile_sidebar_description profile_sidebar__description">
        {profile?.bio}
      </div>
      <div className="profile_sidebar_social profile_sidebar__social">
        <ul className="profile_sidebar_social__list">
          {profile?.linkedin_usr && (
            <li className="profile_sidebar_social__item" key={0}>
              <a
                className="profile_sidebar_social__item_link"
                href={`https://linkedin.com/${profile?.linkedin_usr}`}
              >
                <div className="profile_sidebar_social__item_icon">
                  <LinkedInIcon className="icon size_mod" />
                </div>
              </a>
            </li>
          )}
          {profile?.insta_usr && (
            <li className="profile_sidebar_social__item" key={1}>
              <a
                className="profile_sidebar_social__item_link"
                href={`https://instagram.com/${profile?.pro?.insta_usr}`}
              >
                <div className="profile_sidebar_social__item_icon">
                  <InstagramIcon className="icon size_mod" />
                </div>
              </a>
            </li>
          )}
          {profile?.facebook_usr && (
            <li className="profile_sidebar_social__item" key={2}>
              <a
                className="profile_sidebar_social__item_link"
                href={`https://facebook.com/${profile?.pro?.facebook_usr}`}
              >
                <div className="profile_sidebar_social__item_icon">
                  <FacebookIcon className="icon size_mod" />
                </div>
              </a>
            </li>
          )}
          {profile?.twitter_usr && (
            <li className="profile_sidebar_social__item" key={3}>
              <a
                className="profile_sidebar_social__item_link"
                href={`https://twitter.com/${profile?.twitter_usr}`}
              >
                <div className="profile_sidebar_social__item_icon">
                  <TwitterIcon className="icon size_mod" />
                </div>
              </a>
            </li>
          )}
          {profile?.youtube && (
            <li className="profile_sidebar_social__item" key={4}>
              <a
                className="profile_sidebar_social__item_link"
                href={`${profile?.youtube}`}
              >
                <div className="profile_sidebar_social__item_icon">
                  <YoutubeIcon className="icon size_mod" />
                </div>
              </a>
            </li>
          )}
          {profile?.tiktok_usr && (
            <li className="profile_sidebar_social__item" key={5}>
              <a
                className="profile_sidebar_social__item_link"
                href={`https://tiktok.com/${profile?.tiktok_usr}`}
              >
                <div className="profile_sidebar_social__item_icon">
                  <TikTokIcon className="icon size_mod" />
                </div>
              </a>
            </li>
          )}
          {profile?.website_url && (
            <li className="profile_sidebar_social__item" key={6}>
              <a
                className="profile_sidebar_social__item_link"
                href={`${profile?.pro?.website_url}`}
              >
                <div className="profile_sidebar_social__item_text">
                  {domain_from_url(profile?.website_url)}
                </div>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="profile_sidebar_salaries_wrap">
        {(parseInt(eventEntryFee) || parseInt(eventEntryFee)) > 0 && (
          <ul className="profile_sidebar_salaries profile_sidebar__salaries">
            <li className="profile_sidebar_salaries__item">
              <span className="profile_sidebar_salaries__item_money">
                ${parseInt(eventEntryFee).toFixed(0)}
                <span className="profile_sidebar_salaries__item_money_separator">/</span>
              </span>
              <span className="profile_sidebar_salaries__item_period">
                {sessionTypes[`${profile.category}`]}
              </span>
            </li>
          </ul>
        )}
        <ul className="profile_sidebar__info">
          {info.map(({ title, icons }, index) => {
            return (
              <div className="profile_sidebar__info_item" key={index}>
                {title}
                {icons &&
                  icons.map((Icon, index) => {
                    return (
                      <div className="profile_sidebar__info_icon" key={index}>
                        <Icon className="icon size_mod" />
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </ul>
      </div>

      {/* <FooterProfile /> */}
    </div>
  )
}

export { ProfileSideBar }
