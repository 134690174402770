import React, { useContext, useState, useRef } from 'react'
import { UiContext } from 'context/ui'
import { connect } from 'react-redux'

import { useHistory } from 'react-router-dom'
import { FormCheckBox, FormCustomSelect } from 'components/Form'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { Spinner } from 'components/Spinner'

import { ReactComponent as TikTokIcon } from 'i/icons/social_media/logo-tiktok.svg'
import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'
import { ReactComponent as FacebookIcon } from 'i/icons/social_media/facebook2.svg'
import { ReactComponent as InstagramIcon } from 'i/icons/social_media/instagram2.svg'
import { ReactComponent as LinkedinIcon } from 'i/icons/social_media/linkedin2.svg'
import { ReactComponent as TwitterIcon } from 'i/icons/social_media/twitter2.svg'

import { profileApi, authApi, paymentApi } from 'api'

import { logIn } from 'store/actions'

import sessionTypes from 'utils/sessionTypes.json'

import { POPUPS_ID, timezoneOptions } from 'utils/constants'

import { useClickOutside } from 'hooks'
import Popup from '../Popup/Popup'
import FormInputWithLabel from 'components/Form/FormInputWithLabel/FormInputWithLabel'
import { professionalTypeOptions } from 'utils/constants'
import { SubscriptionCard } from 'components/ui/SubscriptionCard'
import { Button } from 'components/ui/Button'
import { format } from 'date-fns'

const ProfilePopup = (props) => {
  const $container = useRef()
  const { setActivePopupId } = useContext(UiContext)
  const [success, setSuccess] = useState(false)
  const [checked, setChecked] = useState()
  const [timezone, setTimezone] = useState({ name: 'America/New_York', tag: 'en-US' })
  const { isOpen } = props

  const history = useHistory()

  const handleClose = () => {
    setActivePopupId(null)
  }

  const openSubscriptionPopup = () => {
    setActivePopupId(POPUPS_ID.manageSubscriptionPopup)
  }

  useClickOutside($container, handleClose)

  const rateSchema = Yup.object().shape({
    rate: Yup.number().required('Rate is required').max(10000, 'Rate is too high'),
  })

  return (
    <Popup
      isOpen={isOpen}
      title="Profile"
      description="Update your your information"
      headBorder
    >
      <Formik
        initialValues={{
          rate: Math.trunc(props.user?.rate),
          location: `${props.user?.location ? props.user?.location : ''}`,
          // Need to review
          language: `${
            props.user?.language ? props.user?.language?.code?.toLowerCase() : 'en'
          }`,
          category: props.user?.category || '',
          website: `${props.user?.youtube ? props.user?.youtube : ''}`,
          expectations: `${props.user?.expectations ? props.user?.expectations : ''}`,
          bio: `${props.user?.bio ? props.user?.bio : ''}`,
          name: `${props.user?.user?.name ? props.user?.user?.name : ''}`,
          website_url: `${props.user?.website_url ? props.user?.website_url : ''}`,
          linkedin_usr: `${props.user?.linkedin_usr ? props.user?.linkedin_usr : ''}`,
          insta_usr: `${props.user?.insta_usr ? props.user?.insta_usr : ''}`,
          facebook_usr: `${props.user?.facebook_usr ? props.user?.facebook_usr : ''}`,
          twitter_usr: `${props.user?.twitter_usr || ''}`,
          youtube: `${props.user?.youtube || ''}`,
          tiktok_usr: `${props.user?.tiktok_usr || ''}`,
        }}
        validationSchema={rateSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const data = {
            rate: values.rate.toString(),
            rate_currency_code: 'USD',
            language: props.user?.language
              ? props.user?.language?.code?.toLowerCase()
              : 'en',
            location: values.location,
            category: values.category || '',
            expectations: values.expectations,
            bio: values.bio,
            name: values.name,
            website_url: values.website_url,
            linkedin_usr: values.linkedin_usr,
            insta_usr: values.insta_usr,
            facebook_usr: values.facebook_usr,
            twitter_usr: values.twitter_usr,
            youtube: values.youtube,
            tiktok_usr: values.tiktok_usr,
            timezone: timezone.name,
          }

          Object.keys(data).forEach((key) => {
            if (data[key] === '') delete data[key]
          })

          await profileApi.setProInfo(data)

          setSuccess(true)

          window.location.reload()
        }}
      >
        {({ errors, touched, submitForm, isSubmitting, handleChange, values }) => (
          <>
            <div className="signup__tabs_content signup__tabs_content--border_mod">
              <div className="signup__tabs_content_item signup__tabs_content_item--active">
                <div className="signup__form">
                  <div className="form_group">
                    <label className="form_group__label">Subscription</label>
                    <SubscriptionCard
                      price="$89.95"
                      renewal={format(new Date(props?.user?.created), 'M/d/yy')}
                      action={
                        <Button
                          size="sm2"
                          variant="outlined2"
                          onClick={openSubscriptionPopup}
                        >
                          Cancel
                        </Button>
                      }
                    />
                  </div>
                  <Form className="signup__form_in">
                    <div className="form_group form_group--icon_mod profile_mod">
                      <div className="form_group profile_mod">
                        <label className="form_group__label">Pro Type</label>
                        <Field
                          name="category"
                          id="category"
                          as="select"
                          className="form_group__field"
                          disabled={isSubmitting || success}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        >
                          <option value="Professional Type" disabled hidden>
                            Professional Type
                          </option>
                          <option value="Therapist">Therapist</option>
                          <option value="Practitioner">Practitioner</option>
                          <option value="Business">Business</option>
                          <option value="Technology">Technology</option>
                          <option value="Other">Other</option>
                        </Field>
                      </div>
                    </div>
                    <div class="form_group">
                      <FormCustomSelect
                        options={timezoneOptions}
                        onChange={(e) => {
                          setTimezone(e.value)
                        }}
                      />
                    </div>
                    <div className="form_group">
                      <FormInputWithLabel
                        textarea
                        label="What should someone expect (short and sweet)"
                      >
                        <Field
                          name="expectations"
                          as="textarea"
                          className="form_group__field"
                          // placeholder={props.user?.expectations || 'Enter what you get'}
                          disabled={isSubmitting || success}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormInputWithLabel>
                    </div>
                    <div className="form_span_wrap_v3 profile_mod">
                      <FormCheckBox
                        id="bookHeader-name"
                        name="terms"
                        value={props.recording_allowed}
                        onChange={() => setChecked(!checked)}
                      />
                      <span className="form_term_conditions profile_mod">
                        Allow clients to record calls?
                      </span>
                    </div>
                    <div className="form_group__divider">
                      <div className="form_group__divider_in">Profile</div>
                    </div>
                    <div className="form_group">
                      <FormInputWithLabel label="Display name">
                        <Field
                          className="form_group__field"
                          name="name"
                          type="text"
                          disabled={isSubmitting || success}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormInputWithLabel>
                    </div>
                    <div className="form_group">
                      <FormInputWithLabel textarea label="Your bio (max 400 char)">
                        <Field
                          className="form_group__field"
                          name="bio"
                          as="textarea"
                          disabled={isSubmitting || success}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormInputWithLabel>
                    </div>
                    <div className="form_group">
                      <FormInputWithLabel label="Location">
                        <Field
                          className="form_group__field"
                          name="location"
                          type="text"
                          disabled={isSubmitting || success}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormInputWithLabel>
                    </div>
                    <div className="form_group">
                      <FormInputWithLabel label="Enter website url">
                        <Field
                          className="form_group__field"
                          name="website_url"
                          type="text"
                          disabled={isSubmitting || success}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormInputWithLabel>
                    </div>
                    <div className="form_row form_row--grid_mod">
                      <div className="form_group form_group--icon_mod">
                        <FormInputWithLabel
                          type="text"
                          label={props.user?.linkedin_usr || 'Enter LinkedIn'}
                          icon={<LinkedinIcon />}
                          iconPosition
                        >
                          <Field
                            className="form_group__field"
                            name="linkedin_usr"
                            type="text"
                            disabled={isSubmitting || success}
                            onChange={(e) => handleChange(e)}
                          />
                        </FormInputWithLabel>
                      </div>
                      <div className="form_group form_group--icon_mod">
                        <FormInputWithLabel
                          type="text"
                          label={props.user?.insta_usr || 'Enter Instagram'}
                          icon={<InstagramIcon />}
                          iconPosition
                        >
                          <Field
                            className="form_group__field"
                            name="insta_usr"
                            type="text"
                            disabled={isSubmitting || success}
                            onChange={(e) => handleChange(e)}
                          />
                        </FormInputWithLabel>
                      </div>
                      <div className="form_group form_group--icon_mod">
                        <FormInputWithLabel
                          type="text"
                          label={props.user?.facebook_usr || 'Enter Facebook'}
                          icon={<FacebookIcon />}
                          iconPosition
                        >
                          <Field
                            className="form_group__field"
                            name="facebook_usr"
                            type="text"
                            disabled={isSubmitting || success}
                            onChange={(e) => handleChange(e)}
                          />
                        </FormInputWithLabel>
                      </div>
                      <div className="form_group form_group--icon_mod">
                        <FormInputWithLabel
                          type="text"
                          label={props.user?.twitter_usr || 'Enter Twitter'}
                          icon={<TwitterIcon />}
                          iconPosition
                        >
                          <Field
                            className="form_group__field"
                            name="twitter_usr"
                            type="text"
                            disabled={isSubmitting || success}
                            onChange={(e) => handleChange(e)}
                          />
                        </FormInputWithLabel>
                      </div>
                      <div className="form_group form_group--icon_mod">
                        <FormInputWithLabel
                          type="text"
                          label={props.user?.youtube || 'Enter Youtube Channel'}
                          icon={<YoutubeIcon />}
                          iconPosition
                        >
                          <Field
                            className="form_group__field"
                            name="youtube"
                            type="text"
                            disabled={isSubmitting || success}
                            onChange={(e) => handleChange(e)}
                          />
                        </FormInputWithLabel>
                      </div>
                      <div className="form_group form_group--icon_mod">
                        <FormInputWithLabel
                          type="text"
                          label={props.user?.tiktok_usr || 'Enter TikTok'}
                          icon={<TikTokIcon />}
                          iconPosition
                        >
                          <Field
                            className="form_group__field"
                            name="tiktok_usr"
                            type="text"
                            disabled={isSubmitting || success}
                            onChange={(e) => handleChange(e)}
                          />
                        </FormInputWithLabel>
                      </div>
                    </div>

                    {isSubmitting ? (
                      <Spinner className="form_group__button spinner_mod" button={true} />
                    ) : (
                      <div className="form_group">
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault(e)
                            submitForm()
                          }}
                          className="form_group__button"
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    <button
                      onClick={(e) =>
                        authApi
                          .logOut()
                          .then((history.push('/'), window.localStorage.clear()))
                      }
                      className="form_group__button share_mod"
                    >
                      Log Out
                    </button>
                    <br />
                    <br />
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Popup>
  )
}

const mapStateToProps = ({ auth: { user, error, isPro } }) => ({
  authError: error,
  user,
  isPro,
})

const willMapDispatchToProps = {
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(ProfilePopup)
