import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Check2Icon } from 'i/icons/check_2.svg'
import { ReactComponent as LockIcon } from 'i/icons/lock.svg'
import { ReactComponent as MailIcon } from 'i/icons/mail.svg'

const FormFieldVersionTwo = ({
  id,
  labelText,
  type,
  name,
  placeholder,
  defaultValue,
  iconMod,
}) => {
  const fieldClasses = classNames('form_field profile_mod', {
    icon_mod: iconMod,
  })

  return (
    <div className={fieldClasses}>
      <label className="form_field_label" htmlFor={id}>
        {labelText}
      </label>
      <input
        className="form_field_input color_mod padding_mod"
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      {name === 'youtube_url' && (
        <div className="profile_form_icon_w v1_mod">
          <MailIcon className="icon icon-mail size_mod" />
        </div>
      )}
      {name === 'current_password' && (
        <div className="profile_form_icon_w v2_mod">
          <LockIcon className="icon icon-lock size_mod" />
        </div>
      )}
      {name === 'new_password' && (
        <div className="profile_form_icon_w v3_mod">
          <Check2Icon className="icon icon-check_2 size_mod" />
        </div>
      )}
    </div>
  )
}

export default FormFieldVersionTwo
