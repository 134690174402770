import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { formatParticipants, getParticipants } from 'domain/talks'

const TalksMessage = ({ chat, user, recordedCall, onRecordedCallClick }) => {
  const talksMessageClass = classNames('talks_message', {
    talks_message_no_record_state: !recordedCall,
  })

  const handleClick = async () => {
    if (!recordedCall && !onRecordedCallClick) return

    onRecordedCallClick(recordedCall?.video_link)
  }

  const participants = getParticipants(chat?.transcription, user?.user?.name)

  const getFormattedDate = (dateString) => {
    const now = new Date()
    const date = new Date(dateString)
    const diffInMilliseconds = now - date
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60)
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)

    if (diffInHours < 24) {
      const hours = Math.floor(diffInHours)
      const minutes = Math.floor((diffInHours - hours) * 60)
      return `${hours}:${minutes.toString().padStart(2, '0')} ago`
    } else if (diffInDays < 7) {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return daysOfWeek[date.getUTCDay()]
    } else {
      const month = date.getUTCMonth() + 1
      const day = date.getUTCDate()
      const year = date.getUTCFullYear()
      return `${month}/${day}/${year}`
    }
  }

  return (
    <button onClick={handleClick}>
      <div className={talksMessageClass}>
        <div className="talks_message_user_photo">
          {(chat.cover_image || chat?.client?.photo) && (
            <img
              className="talks_message_user_photo_in"
              src={
                chat.title
                  ? chat?.cover_image
                    ? chat?.cover_image
                    : ''
                  : chat.client?.photo
              }
              alt={chat.title ? chat.title : chat.client?.user?.name}
            />
          )}
        </div>
        <div className="talks_message_descr">
          {participants ? (
            <h3 className="talks_message_user_name">
              {formatParticipants(participants)}
              {participants.length > 3 && (
                <div class="talks_message_user_name__more">
                  +{participants.length - 3}
                </div>
              )}
            </h3>
          ) : null}
          <div className="talks_message_date">
            {user?.user?.email === chat.client?.user?.email
              ? chat.pro?.user?.name
              : chat.client?.user?.name}
            {chat.title && chat.title}
          </div>
        </div>
        <div className="talks_message_date">{getFormattedDate(chat?.scheduled_at)}</div>
      </div>
    </button>
  )
}

const mapStateToProps = ({ auth: { user, isPro } }) => ({
  user,
  isPro,
})

export default connect(mapStateToProps)(TalksMessage)
