import classNames from 'classnames'
import React from 'react'

import styles from './AuthContent.module.scss'

const AuthContent = ({ children, offsetMod }) => {
  const contentClasses = classNames(styles.content, {
    [styles.offsetMod]: offsetMod,
  })

  return <div className={contentClasses}>{children}</div>
}

export default AuthContent
