import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import closeBtn from 'i/icons/close.svg'

import { strikesApi } from 'api'

const PopupStrike = (props) => {
  if (!props.show) {
    //return null
  }

  const [success, setSuccess] = useState(false)
  const [report, setReport] = useState('')
  const [checked1, setChecked1] = useState(false)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(false)

  return (
    <div className="popup v2_mod popupStrike active_mod">
      <div className="popup_in side_mod pos_relative">
        <img
          className="popup_close_btn"
          src={closeBtn}
          alt="close"
          onClick={props.onClose}
        />
        <div className="popup_content limit_mod">
          <h2 className="section_title center_lvl_mod">Report Issue</h2>
          <div className="section_descr center_lvl_mod offset_mod">
            We understand that there may be an issue with your talk. <br />
            Please select the type of issue you encountered
          </div>
          <Formik
            initialValues={{
              strikeReport: '',
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              const callId = props?.call?.id
              await strikesApi.sendReport({
                title: report,
                description: report,
                call_id: callId,
              })
              setSubmitting(false)
              setSuccess(true)
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form className="popup_form" action="#" method="POST">
                <div role="group" className="popup_form_list">
                  <div className="popup_form_item">
                    <div className="form_field ">
                      <label className="form_field_label radio_field" htmlFor="report_0">
                        <Field
                          className="form_field_radiocheck"
                          id="report_0"
                          type="radio"
                          checked={checked1}
                          value="Connection quality issue"
                          name="report"
                          onChange={(e) => (
                            setReport(e.target.value),
                            setChecked1(true),
                            setChecked2(false),
                            setChecked3(false)
                          )}
                        />
                        <span className="form_field__text form_field_text_radio">
                          Connection quality issue
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="popup_form_item">
                    <div className="form_field ">
                      <label className="form_field_label radio_field" htmlFor="report_1">
                        <Field
                          className="form_field_radiocheck"
                          id="report_1"
                          type="radio"
                          checked={checked2}
                          value="Inappropriate conversation"
                          name="report"
                          onChange={(e) => (
                            setReport(e.target.value),
                            setChecked1(false),
                            setChecked2(true),
                            setChecked3(false)
                          )}
                        />
                        <span className="form_field__text form_field_text_radio">
                          Inappropriate conversation
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="popup_form_item">
                    <div className="form_field ">
                      <label className="form_field_label radio_field" htmlFor="report_2">
                        <Field
                          className="form_field_radiocheck"
                          id="report_2"
                          type="radio"
                          checked={checked3}
                          value="Un-invited attendees"
                          name="report"
                          onChange={(e) => (
                            setReport(e.target.value),
                            setChecked1(false),
                            setChecked2(false),
                            setChecked3(true)
                          )}
                        />
                        <span className="form_field__text form_field_text_radio">
                          Un-invited attendees
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="section_descr center_lvl_mod">
                  {props.call.client.user.email === props.user?.user?.email
                    ? props.call?.pro?.user?.name
                    : props.call?.client?.user?.name}{' '}
                  will receive a temporary Strike and will be allowed to appeal
                </div>
                {success || isSubmitting ? (
                  <div className="report_sent">Report Submitted</div>
                ) : (
                  <div className="btn_wrap offset_mod">
                    <button
                      className="btn_base callStrikedPopup"
                      disabled={isSubmitting || success}
                      onClick={async (e) => {
                        await submitForm()
                      }}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth: { error, user, isPro } }) => ({
  authError: error,
  user,
  isPro,
})

export default connect(mapStateToProps)(PopupStrike)
