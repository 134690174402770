import classNames from 'classnames'
import React from 'react'

import styles from './BaseNavigation.module.scss'

const BaseNavigation = ({ navigation, isAuthPage }) => {
  if (!navigation) return

  const navigationClasses = classNames(styles.navigation, {
    [styles.authNavigation]: isAuthPage,
  })

  return (
    <div className={navigationClasses}>
      <ul className={styles.list}>
        {navigation.map(({ title, href }) => {
          return (
            <li className={styles.item}>
              <a className={styles.link} href={href}>
                {title}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default BaseNavigation
