import React from 'react'
import { AlertMessage } from 'components/AlertMessage'
import { PROMISE_STATES } from 'utils/constants'

export function FetchResult({ children, data, indicator, status, error }) {
  if (status === PROMISE_STATES.pending) {
    return indicator
  }

  if (status === PROMISE_STATES.rejected || error) {
    return <AlertMessage message={error?.message} />
  }

  if (
    status === PROMISE_STATES.fulfilled &&
    (Array.isArray(data) ? data.length > 0 : data?.result)
  ) {
    return children
  }

  return null
}

export default FetchResult
