import React, { useState, useRef, useEffect } from 'react'
import styles from './SummaryTranscription.module.scss'
import { useParams } from 'react-router-dom'

import TranscriptionChat from 'components/TranscriptionChat/TranscriptionChat'

function SummaryTranscription({ isChangeable = true }) {
  const { id } = useParams()

  return (
    <div className={styles.component}>
      <TranscriptionChat roomID={id} />
    </div>
  )
}

export { SummaryTranscription }
