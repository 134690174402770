import React from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'

import ConfirmImg from 'i/confirm.svg'

const RequestSent = (props) => {
  const request = props.location.state
  const time = format(request.timeSelect, 'EEEE, LLLL do h:mma')

  return (
    <section className="section default_mod center_mod">
      <div className="section_in v3_mod">
        <div className="pro_img_wrap">
          <img className="pro_img" src={ConfirmImg} alt="pro > share" />
        </div>
        <h2 className="section_title center_lvl_mod offset_mod">
          {request?.pro?.user?.name} <br /> {time}
        </h2>
        <div className="section_descr center_lvl_mod offset_mod">
          {request?.pro?.user.name.split(' ')[0]} will confirm the talk within 24 hours
        </div>
        <div className="btn_wrap center_mod offset_2_mod">
          <button
            className="btn_base"
            onClick={() => (window.location = request?.res?.result?.approve_url)}
          >
            Pay now
          </button>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(RequestSent)
