import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { authApi, profileApi, callApi } from 'api'

import { setIsPro, logIn } from '../../store/actions'

const ProLogin = (props) => {
  const history = useHistory()

  const responseGoogle = async (res) => {
    await authApi
      .googleLogInPro({ access_token: res?.tokenObj?.access_token })
      .then(async (res) => {
        if (res?.result?.access_token) {
          props.setIsPro(true)
          localStorage.setItem('token', res?.result.access_token)
          localStorage.setItem('refreshToken', res?.result.refresh_token)
          localStorage.setItem('isPro', true)
          profileApi.setProInfo({
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
          history.push('/dashboard/pro')
          await profileApi
            .getProfileInfo()
            .then((res) => props.logIn(res?.result?.profile))
        }
      })

    authApi
      .getGoogleContacts({ access_token: res?.tokenObj?.access_token })
      .then((res) => {
        let contacts = res?.data?.connections?.filter((e) => e.emailAddresses)
        let emails = contacts?.map((e) => {
          return { email: e.emailAddresses[0].value, displayName: e.names[0].displayName }
        })
        callApi.saveContacts(JSON.stringify(emails))
      })
  }

  return (
    <GoogleLogin
      clientId={
        process.env.REACT_APP_GOOGLE_ID ||
        `762923432718-9ifhiu7igag6c2902uh9nejfo28uuc69.apps.googleusercontent.com`
      }
      buttonText="Sign Up with Google"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
      scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly"
      icon={false}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          style={{
            backgroundColor: 'none',
            color: '#1a1c1d',
            cursor: `pointer`,
            textDecoration: `none`,
            fontSize: '1.6rem',
            fontWeight: '700',
            width: '100%',
            height: '100%',
            // marginTop: '-5em',
            borderRadius: '',
            position: 'absolute',
            left: '0',
            top: '0',
            // right: '',
            zIndex: '20',
          }}
        ></button>
      )}
    />
  )
}

const mapStateToProps = ({ auth: { error } }) => ({
  authError: error,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(ProLogin)
