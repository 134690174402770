import React, { useContext, useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { enUS } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import { add, format } from 'date-fns'
import { ErrorMessage, Form, Formik } from 'formik'

import { FormTimeSelect, FormTextArea } from 'components/Form'
import FormInputWithLabel from 'components/Form/FormInputWithLabel/FormInputWithLabel'
import FormInputDropdown from 'components/Form/FormInputDropdown/FormInputDropdown'
import { AlertMessage } from 'components/AlertMessage'
import { Spinner } from 'components/Spinner'
import Popup from '../Popup/Popup'
import { UiContext } from 'context/ui'
import { FormField } from './ui'
import { useClickOutside } from 'hooks'

import { callApi } from 'api'

import 'react-nice-dates/build/style.css'
import styles from './SendInvitePopup.module.scss'

import times from 'utils/times.json'

import { ReactComponent as ProfileIcon } from 'i/icons/profile.svg'
import { ReactComponent as CalendarIcon } from 'i/icons/calendar_4.svg'
import { ReactComponent as ClockIcon } from 'i/icons/clock.svg'

const rateSchema = Yup.object().shape({
  classTitle: Yup.string().required('Class title is required'),
  clientEmail: Yup.string().required('Client email is required'),
  date: Yup.string().required('Date is required'),
  time: Yup.string()
    .required('Time is required')
    .notOneOf(['Time'], 'Time is not selected'),
})

const getDate = (scheduledAt) => {
  return scheduledAt ? new Date(scheduledAt) : new Date()
}

const SendInvitePopup = ({ isOpen, user, setFieldValue, onSubmitCallback }) => {
  const [inputError, setInputError] = useState(null)
  const [errorSubmitMessage, setErrorSubmitMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [isEmailDropdownOpen, setEmailDropdownOpen] = useState(false)
  const [emails, setEmails] = useState([])
  const [textInput, setTextInput] = useState('')
  const { setActivePopupId, setSentInvite } = useContext(UiContext)
  const $container = useRef()

  useEffect(() => {
    if (isOpen) {
      callApi.getContacts().then((res) => {
        if (res?.result?.result) {
          setContacts(JSON.parse(res?.result?.result))
          setFilteredContacts(JSON.parse(res?.result?.result))
        } else {
          setContacts([])
          setFilteredContacts([])
        }
      })
    }
  }, [isOpen])

  const filterTextByInput = (inputString) => {
    const newFilteredContacts = []

    for (const item of contacts) {
      if (
        item?.email?.includes(inputString) ||
        item?.displayName?.toLowerCase()?.includes(inputString)
      ) {
        newFilteredContacts.push(item)
      }
    }
    if (inputString.length === 0) {
      setFilteredContacts([...newFilteredContacts])
    } else {
      setFilteredContacts([...newFilteredContacts])
    }
  }

  const removeContact = (email) => {
    setContacts(contacts.filter((e) => e.email !== email))
  }

  document?.querySelector('#clientEmail')?.addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
      setEmails([...emails, textInput])
      e.preventDefault()
      setTextInput('')
    }
  })

  const earliestTime = times
    .map((e) => {
      const timeParts = e.time.split(' ')
      let hours = parseInt(timeParts[0].split(':')[0])
      let minutes = parseInt(timeParts[0].split(':')[1])
      let isPM = timeParts[1] === 'PM'

      // If the time is AM and the hour is 12, set it to 0
      if (!isPM && hours === 12) {
        hours = 0
      }

      // If the time is PM and the hour is not 12, add 12 to the hours
      if (isPM && hours !== 12) {
        hours += 12
      }

      const currentTime = new Date()
      const targetTime = new Date(currentTime)
      targetTime.setHours(hours, minutes, 0, 0)

      return {
        time: targetTime,
        original: e.time, // Store the original time for reference
      }
    })
    .filter((e) => e.time > new Date())
    .sort((a, b) => a.time - b.time)[0]

  const itemClassName = `${styles.item} ${styles.itemHalfMod}`

  const renderErrorMessage = (message) => {
    return <AlertMessage message={message} />
  }

  const createFieldName = (name) => {
    return name
  }

  const handleClose = () => {
    if (!inputError) {
      setActivePopupId(null)
    }
  }

  useClickOutside($container, handleClose)
  return (
    <Popup
      isOpen={isOpen}
      title="Invite to meeting"
      description="Choose which days and from what time you would like to offer 1on1 calls.
    You can always edit your schedule later"
      centerContent
    >
      {inputError && <div className={styles.error}>{inputError}</div>}
      <Formik
        initialValues={{
          classTitle: '',
          clientEmail: '',
          scheduled_at: '',
          time: earliestTime?.original,
          description: '',
        }}
        validationSchema={rateSchema}
        onSubmit={async (values) => {
          setIsLoading(true)
          let parsedDate = add(
            new Date(
              values.date.getFullYear(),
              values.date.getMonth(),
              values.date.getDate()
            ),
            values.time === 'Now'
              ? {
                  hours: earliestTime.original.split(':')[0],
                  minutes: earliestTime.original.split(':')[1],
                }
              : {
                  hours:
                    values.time.split(' ').includes('PM') &&
                    values.time.split(':')[0] !== '12'
                      ? parseInt(values.time.split(':')[0]) + 12
                      : parseInt(values.time.split(':')[0]),
                  minutes: parseInt(values.time.split(':')[1]),
                }
          )

          let scheduled_at = format(parsedDate, 'MM/dd/yyyy HH:mm XXXX')

          const formData = {
            invited_users: emails.length > 0 ? emails : [textInput],
            scheduled_at: scheduled_at,
            title: values.classTitle,
            total_cost: 0,
            description: values.description,
          }

          await callApi.sendInviteToGroup(formData)
          setSentInvite(true)

          setIsLoading(false)
          window.location.reload()
        }}
      >
        {({ values, handleChange, setFieldValue, submitForm, handleFocus }) => {
          if (!values?.date) {
            const date = getDate(values.scheduled_at)
            setFieldValue('date', date)
          }

          if (!values?.time) {
            const date = getDate(values.scheduled_at)
            const timeValue = date.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })

            setFieldValue('time', timeValue)
          }

          return (
            <Form className={styles.form}>
              <div className={styles.formContainer}>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <FormInputWithLabel
                      className={values.classTitle && styles.formLabel}
                      type="text"
                      label="Meeting subject"
                    >
                      <FormField
                        name={createFieldName('classTitle')}
                        id="classTitle"
                        defaultValue={values.classTitle}
                        className={styles.formInput}
                        handleChange={handleChange}
                      />
                      <ErrorMessage
                        name={createFieldName('classTitle')}
                        render={renderErrorMessage}
                      />
                    </FormInputWithLabel>
                  </li>
                  <li className={styles.item}>
                    <FormInputWithLabel
                      className={values?.clientEmail && styles.formLabel}
                      label="Email address"
                      icon={<ProfileIcon />}
                    >
                      <FormField
                        name={createFieldName('clientEmail')}
                        id="clientEmail"
                        defaultValue={values.clientEmail}
                        value={textInput}
                        className={styles.formInput}
                        handleChange={(e) => {
                          filterTextByInput(e.target.value)
                          setTextInput(e.target.value)
                          setFieldValue('clientEmail', e.target.value)
                        }}
                        handleFocus={() => setEmailDropdownOpen(true)}
                        onClick={() => setEmailDropdownOpen(true)}
                      />
                      <FormInputDropdown
                        className={styles.formInputDropdownOverlay}
                        data={filteredContacts}
                        contacts={contacts}
                        fieldName="clientEmail"
                        isOpen={isEmailDropdownOpen}
                        removeContact={removeContact}
                        setEmails={setEmails}
                        setFieldValue={setFieldValue}
                        setTextInput={setTextInput}
                        emails={emails}
                        hover={true}
                        onMouseLeave={() => {
                          setEmailDropdownOpen(false)
                        }}
                      />
                      <FormInputDropdown
                        data={contacts}
                        emails={emails}
                        isOpen={emails.length > 0}
                        hover={false}
                        setEmails={setEmails}
                      />
                    </FormInputWithLabel>
                    <ErrorMessage
                      name={createFieldName('clientEmail')}
                      render={renderErrorMessage}
                    />
                  </li>
                  <li className={`${itemClassName} ${styles.itemCalendar}`}>
                    <FormInputWithLabel
                      className={values.date && styles.formLabel}
                      label="Date"
                      icon={<CalendarIcon />}
                    >
                      <div className="date-picker">
                        <DatePicker
                          date={values.date ? new Date(values.date) : null}
                          className={styles.formInput}
                          onDateChange={(value) => {
                            setFieldValue('date', new Date(value))
                          }}
                          locale={enUS}
                        >
                          {({ inputProps, focused }) => (
                            <input
                              className={
                                `${
                                  values?.date ? styles.formInput : 'form_group__field'
                                }` + (focused ? ' -focused' : '')
                              }
                              {...inputProps}
                              placeholder=""
                            />
                          )}
                        </DatePicker>
                      </div>
                    </FormInputWithLabel>
                    <ErrorMessage
                      name={createFieldName('date')}
                      render={renderErrorMessage}
                    />
                  </li>
                  <li className={itemClassName}>
                    <FormInputWithLabel
                      className={values.time && styles.formLabel}
                      label="Time"
                      icon={<ClockIcon />}
                    >
                      <FormTimeSelect
                        name={createFieldName('time')}
                        id="time"
                        defaultOptionValue="Time"
                        showEmpty
                        className={styles.formInput}
                        variant="lg"
                        selectedValue={earliestTime?.original}
                        startTime={
                          values?.date?.setHours(0, 0, 0, 0) ===
                          new Date().setHours(0, 0, 0, 0)
                        }
                        handleChange={handleChange}
                      />
                    </FormInputWithLabel>

                    <ErrorMessage
                      name={createFieldName('time')}
                      render={renderErrorMessage}
                    />
                  </li>

                  <li className={styles.item}>
                    <FormInputWithLabel
                      className={values.description && styles.formLabel}
                      label="Message"
                    >
                      <FormTextArea
                        name={createFieldName('description')}
                        id="description"
                        handleChange={handleChange}
                      />
                    </FormInputWithLabel>
                    <ErrorMessage
                      name={createFieldName('description')}
                      render={renderErrorMessage}
                    />
                  </li>
                </ul>
              </div>
              {errorSubmitMessage && (
                <AlertMessage message={errorSubmitMessage} formMod />
              )}
              {isLoading ? (
                <Spinner className="form_group__button spinner_mod" button={true} />
              ) : (
                <button
                  type="submit"
                  className="form_group__button"
                  onClick={(e) => {
                    e.preventDefault(e)
                    submitForm()
                  }}
                >
                  Confirm
                </button>
              )}
            </Form>
          )
        }}
      </Formik>
    </Popup>
  )
}

export default SendInvitePopup
