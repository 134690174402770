import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DailyIframe from '@daily-co/daily-js'

import { WaitingRoomSection } from 'components/WaitingRoomSection'
import { CallInfo } from 'components/CallInfo'
import { Button } from 'components/ui/Button'

import { callApi } from 'api'
import { useFetch } from 'hooks'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { PROMISE_STATES } from 'utils/constants'
import { AlertMessage } from 'components/AlertMessage'

const applyStylesForActiveCall = () => {
  const headerdisplay = document.querySelector('header')
  const basedisplay = document.querySelector('base')
  const chatdisplay = document.querySelector('section')
  const timerdisplay = document.querySelector('talk_start_in')
  const joincalldisplay = document.querySelector('btn_v7_upper')

  headerdisplay?.classList.add('remove')
  basedisplay?.classList.add('no_header_mod')
  chatdisplay?.classList.add('active_call_width')
  timerdisplay?.classList.add('active_call_margin')
  joincalldisplay?.classList.add('remove')
}

const createFrame = (videoContainer, userName) => {
  return DailyIframe.createFrame(videoContainer, {
    iframeStyle: {
      position: 'fixed',
      left: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
    },
    showLeaveButton: true,
    showFullscreenButton: true,
    userName,
  })
}

const Huddle = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [isEnding, setIsEnding] = useState(false)
  const [isCallLive, setCallLive] = useState(false)
  const [transcription, setTranscription] = useState(
    JSON.parse(window.localStorage.getItem('transcription')) || []
  )
  const videoRef = useRef(null)
  const callFrameRef = useRef(null)

  const { slug } = useParams()

  const {
    loading: activeCallLoading,
    error: activeCallError,
    data: activeCall,
    statusCode,
  } = useFetch(() => callApi.getActiveInstantCall(slug))

  const isCallOwner = () => {
    return props.isPro && props?.user?.channel_name === slug
  }

  const redirectToSummary = () => {
    const redirectUrl = `/summary/group/${activeCall?.result?.id}`

    history.push(redirectUrl)
  }

  const onLeftMeeting = async (callId) => {
    return callApi
      .endGroupCall({
        id: callId,
        transcription: window.localStorage.getItem('transcription'),
      })
      .then((res) => {
        window.localStorage.removeItem('transcription')
        redirectToSummary(callId)
      })
  }

  const onAppMessage = (callFrame, msg) => {
    const data = msg.data
    if (msg?.fromId === 'transcription' && data?.is_final) {
      const local = callFrame.participants().local
      const userName =
        local.session_id === data.session_id
          ? local.user_name
          : callFrame.participants()[data.session_id].user_name

      setTranscription((prevTranscription) => [
        ...prevTranscription,
        {
          name: userName,
          date: data?.timestamp,
          text: data?.text,
        },
      ])
    }
  }

  const startCall = () => {
    if (callFrameRef.current) return

    const searchParams = new URLSearchParams(
      new URL(activeCall?.result?.join_call_url).search
    )
    const params = Object.fromEntries(searchParams.entries())
    callFrameRef.current = createFrame(videoRef.current, props.user?.user?.name)
    callFrameRef.current.on('left-meeting', async (event) => {
      setIsEnding(true)
      setLoading(true)
      setCallLive(false)

      if (isCallOwner()) {
        await onLeftMeeting(activeCall?.result?.id)
      } else {
        if (props?.user) {
          redirectToSummary(activeCall?.result?.id)
        }
      }

      setLoading(false)
    })
    callFrameRef.current.join({ url: activeCall?.result?.join_call_url, token: params.t })
    callFrameRef.current.on('joined-meeting', () => {
      callFrameRef.current.startTranscription()
    })
    callFrameRef.current.on('app-message', (msg) => {
      if (isCallOwner()) {
        onAppMessage(callFrameRef.current, msg)
      }
    })
  }

  useEffect(() => {
    window.localStorage.setItem('transcription', JSON.stringify(transcription))
  }, [transcription])

  useEffect(() => {
    setTranscription(JSON.parse(window.localStorage.getItem('transcription') || []))
    document.title = `Meeting room | Talkpro`
  }, [])

  useEffect(() => {
    if (
      activeCall?.result?.join_call_url &&
      isCallOwner() &&
      activeCallLoading === PROMISE_STATES.fulfilled &&
      props?.loading === PROMISE_STATES.fulfilled
    ) {
      setCallLive(true)
      applyStylesForActiveCall()
    }
  }, [activeCall, activeCallLoading, props])

  useEffect(() => {
    if (
      activeCall?.result?.join_call_url &&
      isCallOwner() &&
      activeCallLoading === PROMISE_STATES.fulfilled &&
      isCallLive === true
    ) {
      startCall()
    }
  }, [isCallLive])

  // Channel not found
  if (activeCallLoading === PROMISE_STATES.rejected && statusCode === 404) {
    return (
      <WaitingRoomSection variant="huddle" withoutLogout>
        <AlertMessage message={activeCallError} />
      </WaitingRoomSection>
    )
  }

  // FIXME: refactor setTimeout logic
  const enterRoom = () => {
    setCallLive(true)
    setTimeout(() => {
      applyStylesForActiveCall()
      startCall()
    }, 3000)
  }

  const getInTouch = () => {
    window.location.href = `mailto:${activeCall?.result?.call_owner_email}`
  }

  // FIXME: refactor

  const callContent = `Welcome to ${activeCall?.result?.call_owner} Talk Pro encrypted private room. Talk
  pro is the internet’s most powerfull meeting AI technology. Summary
  notes from your meeting can be shared after your talk.`

  return (
    <WaitingRoomSection variant="huddle" withoutLogout>
      {!isCallLive ? (
        <>
          {activeCallLoading === PROMISE_STATES.fulfilled &&
            isCallOwner() &&
            activeCall?.result?.status === 202 && (
              <AlertMessage message="You haven't created any call yet" />
            )}

          {activeCallLoading === PROMISE_STATES.fulfilled &&
            !isCallOwner() &&
            activeCall?.result?.status === 202 && (
              <CallInfo variant="secondary">
                <CallInfo.Title>{`${activeCall?.result?.call_owner} meeting room is closed`}</CallInfo.Title>
                <CallInfo.Content>{callContent}</CallInfo.Content>
                <Button variant="outlined" size="md" rounded="sm" onClick={getInTouch}>
                  Get in touch
                </Button>
              </CallInfo>
            )}

          {activeCallLoading === PROMISE_STATES.fulfilled &&
            props?.user === null &&
            !isCallOwner() &&
            activeCall?.result?.status !== 202 && (
              <CallInfo variant="secondary">
                <CallInfo.Title>{`${activeCall?.result?.call_owner} is waiting for you`}</CallInfo.Title>
                <CallInfo.Content>{callContent}</CallInfo.Content>
                <Button variant="success" size="md" rounded="sm" onClick={enterRoom}>
                  Enter Room
                </Button>
              </CallInfo>
            )}
        </>
      ) : (
        <div id="daily_video" ref={videoRef} />
      )}
    </WaitingRoomSection>
  )
}

const mapStateToProps = ({ auth: { error, user, isPro, loading } }) => ({
  authError: error,
  user,
  isPro,
  loading,
})

export default connect(mapStateToProps)(Huddle)
