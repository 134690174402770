import React from 'react'

const FormTextArea = ({ name, placeholder, defaultValue, handleChange }) => {
  return (
    <textarea
      className="form_group__field form_group__field--lg_mod"
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  )
}

export default FormTextArea
