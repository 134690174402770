import React from 'react'
import styles from './ChatMessage.module.scss'
import classNames from 'classnames'

function ChatMessage({ data, myName, variant = 'primary', index }) {
  const { name, date, text } = data
  return (
    <div
      className={classNames(styles.message, {
        [styles.primaryVariant]: variant === 'primary',
        [styles.secondaryVariant]: variant === 'secondary',
        [styles.messageV2]: index % 2 !== 0,
      })}
    >
      <div className={styles.head}>
        <div className={styles.author}>{name}</div>
        <div className={styles.time}>{date}</div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default ChatMessage
