import React from 'react'

import { ArrowBack } from 'components/ArrowBack'

import Avatar from 'i/confirmed_popup.jpg'

const ConfirmedPopup = () => {
  return (
    <div className="confirmed_popup">
      <div className="confirmed_popup__top">
        <ArrowBack />
      </div>

      <div className="confirmed_popup__scroll_area">
        <div className="confirmed_popup__content">
          <div className="confirmed_popup_image">
            <img className="confirmed_popup_image__in" src={Avatar} alt="" />
          </div>

          <div className="confirmed_popup_title">Requested with Tom</div>

          <div className="confirmed_popup_date">
            <div className="confirmed_popup_date__day">Wednesday, March 16</div>
            <div className="confirmed_popup_date__time">10:30 - 11:00 AM</div>
          </div>

          <div className="confirmed_popup_subtitle">
            Karl will confirm the talk within 24 hours
          </div>
        </div>
      </div>

      <a className="confirmed_popup_button" href="#">
        Continue to Dashbord
      </a>
    </div>
  )
}

export default ConfirmedPopup
