import React, { useContext, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { UiContext } from 'context/ui'
import classNames from 'classnames'

import { ReactComponent as CloseIcon } from 'i/icons/close.svg'

import { useClickOutside } from 'hooks'

import styles from './Popup.module.scss'

const Popup = ({ isOpen, title, description, icon, children, type, centerContent }) => {
  const { setActivePopupId } = useContext(UiContext)
  const $container = useRef()
  const $popup = useRef()

  const handleClose = () => {
    setActivePopupId(null)
    if (window?.localStorage?.getItem('editEvent') !== undefined) {
      window.localStorage.removeItem('editEvent')
    }
  }

  useClickOutside($container, handleClose)

  // const popupClass = classNames(styles.popup, {
  // 	// [styles['popup-enter']]: isOpen,
  // });

  // useEffect(() => {
  //   if (isOpen) {
  //     setIsHandleOpen(true)
  //   } else {
  //     $popup?.current?.classList.remove(styles['popup-enter-active'])
  //     setTimeout(() => {
  //       setIsHandleOpen(false)
  //     }, 200)
  //   }
  // }, [isOpen])

  // useEffect(() => {
  //   if (isHandleOpen) {
  //     $popup?.current?.classList.add(styles['popup-enter-active'])
  //   }
  // }, [isHandleOpen])

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 500,
        exit: 350,
      }}
      classNames={{
        enter: styles['popup-enter'],
        enterActive: styles['popup-enter-active'],
        exitActive: styles['popup-exit-active'],
        enterDone: styles['popup-enter-done'],
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.popup} ref={$popup}>
        <div
          className={classNames(styles.container, {
            [styles.containerV2]: type === 'v2',
            [styles.containerV3]: type === 'v3',
            [styles.containerV4]: type === 'v4',
            [styles.containerV5]: type === 'v5',
            [styles.popupOffsets]: type === 'custom-offsets',
            [styles.popupBorder]: type === 'border',
            [styles.centerContent]: centerContent,
          })}
          ref={$container}
        >
          <button className={styles.closeButton} type="button" onClick={handleClose}>
            <CloseIcon className="icon size_mod" />
          </button>
          <div class={styles.popup_in}>
            {(title || description) && (
              <div className={styles.head}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <div className={styles.content}>
                  {title && <div className={styles.title}>{title}</div>}
                  {description && <div className={styles.description}>{description}</div>}
                </div>
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Popup
