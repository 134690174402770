import classNames from 'classnames'
import React from 'react'

import styles from './DashboardIndicator.module.scss'

const DashboardIndicator = ({ title, value, info, ratioColor, ratioValue }) => {
  const colorMod =
    ratioColor === 'orange' ? styles.dashboardIndicatorRatioValueColorMod : ''

  const ratioValueClass = classNames(styles.dashboardIndicatorRatioValue, colorMod)

  return (
    <div className={styles.dashboardIndicator}>
      <div className={styles.dashboardIndicatorCol}>
        <div className={styles.dashboardIndicatorTitle}>{title}</div>
        <div className={styles.dashboardIndicatorInfo}>{info}</div>
      </div>
      <div className={styles.dashboardIndicatorCol}>
        <div className={styles.dashboardIndicatorValue}>{value}</div>
        <div className={styles.dashboardIndicatorRatio}>
          <div className={ratioValueClass} style={{ width: ratioValue || '100%' }} />
        </div>
      </div>
    </div>
  )
}

export default DashboardIndicator
