import React, { useState } from 'react'
import { connect } from 'react-redux'

import QrMaker from 'components/QrMaker'

import closeBtn from 'i/icons/close.svg'

// TODO: delete this component, after testing new functionality

const PopupShare = (props) => {
  if (!props.showPopup) {
    //return null
  }

  const [copied, setCopied] = useState(false)
  const userLink = `https://www.talkpro.com/${props?.user?.channel_name}`

  return (
    <div className="popup popupShare active_mod">
      <div className="popup_in pos_relative">
        <img
          className="popup_close_btn"
          src={closeBtn}
          alt="close"
          onClick={props.onClose}
        />
        <div className="popup_content">
          <h3 className="section_title md_mod">Invite people to chat</h3>
          <div className="section_descr offset_mod">
            Try to create new calendar events or sync your calendar service
          </div>
          <QrMaker />
          <div className="section_link">{userLink}</div>
          <div
            className={
              !copied ? 'popup_confirm_container' : 'popup_confirm_container showConfirm'
            }
          >
            <div className="popup_copy_confirm">Link has been copied</div>
          </div>
          <div className="btn_wrap center_mod offset_mod">
            <button
              className="btn_v2"
              href=""
              onClick={() => {
                navigator.clipboard.writeText(userLink)
                setCopied(true)
              }}
            >
              Copy Link
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth: { error, user, isPro } }) => ({
  authError: error,
  user,
  isPro,
})

export default connect(mapStateToProps)(PopupShare)
