import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Code } from 'react-content-loader'

import clockFace from 'i/clock-anim.svg'
import sadFace from 'i/sad_face.svg'
import smilingFace from 'i/smiling_face.svg'
import logicFace from 'i/logic.svg'
import aiPersonal from 'i/icons/ai_personal_assistant_white.svg'
import aiPunctual from 'i/icons/ai_punctual_white.svg'

import { ReactComponent as CloseIcon } from 'i/icons/close.svg'

import styles from './DashboardReport.module.scss'
import DashboardTimeLeft from './ui/DashboardTimeLeft'
import { callApi } from 'api'

const DashboardReport = (props) => {
  let strikes = props?.user?.strikes
  strikes = strikes || strikes === 0 ? Number(strikes) : -1
  const [callsLeft, setCallsLeft] = useState(0)
  const [loading, setLoading] = useState(true)
  const [barWidth, setBarWidth] = useState(0)

  useEffect(() => {
    callApi?.getCalls().then((res) => {
      setLoading(false)
      setCallsLeft(50 - res?.result?.filter((e) => e.finished_at).length)
      setBarWidth((res?.result?.filter((e) => e.finished_at).length / 50) * 100)
    })
  }, [])

  const strikeCountAllowed = 3
  const { type } = props

  return (
    <>
      <div
        className={classNames(styles.dashboardReport, {
          [styles.dashboardReportV2]: type === 'ai',
        })}
      >
        <button
          className={styles.dashboardReportHide}
          type="button"
          aria-label="Hide reports"
        >
          <CloseIcon />
        </button>
        {!loading && (
          <div className={styles.dashboardReportHeading}>
            <div
              className={classNames(styles.dashboardReportIndicator, {
                [styles.dashboardReportIndicatorV2]: type === 'ai',
              })}
            >
              {type === 'report' && (
                <>
                  {strikes === 0 && (
                    <img
                      src={aiPunctual}
                      alt=""
                      className={styles.dashboardReportIndicatorImg}
                    />
                  )}
                  {((strikes > 0 && strikes < strikeCountAllowed) ||
                    strikes >= strikeCountAllowed) && (
                    <img
                      src={clockFace}
                      alt=""
                      className={styles.dashboardReportIndicatorImg}
                    />
                  )}
                </>
              )}
              {type === 'ai' && (
                <img
                  src={aiPersonal}
                  alt=""
                  className={styles.dashboardReportIndicatorImg}
                />
              )}
            </div>
            <div className={styles.dashboardReportTitle}>
              {type === 'report' && (
                <p>Stay punctual</p>
                /*<>
                  {strikes === 0 && 'So far, so good'}
                  {strikes > 0 && strikes < strikeCountAllowed && 'You got a strike'}
                  {strikes >= strikeCountAllowed && '3 strikes, your passed'}
                </>*/
              )}
              {type === 'ai' && <p>AI Personal Assistant</p>}
            </div>
          </div>
        )}

        {loading ? (
          <Code className={styles.dashboardReportContent} style={{ minWidth: '20em' }} backgroundColor="#547598" foregroundColor="#547598" />
        ) : (
          <>
            {type === 'report' && (
              <>
                <div class="timeLeft_block">
                  <div class="timeLeft_bar timeLeft_bar_1">
                    <div class="timeLeft_progress"></div>
                  </div>
                  <div class="timeLeft_bar timeLeft_bar_2">
                    <div class="timeLeft_progress"></div>
                  </div>
                  <div class="timeLeft_bar timeLeft_bar_3">
                    <div class="timeLeft_progress"></div>
                  </div>
                </div>
              </>
            )}
            {type === 'ai' && callsLeft > 0 && (
              <DashboardTimeLeft
                title={`${callsLeft} left`}
                width={barWidth}
              ></DashboardTimeLeft>
            )}
            <div className={styles.dashboardReportContent}>
              {type === 'report' && (
                <p>Be on time, always check your connection, be professional, and get good reviews.</p>
                /*<>
                  {strikes === 0 && (
                    <>
                      <p>Stay on time, always check your connection,</p>
                      <p>and be professional and get good reviews.</p>
                      <p>You have no strikes.</p>
                    </>
                  )}
                  {strikes > 0 && strikes < strikeCountAllowed && (
                    <>
                      <p>Punctuality is the soul of business.</p>
                      <p>You are allowed to 3 strikes.</p>
                      <a href="#">You have {strikeCountAllowed - strikes} left</a>
                    </>
                  )}
                  {strikes >= strikeCountAllowed && (
                    <>
                      <p>Uh oh! You where late too often.</p>
                      <p>You got 3 strikes so your suspended</p>
                      <a href="https://forms.gle/JEZ25RSUadfeGQ9r8">Appeal here</a>
                    </>
                  )}
                </>*/
              )}
              {type === 'ai' && (
                <p>
                  Once you reach 100 conversations you Emma AI assistant can answer for you
                </p>
              )}
            </div>            
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user } }) => ({
  authError: error,
  user,
  isPro,
})

export default connect(mapStateToProps)(DashboardReport)
