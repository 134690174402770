import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import { SanitaizedText } from 'components/SanitaizedText'
import { ReactComponent as PenIcon } from 'i/icons/pen.svg'
import styles from './SummaryNotes.module.scss'
import { postCallSummary } from 'api/call'

function SummaryNotes({ isChangeable = true, notes = '', callId, callType }) {
  const $textareaRef = useRef(null)
  const [isChangeMod, setChangeMod] = useState(false)
  const [copyNotes, setCopyNotes] = useState(notes)

  const handleTextareaChange = ({ target }) => {
    setCopyNotes(target.value)
  }

  const enableChangeMod = () => {
    setChangeMod(true)
    $textareaRef?.current?.focus()
  }

  const disableChangeMod = () => {
    setChangeMod(false)
    postCallSummary(callType, callId, {
      meeting_notes: copyNotes,
    })
  }

  const toggleChangeMod = () => {
    if (isChangeMod) {
      disableChangeMod()
    } else {
      enableChangeMod()
    }
  }

  return (
    <div
      className={classNames(styles.notes, {
        [styles.changeMod]: isChangeMod,
      })}
    >
      <div className={styles.container}>
        {isChangeable && (
          <>
            <div className={styles.wrap}>
              <textarea
                ref={$textareaRef}
                className={styles.textarea}
                defaultValue={copyNotes}
                onChange={handleTextareaChange}
              />
            </div>
          </>
        )}
        <div className={styles.text}>
          <SanitaizedText>{copyNotes}</SanitaizedText>
        </div>
      </div>
      {isChangeable && (
        <div className={styles.triggerWrap}>
          <button
            className={styles.trigger}
            type="button"
            aria-label="Change metting notes"
            onClick={toggleChangeMod}
          >
            {isChangeMod ? (
              <div className={styles.label}>Save</div>
            ) : (
              <div className={styles.icon}>
                <PenIcon />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export { SummaryNotes }
