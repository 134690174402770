import React from 'react'

import styles from './AuthHeader.module.scss'
import LogoImg from 'i/talkpro_logo.png'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const AuthHeader = ({ title, subTitle, offsetMod, dividerMod }) => {
  const headerClasses = classNames(styles.header, {
    [styles.offsetMod]: offsetMod,
    [styles.dividerMod]: dividerMod,
  })

  return (
    <div className={headerClasses}>
      <Link to="/" className={styles.logo}>
        <img className={styles.image} src={LogoImg} alt={title} />
      </Link>
      {title && <h1 className={styles.title}>{title}</h1>}
      {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
    </div>
  )
}

export default AuthHeader
