import { UPDATE_HISTORY } from '../actions/actionTypes'

const initialState = {
  historyList: [],
}

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HISTORY:
      return {
        ...state,
        historyList: [...action.payload],
      }

    default:
      return { ...state }
  }
}

export default historyReducer
