import classNames from 'classnames'
import React from 'react'

import times from 'utils/times.json'

import styles from './FormTimeSelect.module.scss'
import { isFuture, add } from 'date-fns'

const FormTimeSelect = ({
  name,
  placeholder,
  selectedValue,
  defaultOptionValue,
  handleChange,
  variant,
  startTime,
  showEmpty,
  className,
}) => {
  const componentClassName = classNames(styles.select, className, {
    [styles.lgSelect]: variant === 'lg',
  })

  // These funcitons are used to format the time string into an object
  // that can be used by the date-fns library

  // I know, I know, this is a super hacky way to do this,
  // but if you feel that strongly about it,
  // feel free to refactor every time selector component in the app

  const formatStringtoTime = (time) => {
    return {
      hours:
        time.split(' ').includes('PM') && time.split(':')[0] !== '12'
          ? parseInt(time.split(':')[0]) + 12
          : parseInt(time.split(':')[0]),
      minutes: parseInt(time.split(':')[1]),
    }
  }

  const today = new Date().setHours(0, 0, 0, 0)

  return (
    <select
      className={componentClassName}
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
    >
      <option disabled value={null} selected></option>
      {times
        .filter((e) =>
          startTime ? isFuture(add(today, formatStringtoTime(e.time))) : true
        )
        .map((e, index) =>
          e.time === null ? (
            <option value={defaultOptionValue} disabled hidden key="default_from">
              {defaultOptionValue}
            </option>
          ) : (
            <>
              {index === 0 && showEmpty && (
                <option value="" disabled selected hidden></option>
              )}
              <option value={e.time} key={index} selected={e.time === selectedValue}>
                {e.time}
              </option>
            </>
          )
        )}
    </select>
  )
}

export default FormTimeSelect
