import { React } from 'react'

import { ReactComponent as AndroidIcon } from 'i/icons/brands/android.svg'
import { ReactComponent as AppleIcon } from 'i/icons/brands/apple.svg'
import { ReactComponent as FacebookIcon } from 'i/icons/social_media/facebook.svg'
import { ReactComponent as InstagramIcon } from 'i/icons/social_media/instagram.svg'
import { ReactComponent as LinkedinIcon } from 'i/icons/social_media/linkedin.svg'
import { ReactComponent as TwitterIcon } from 'i/icons/social_media/twitter.svg'
import { ReactComponent as YoutubeIcon } from 'i/icons/social_media/youtube.svg'

const Footer = () => {
  const menuList = [
    ['Help Center', 'http://help.talkpro.com/en'],
    [
      'Terms of Service',
      'http://help.talkpro.com/en/articles/5527110-terms-and-conditions-of-service',
    ],
    ['Privacy Policy', 'http://help.talkpro.com/en/articles/5527072-privacy-policy'],
  ]

  const socialBlocks = [
    {
      title: 'Download App',
      list: [AppleIcon, AndroidIcon],
    },
    {
      title: 'Follow us',
      list: [FacebookIcon, TwitterIcon, LinkedinIcon, YoutubeIcon, InstagramIcon],
    },
  ]

  return (
    <footer className="footer">
      <div className="footer_in">
        <div className="footer_top">
          <div className="footer_menu">
            {menuList.map((item, index) => {
              return (
                <div className="footer_menu_item" key={index}>
                  <a className="footer_menu_link" href={item[1]}>
                    {item[0]}
                  </a>
                </div>
              )
            })}
          </div>
          <div className="footer_social">
            {socialBlocks.map((item, index) => {
              return (
                <div className="footer_social_block" key={index}>
                  <div className="footer_social_title">{item.title}</div>
                  <ul className="social_list">
                    {item.list.map((Icon, index) => {
                      return (
                        <li className="social_item" key={index}>
                          <a className="social_link contact_mod" href="#">
                            <Icon className={`icon social_mod`} />
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
        <div className="footer_copyright">© 2022 TalkPro.com</div>
      </div>
    </footer>
  )
}

export default Footer
