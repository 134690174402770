import React from 'react'

import closeBtn from 'i/icons/close.svg'

const PopupStriked = (props) => {
  return (
    <div className="popup v2_mod popupStriked active_mod">
      <div className="popup_in side_mod">
        <img
          className="popup_close_btn"
          src={closeBtn}
          alt="close"
          onClick={props.onClose}
        />
        <div className="popup_content limit_mod">
          <h2 className="section_title center_lvl_mod">We received your request</h2>
          <div className="section_descr center_lvl_mod">
            Karl will receive a temporary Strike and will be allowed to appeal{' '}
          </div>
          <div className="btn_wrap offset_mod">
            <button className="btn_base popupClose" href="#" onClick={props.onClose}>
              Close{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupStriked
