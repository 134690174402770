import React from 'react'

import { ProfileTabs } from 'components/ProfileTabs'
import { ProfileCalendar } from 'components/ProfileCalendar'
import { ProfileSidebar } from 'components/ProfileSidebar'
import { SelectTimePopup } from 'components/SelectTimePopup'
import { ConfirmedPopup } from 'components/ConfirmedPopup'

const ProfilePage = () => {
  return (
    <>
      <section className="section profile">
        <div className="profile__cols">
          <div className="profile__col">
            <ProfileSidebar />
          </div>
          <div className="profile__col">
            <ProfileCalendar />
          </div>
          <div className="profile__col">
            <ProfileTabs />
          </div>
        </div>
      </section>
      <SelectTimePopup />
      <ConfirmedPopup />
    </>
  )
}

export default ProfilePage
