import { React, useState, useEffect, useRef } from 'react'

import { Logo } from 'components/Header'
import { ReactComponent as BackIcon } from 'i/icons/back.svg'
import LogoImg from 'i/talkpro_logo_white.png'

const HeaderProfile = (props) => {
  // const headerRef = useRef(null)
  const menuHandler = (e) => {
    e.target.classList.toggle('menu_trigger--active_mod')
    document.querySelector('.header_menu').classList.toggle('header_menu--active')
    document.body.classList.toggle('body--menu_open')
  }

  const menuList = ['About', 'Billing', 'Logout']

  useEffect(() => {
    document.querySelector('.section_wrap').addEventListener('scroll', () => {
      const scrolled = document.querySelector('.section_wrap').scrollTop
      // if (scrolled >= 50 && !headerRef.current.classList.contains('scroll_mod')) {
      //   headerRef.current.classList.add('scroll_mod')
      // } else if (scrolled < 50) {
      //   headerRef.current.classList.remove('scroll_mod')
      // }
    })
  })

  return (
    <header
      className={`header profile_mod ${
        props.isBookPage || props.isConfirmPage ? 'short_mod' : ''
      }`}
      // ref={headerRef}
    >
      <div className="header_in qq">
        <div className="header_logo_w_block">
          <div className="header_logo_w_block_in">
            <div className="header_logo_w profile_mod">
              <Logo />
            </div>
          </div>
        </div>
        <div className="header_btn">
          <a href="" className="header_btn__link">
            About TalkPro
          </a>
        </div>
        {/* <a href="#" className="header_back">
					<BackIcon className='icon size_mod' />
				</a> */}
        {!props.isBookPage && !props.isConfirmPage ? (
          <div className="menu_trigger profile_mod" onClick={menuHandler}>
            <div className="menu_trigger__block">
              <span className="menu_trigger_decor"></span>
            </div>
          </div>
        ) : null}
        <div className="header_menu">
          <div className="header_menu__list">
            {menuList.map((item, index) => {
              return (
                <div className="header_menu__item" key={index}>
                  <a href="#" className="header_menu__link">
                    {item}
                  </a>
                </div>
              )
            })}
          </div>
          <div className="header_menu__logo">
            <img className="header_menu__logo_img" src={LogoImg} alt="" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderProfile
