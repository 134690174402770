import { UPDATE_HISTORY } from './actionTypes'

export const initializeHistory = () => (dispatch, getState) => {
  const historyItem = `history_${getState().auth.userId}`
  const initialHistory = JSON.parse(localStorage.getItem(historyItem))
  if (!initialHistory) localStorage.setItem(historyItem, JSON.stringify([]))

  return dispatch({
    type: UPDATE_HISTORY,
    payload: initialHistory || [],
  })
}
