import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { paymentApi } from 'api'
import { useHistory } from 'react-router-dom'

const Callback = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const history = useHistory()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    paymentApi.onboardSeller().then((res) => history.push('/dashboard/pro'))
  }, [])

  return <section className="section default_mod_v2 center_mod"></section>
}

const mapStateToProps = ({ auth: { user, error, attributes } }) => ({
  user,
  authError: error,
  attributes,
})

export default connect(mapStateToProps)(Callback)
