import classNames from 'classnames'
import React from 'react'

import styles from './AuthItem.module.scss'

const AuthItem = ({ variant, children }) => {
  const classes = classNames({
    [styles.item]: !variant,
    [styles.itemDivider]: variant === 'divider',
  })

  return <div className={classes}>{children}</div>
}

export default AuthItem
