/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import profileTabsMedia from 'i/profile_tabs_media.jpg'
import { ReactComponent as QuestionIcon } from 'i/icons/question.svg'

import { YoutubeVideoHandler } from 'lib'
import { generatePaymentIntentId } from 'utils/generate-payment-intent-id'

const videoHandler = new YoutubeVideoHandler()

const ProfileTabs = ({ user, pro, events }) => {
  const history = useHistory()

  const dateOptions = {
    weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  }
  const tabsContent = [
    {
      events,
    },
    {
      media: [
        {
          title: 'How to do TikTok',
          img: profileTabsMedia,
        },
        {
          title: 'React native in 2 hours',
          img: profileTabsMedia,
        },
        {
          title: 'Java Tutorial for Beginners',
          img: profileTabsMedia,
        },
        {
          title: 'React native in 2 hours',
          img: profileTabsMedia,
        },
        {
          title: 'Java Tutorial for Beginners',
          img: profileTabsMedia,
        },
        {
          title: 'React native in 2 hours',
          img: profileTabsMedia,
        },
      ],
    },
  ]

  const [activeTab, setActiveTab] = useState(events?.length ? 0 : 1)
  const [userVideos, setUserVideos] = useState(null)

  const tabHandler = (index) => {
    setActiveTab(index)
  }

  useEffect(() => {
    let videoUrls = []

    if (pro?.video_urls) {
      videoUrls = JSON.parse(pro?.video_urls)
    } else if (user?.video_urls) {
      videoUrls = JSON.parse(pro?.video_urls)
    }

    setUserVideos(videoUrls)
  }, [pro, user])

  const handleEventClick = (e, event) => {
    e.preventDefault()

    const eventEntryFee = parseInt(event?.entry_fee)

    const eventData = {
      event: {
        id: event?.id,
        entry_fee: event?.entry_fee,
        scheduled_at: event?.scheduled_at,
        category: event?.category,
        description: event?.description,
        max_participants: event?.max_participants,
      },
      pro,
    }

    if (eventEntryFee !== 0) {
      if (user) {
        history.push('/user/payments', eventData)
      } else {
        history.push('/user/confirm', eventData)
      }
    }

    if (eventEntryFee === 0) {
      if (user) {
        const paymentIntentId = generatePaymentIntentId()
        const callbackRoute = `/user/callback/?payment_intent=${paymentIntentId}`
        window.localStorage.setItem('isEvent', 'true')
        window.localStorage.setItem('info', JSON.stringify(eventData))
        history.push(callbackRoute, eventData)
      } else {
        history.push('/user/confirm', eventData)
      }
    }
  }

  const tabsLinks =
    events?.length && userVideos?.length
      ? ['Classes', 'Videos']
      : events?.length && !userVideos?.length
      ? ['Classes']
      : ['Videos']

  return (
    <>
      {events?.length || userVideos?.length ? (
        <div className="profile_tabs">
          <div className="profile_tabs__head">
            {tabsLinks.map((item, index) => {
              return (
                //Total hack, but it works
                <div
                  className="profile_tabs__item"
                  key={
                    events?.length && userVideos?.length
                      ? index
                      : events?.length && !userVideos?.length
                      ? index
                      : index + 1
                  }
                >
                  <a
                    href="#"
                    className={`profile_tabs__link ${
                      (events?.length && userVideos?.length
                        ? index
                        : events?.length && !userVideos?.length
                        ? index
                        : index + 1) === activeTab
                        ? 'profile_tabs__link--active'
                        : ''
                    }`}
                    data-tab={
                      events?.length && userVideos?.length
                        ? index
                        : events?.length && !userVideos?.length
                        ? index
                        : index + 1
                    }
                    onClick={() =>
                      tabHandler(
                        events?.length && userVideos?.length
                          ? index
                          : events?.length && !userVideos?.length
                          ? index
                          : index + 1
                      )
                    }
                  >
                    {item}
                  </a>
                </div>
              )
            })}
          </div>
          <div className="profile_tabs__content">
            {tabsContent.map(({ events, media }, index) => {
              return (
                <div
                  className={`profile_tabs__content_item ${
                    index === activeTab ? 'profile_tabs__content_item--active' : ''
                  }`}
                  key={index}
                >
                  {events && events.length > 0
                    ? events.map((event) => {
                        return (
                          <div className="profile_events">
                            <div
                              className="profile_events__item"
                              key={event.id}
                              onClick={(e) => handleEventClick(e, event)}
                            >
                              <div className="profile_events__title">{event?.title}</div>
                              <div className="profile_events__date">
                                {new Date(event?.scheduled_at).toLocaleDateString(
                                  'en-US',
                                  dateOptions
                                )}
                              </div>
                              <button className="profile_events__info_icon">
                                <QuestionIcon />
                              </button>
                              <div className="profile_events__info">
                                <div className="profile_events__info_list">
                                  <div className="profile_events__info_item">{`$${event?.entry_fee} Entry`}</div>
                                  <div className="profile_events__info_item">{`${event?.max_participants} Max Attendance`}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    : null}
                  {media?.length && index === 1 && (
                    <div className="profile_media">
                      <div className="profile_media__list">
                        {userVideos?.length
                          ? userVideos.map(({ videoId, title, duration }, index) => {
                              return (
                                <div
                                  className="profile_media__item"
                                  key={videoId + index}
                                >
                                  <div className="profile_media__iframe_item">
                                    <iframe
                                      src={`https://www.youtube.com/embed/${videoId}?showinfo=0`}
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      allowFullScreen
                                    ></iframe>
                                  </div>
                                  <div className="profile_media__title">{title}</div>
                                  <div className="profile_media__duration">
                                    {videoHandler.convertDuration(duration)}
                                  </div>
                                </div>
                              )
                            })
                          : null}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ProfileTabs
