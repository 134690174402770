const PAGE_NAMES = {
  schedule: 'Schedule',
  availability: 'Availability',
  classes: 'Events',
  videoes: 'Videos',
  profile: 'Account',
  help: 'Help',
  sendInvite: 'Send Invite',
}

export default PAGE_NAMES
