import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import { Logo } from 'components/Header'

import JoinHeroImg from 'i/pro_join_hero_img.jpg'
import { ReactComponent as MailIcon } from 'i/icons/mail.svg'

import { authApi } from 'api'

const ResetPassword = (props) => {
  const [error, setError] = useState()

  return (
    <section className="section pro_join_mod full_mod_v2">
      <Logo proJoinPageMod />
      <div className="section_row flex_mod">
        <div className="section_col text_mod col_mod">
          <div className="pro_join_block">
            <h1 className="section_title offset_mod">
              Enter your email below.
              <br />
            </h1>
            <h2 className="form_field_label">
              If an account is found, you will find a reset link in your mailbox.
            </h2>
            <Formik
              initialValues={{
                email: '',
                csrfmiddlewaretoken: document.cookie.split(';')[0].split('=')[1],
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true)

                var parser = new DOMParser()

                await authApi.passwordResetCSRF().then(
                  authApi.passwordReset({
                    email: values.email,
                    csrfmiddlewaretoken: values.csrfmiddlewaretoken,
                  })
                )
                setSubmitting(false)
              }}
            >
              {({ errors, touched, submitForm, isSubmitting, handleChange }) => (
                <Form className="user_join_form">
                  {error && (
                    <div className="form_error">
                      <ul>
                        {Object.keys(error).map(function (key, index) {
                          return (
                            <li>
                              {String.fromCharCode('0x2022')}
                              {error[key]}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                  <div className="pro_join_block_form_w_input">
                    <div className="form_field profile_mod icon_mod">
                      <label className="form_field_label" htmlFor="email">
                        Email
                      </label>
                      <Field
                        name="csrfmiddlewaretoken"
                        value={document.cookie.split(';')[0].split('=')[1]}
                        type="hidden"
                      />
                      <Field
                        className="form_field_input color_mod"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="johnsmith@email.com"
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="profile_form_icon_w v2_mod">
                        <MailIcon className="icon icon-mail size_mod" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <button
                    className="btn_v6"
                    button
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Login
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="section_col image_mod">
          <div className="section_w_img full_mod">
            <img className="cover_img" src={JoinHeroImg} alt="pro join" />
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ auth: { error, attributes } }) => ({
  authError: error,
  attributes,
})

export default connect(mapStateToProps)(ResetPassword)
