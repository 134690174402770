import React from 'react'

const FormField = ({
  value,
  name,
  id,
  type,
  className,
  placeholder,
  handleChange,
  defaultValue,
  handleFocus,
  handleBlur,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <input
      id={id}
      name={name}
      className={className}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}

export default FormField
