import React from 'react'
import { Player } from 'video-react'
import styles from './SummaryPlayer.module.scss'
import 'video-react/dist/video-react.css'
import { useFetch } from 'hooks'
import { callApi } from 'api'
import { FetchResult } from 'components/FetchResult'
import { Spinner } from 'components/Spinner'
import getCallType from 'utils/getCallType'

function SummaryPlayer({ roomID }) {
  const callType = getCallType()
  const { loading, error, data } = useFetch(() => callApi.getCallRecord(callType, roomID))

  return (
    <FetchResult error={error} status={loading} data={data} indicator={<Spinner />}>
      {data?.result?.records.map((videoLink) => {
        return (
          <div className={styles.player}>
            <div className={styles.wrapper}>
              <Player>
                <source src={videoLink} />
              </Player>
            </div>
          </div>
        )
      })}
    </FetchResult>
  )
}

export { SummaryPlayer }
