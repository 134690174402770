import ContentLoader from 'react-content-loader'

const TalkListPreview = (props) => {
  return (
    <div className="talk_list_preview">
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
      <ContentLoader />
    </div>
  )
}

export default TalkListPreview
