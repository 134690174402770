import { React, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Logo } from 'components/Header'

const Header = ({ pro }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <header className="header_v2">
      <div className="header_in">
        <div className="header_col_v2">
          <div className="header_logo_w border_mod">
            <Logo />
          </div>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = ({ auth: { pro } }) => ({
  pro,
})

export default connect(mapStateToProps)(Header)
