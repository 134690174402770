import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import LogoImg from 'i/talkpro_logo.png'

const Logo = (props) => {
  const logoClasses = classNames('header_logo', {
    pro_join_mod: props.proJoinPageMod,
  })

  return (
    <Link className={logoClasses} to={props.isPro ? '/dashboard/pro' : '/dashboard/user'}>
      <img className="inner_img" src={LogoImg} alt="" />
    </Link>
  )
}

const mapStateToProps = ({ auth: { error, user, isPro } }) => ({
  authError: error,
  user,
  isPro,
})

export default connect(mapStateToProps)(Logo)
